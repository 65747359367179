import React from 'react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';

function PrivacyPolicy() {
  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Privacy Policy"
        description="Learn about how we collect, use, and protect your personal information at Senses Innovations."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Privacy Policy - Senses Innovations',
          description: 'Privacy policy and data protection information',
          publisher: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          }
        }}
      />
      <PageHeader
        title="Privacy Policy"
        subtitle="Last updated: February 27, 2025"
      />

      <Section>
        <div className="prose prose-lg max-w-4xl mx-auto">
          <h2>Introduction</h2>
          <p>
            At Senses Innovations, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
          </p>

          <h2>Information We Collect</h2>
          <h3>Personal Information</h3>
          <p>We may collect personal information that you voluntarily provide to us when you:</p>
          <ul>
            <li>Fill out forms on our website</li>
            <li>Create an account</li>
            <li>Subscribe to our newsletter</li>
            <li>Request a consultation</li>
            <li>Contact us for support</li>
          </ul>

          <h3>Automatically Collected Information</h3>
          <p>When you visit our website, we automatically collect certain information about your device, including:</p>
          <ul>
            <li>IP address</li>
            <li>Browser type</li>
            <li>Operating system</li>
            <li>Access times</li>
            <li>Pages viewed</li>
          </ul>

          <h2>How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Improve our website and user experience</li>
            <li>Communicate with you about our services</li>
            <li>Send you marketing and promotional materials (with your consent)</li>
            <li>Respond to your inquiries and support requests</li>
            <li>Monitor and analyze usage patterns and trends</li>
          </ul>

          <h2>Information Sharing and Disclosure</h2>
          <p>We may share your information with:</p>
          <ul>
            <li>Service providers who assist in our operations</li>
            <li>Professional advisors and consultants</li>
            <li>Law enforcement or regulatory authorities when required by law</li>
          </ul>

          <h2>Data Security</h2>
          <p>
            We implement appropriate technical and organizational security measures to protect your information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure.
          </p>

          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Object to processing of your information</li>
            <li>Withdraw consent where applicable</li>
          </ul>

          <h2>Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to track activity on our website and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
          </p>

          <h2>Third-Party Links</h2>
          <p>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.
          </p>

          <h2>Children's Privacy</h2>
          <p>
            Our services are not intended for individuals under the age of 16. We do not knowingly collect personal information from children.
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <ul>
            <li>Email: privacy@sensesinnovations.com</li>
            <li>Address: 5900 Balcones Dr. STE 100, Austin, TX 78731, United States</li>
          </ul>
        </div>
      </Section>
    </div>
  );
}

export default PrivacyPolicy;