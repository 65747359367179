import { supabase } from './supabase/client';

export async function uploadImage(file: File, path: string) {
  const { data, error } = await supabase.storage
    .from('seo-images')
    .upload(path, file, {
      cacheControl: '3600',
      upsert: true
    });

  if (error) throw error;

  const { data: { publicUrl } } = supabase.storage
    .from('seo-images')
    .getPublicUrl(data.path);

  return publicUrl;
}

export async function uploadCertificate(file: File, path: string) {
  const { data, error } = await supabase.storage
    .from('certificates')
    .upload(path, file, {
      cacheControl: '3600',
      upsert: true
    });

  if (error) throw error;

  const { data: { publicUrl } } = supabase.storage
    .from('certificates')
    .getPublicUrl(data.path);

  return publicUrl;
}

export async function deleteImage(path: string) {
  const { error } = await supabase.storage
    .from('seo-images')
    .remove([path]);

  if (error) throw error;
}

export async function deleteCertificate(path: string) {
  const { error } = await supabase.storage
    .from('certificates')
    .remove([path]);

  if (error) throw error;
}