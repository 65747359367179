import React, { useState, useEffect } from 'react';
import { Globe, Code, Eye, Search, Share2, Settings, Lightbulb } from 'lucide-react';
import Modal from './Modal';
import FormField from './FormField';
import Button from './Button';
import ImageUpload from './ImageUpload';
import SEOScoreCard from './SEOScoreCard';
import SEOSuggestions from './SEOSuggestions';
import SEOMetaRobotsEditor from './SEOMetaRobotsEditor';
import { validateSEOData } from '../../lib/seo';
import { analyzeSEO, generateSEOSuggestions } from '../../lib/seo-analyzer';
import { updatePageSEO } from '../../lib/seo-scanner';
import { uploadImage } from '../../lib/storage';
import type { SEOData } from '../../types/admin';
import toast from 'react-hot-toast';

interface SEOPreviewProps {
  data: {
    title: string;
    description: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    twitterTitle?: string;
    twitterDescription?: string;
    twitterImage?: string;
    url: string;
  };
}

function SEOPreview({ data }: SEOPreviewProps) {
  const url = data?.url || window.location.origin;
  
  return (
    <div className="space-y-6">
      {/* Google Search Preview */}
      <div className="space-y-2">
        <h4 className="text-sm font-medium text-gray-900 dark:text-white flex items-center gap-2">
          <Globe className="w-4 h-4 text-primary dark:text-accent" />
          Google Search Preview
        </h4>
        <div className="p-4 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-lg">
          <div className="text-[#1a0dab] dark:text-[#8ab4f8] text-xl hover:underline cursor-pointer">
            {data.title}
          </div>
          <div className="text-[#006621] dark:text-[#0cce6b] text-sm mt-1">
            {url}
          </div>
          <div className="text-sm text-gray-600 dark:text-gray-400 mt-1 line-clamp-2">
            {data.description}
          </div>
        </div>
      </div>

      {/* Facebook/Open Graph Preview */}
      <div className="space-y-2">
        <h4 className="text-sm font-medium text-gray-900 dark:text-white flex items-center gap-2">
          <Share2 className="w-4 h-4 text-primary dark:text-accent" />
          Social Media Preview
        </h4>
        <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
          {data.ogImage && (
            <div className="aspect-[1.91/1] bg-gray-100 dark:bg-gray-800">
              <img 
                src={data.ogImage} 
                alt="Social preview" 
                className="w-full h-full object-cover"
              />
            </div>
          )}
          <div className="p-4 bg-white dark:bg-gray-900">
            <div className="text-base font-medium text-gray-900 dark:text-white">
              {data.ogTitle || data.title}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
              {data.ogDescription || data.description}
            </div>
            <div className="text-sm text-gray-400 dark:text-gray-500 mt-2">
              {new URL(url).hostname}
            </div>
          </div>
        </div>
      </div>

      {/* Twitter Card Preview */}
      <div className="space-y-2">
        <h4 className="text-sm font-medium text-gray-900 dark:text-white flex items-center gap-2">
          <Share2 className="w-4 h-4 text-primary dark:text-accent" />
          Twitter Card Preview
        </h4>
        <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
          {data.twitterImage && (
            <div className="aspect-[2/1] bg-gray-100 dark:bg-gray-800">
              <img 
                src={data.twitterImage} 
                alt="Twitter preview" 
                className="w-full h-full object-cover"
              />
            </div>
          )}
          <div className="p-4 bg-white dark:bg-gray-900">
            <div className="text-base font-medium text-gray-900 dark:text-white">
              {data.twitterTitle || data.ogTitle || data.title}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
              {data.twitterDescription || data.ogDescription || data.description}
            </div>
            <div className="text-sm text-gray-400 dark:text-gray-500 mt-2">
              {new URL(url).hostname}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SEOEditorProps {
  isOpen: boolean;
  onClose: () => void;
  page?: any;
}

function SEOEditor({ isOpen, onClose, page }: SEOEditorProps) {
  const [formData, setFormData] = useState<SEOData>({
    title: '',
    description: '',
    slug: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
    noIndex: false,
    canonicalUrl: '',
    metaKeywords: [],
    metaRobots: [],
    schemaMarkup: '',
    twitterCard: 'summary_large_image',
    twitterTitle: '',
    twitterDescription: '',
    twitterImage: ''
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [twitterImageFile, setTwitterImageFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [analysis, setAnalysis] = useState<ReturnType<typeof analyzeSEO> | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (page) {
      setFormData({
        title: page.title || '',
        description: page.description || '',
        slug: page.path ? page.path.replace(/^\//, '') : '',
        ogTitle: page.og_title || '',
        ogDescription: page.og_description || '',
        ogImage: page.og_image || '',
        noIndex: page.no_index || false,
        canonicalUrl: page.canonical_url || '',
        metaKeywords: page.meta_keywords || [],
        metaRobots: page.meta_robots || [],
        schemaMarkup: page.schema_markup ? JSON.stringify(page.schema_markup, null, 2) : '',
        twitterCard: page.twitter_card || 'summary_large_image',
        twitterTitle: page.twitter_title || '',
        twitterDescription: page.twitter_description || '',
        twitterImage: page.twitter_image || ''
      });
      
      // Run initial analysis
      const initialAnalysis = analyzeSEO(formData);
      setAnalysis(initialAnalysis);
      
      // Generate suggestions
      const initialSuggestions = generateSEOSuggestions(formData);
      setSuggestions(initialSuggestions);
    }
  }, [page]);

  // Run analysis whenever form data changes
  useEffect(() => {
    const newAnalysis = analyzeSEO(formData);
    setAnalysis(newAnalysis);
    
    const newSuggestions = generateSEOSuggestions(formData);
    setSuggestions(newSuggestions);
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationErrors = validateSEOData(formData);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setSaving(true);
      
      let ogImage = formData.ogImage;
      let twitterImage = formData.twitterImage;

      if (imageFile) {
        const fileName = `${page.id}-${Date.now()}.${imageFile.name.split('.').pop()}`;
        ogImage = await uploadImage(imageFile, fileName);
      }

      if (twitterImageFile) {
        const fileName = `${page.id}-twitter-${Date.now()}.${twitterImageFile.name.split('.').pop()}`;
        twitterImage = await uploadImage(twitterImageFile, fileName);
      }

      let schemaMarkup;
      try {
        schemaMarkup = formData.schemaMarkup ? JSON.parse(formData.schemaMarkup) : null;
      } catch (error) {
        toast.error('Invalid JSON in schema markup');
        return;
      }

      const userId = '00000000-0000-0000-0000-000000000000';
      
      await updatePageSEO(page.id, {
        ...formData,
        ogImage,
        twitterImage,
        schemaMarkup
      }, userId);

      toast.success('SEO data updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating SEO data:', error);
      toast.error('Failed to update SEO data');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field: keyof SEOData, value: string | boolean | string[]) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors([]);
  };

  const handleKeywordsChange = (value: string) => {
    const keywords = value.split(',').map(k => k.trim()).filter(k => k);
    handleChange('metaKeywords', keywords);
  };

  const handleSuggestionApply = (suggestion: any) => {
    handleChange(suggestion.field as keyof SEOData, suggestion.suggested);
    toast.success('Suggestion applied');
  };

  const tabs: { id: string; label: string; icon: React.ElementType }[] = [
    { id: 'basic', label: 'Basic SEO', icon: Search },
    { id: 'social', label: 'Social Media', icon: Share2 },
    { id: 'schema', label: 'Schema', icon: Code },
    { id: 'advanced', label: 'Advanced', icon: Settings },
    { id: 'analysis', label: 'Analysis', icon: Lightbulb }
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Edit SEO Settings"
      size="xl"
      footer={
        <div className="flex justify-between items-center">
          <Button
            variant="outline"
            icon={Eye}
            onClick={() => setShowPreview(!showPreview)}
          >
            {showPreview ? 'Hide Preview' : 'Show Preview'}
          </Button>
          <div className="flex gap-3">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} loading={saving}>
              Save Changes
            </Button>
          </div>
        </div>
      }
    >
      <div className="flex gap-6">
        <div className={`flex-1 transition-all duration-300 ${showPreview ? 'w-1/2' : 'w-full'}`}>
          <div className="mb-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex gap-2">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-t-lg transition-colors relative ${
                    activeTab === tab.id
                      ? 'text-primary dark:text-accent border-b-2 border-primary dark:border-accent'
                      : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                  }`}
                >
                  <tab.icon className="w-4 h-4" />
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          <div className="space-y-6">
            {errors.length > 0 && (
              <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
                <ul className="list-disc list-inside text-sm text-red-600 dark:text-red-400">
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Basic SEO Tab */}
            <div className={activeTab === 'basic' ? 'block' : 'hidden'}>
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormField label="Meta Title" required icon={Globe}>
                    <input
                      type="text"
                      value={formData.title}
                      onChange={(e) => handleChange('title', e.target.value)}
                      className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                      maxLength={60}
                    />
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                      {formData.title.length}/60 characters
                    </p>
                  </FormField>

                  <FormField label="URL Path" required>
                    <div className="flex items-center">
                      <span className="px-4 py-2 bg-gray-50 dark:bg-gray-700 border border-r-0 border-gray-200 dark:border-gray-600 rounded-l-lg text-gray-500 dark:text-gray-400">
                        /
                      </span>
                      <input
                        type="text"
                        value={formData.slug}
                        disabled
                        className="flex-1 px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-r-lg bg-gray-50 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
                      />
                    </div>
                  </FormField>
                </div>

                <FormField label="Meta Description" required>
                  <textarea
                    value={formData.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                    rows={3}
                    maxLength={160}
                  />
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    {formData.description.length}/160 characters
                  </p>
                </FormField>

                <FormField label="Meta Keywords">
                  <input
                    type="text"
                    value={formData.metaKeywords.join(', ')}
                    onChange={(e) => handleKeywordsChange(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                    placeholder="Enter keywords separated by commas"
                  />
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Example: renewable energy, wind power, solar energy
                  </p>
                </FormField>

                <FormField label="Meta Robots">
                  <SEOMetaRobotsEditor
                    value={formData.metaRobots}
                    onChange={(value) => handleChange('metaRobots', value)}
                  />
                </FormField>
              </div>
            </div>

            {/* Social Media Tab */}
            <div className={activeTab === 'social' ? 'block' : 'hidden'}>
              <div className="space-y-6">
                <div className="border-b border-gray-200 dark:border-gray-700 pb-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Open Graph Settings
                  </h3>
                  <div className="space-y-6">
                    <FormField label="OG Title">
                      <input
                        type="text"
                        value={formData.ogTitle}
                        onChange={(e) => handleChange('ogTitle', e.target.value)}
                        className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                        maxLength={95}
                      />
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        Leave blank to use meta title
                      </p>
                    </FormField>

                    <FormField label="OG Description">
                      <textarea
                        value={formData.ogDescription}
                        onChange={(e) => handleChange('ogDescription', e.target.value)}
                        className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                        rows={3}
                        maxLength={200}
                      />
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        Leave blank to use meta description
                      </p>
                    </FormField>

                    <FormField label="OG Image">
                      <ImageUpload
                        value={formData.ogImage}
                        onChange={setImageFile}
                        onError={(error) => toast.error(error)}
                      />
                    </FormField>
                  </div>
                </div>

                <div className="space-y-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Twitter Card Settings
                  </h3>
                  <div className="space-y-6">
                    <FormField label="Card Type">
                      <select
                        value={formData.twitterCard}
                        onChange={(e) => handleChange('twitterCard', e.target.value)}
                        className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                      >
                        <option value="summary">Summary</option>
                        <option value="summary_large_image">Summary with Large Image</option>
                      </select>
                    </FormField>

                    <FormField label="Twitter Title">
                      <input
                        type="text"
                        value={formData.twitterTitle}
                        onChange={(e) => handleChange('twitterTitle', e.target.value)}
                        className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                      />
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        Leave blank to use OG title
                      </p>
                    </FormField>

                    <FormField label="Twitter Description">
                      <textarea
                        value={formData.twitterDescription}
                        onChange={(e) => handleChange('twitterDescription', e.target.value)}
                        className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                        rows={3}
                      />
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        Leave blank to use OG description
                      </p>
                    </FormField>

                    <FormField label="Twitter Image">
                      <ImageUpload
                        value={formData.twitterImage}
                        onChange={setTwitterImageFile}
                        onError={(error) => toast.error(error)}
                      />
                    </FormField>
                  </div>
                </div>
              </div>
            </div>

            {/* Schema Tab */}
            <div className={activeTab === 'schema' ? 'block' : 'hidden'}>
              <FormField label="JSON-LD Schema" icon={Code}>
                <textarea
                  value={formData.schemaMarkup}
                  onChange={(e) => handleChange('schemaMarkup', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white font-mono text-sm focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                  rows={12}
                  placeholder="Enter JSON-LD schema markup"
                />
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Enter valid JSON-LD schema markup
                </p>
              </FormField>
            </div>

            {/* Advanced Tab */}
            <div className={activeTab === 'advanced' ? 'block' : 'hidden'}>
              <div className="space-y-6">
                <FormField label="Canonical URL">
                  <input
                    type="url"
                    value={formData.canonicalUrl}
                    onChange={(e) => handleChange('canonicalUrl', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                    placeholder="https://"
                  />
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Leave blank to use the default URL
                  </p>
                </FormField>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="noIndex"
                    checked={formData.noIndex}
                    onChange={(e) => handleChange('noIndex', e.target.checked)}
                    className="w-4 h-4 text-accent border-gray-300 dark:border-gray-600 rounded focus:ring-accent"
                  />
                  <label htmlFor="noIndex" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                    Prevent search engines from indexing this page
                  </label>
                </div>
              </div>
            </div>

            {/* Analysis Tab */}
            <div className={activeTab === 'analysis' ? 'block' : 'hidden'}>
              <div className="space-y-6">
                {analysis && (
                  <>
                    <SEOScoreCard
                      score={analysis.score}
                      issues={analysis.issues}
                      warnings={analysis.warnings}
                    />
                    
                    {analysis.suggestions.length > 0 && (
                      <SEOSuggestions
                        suggestions={analysis.suggestions}
                        onApply={handleSuggestionApply}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {showPreview && (
          <div className="w-1/2 border-l border-gray-200 dark:border-gray-700 pl-6">
            <SEOPreview
              data={{
                title: formData.title,
                description: formData.description,
                ogTitle: formData.ogTitle,
                ogDescription: formData.ogDescription,
                ogImage: formData.ogImage,
                twitterTitle: formData.twitterTitle,
                twitterDescription: formData.twitterDescription,
                twitterImage: formData.twitterImage,
                url: `https://sensesinnovations.com${page?.path || ''}`
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default SEOEditor;