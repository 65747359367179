import React, { useState, useEffect } from 'react';
import { Info } from 'lucide-react';

interface MetaRobotsOption {
  value: string;
  label: string;
  description: string;
  conflicts?: string[];
}

interface SEOMetaRobotsEditorProps {
  value: string[];
  onChange: (value: string[]) => void;
}

function SEOMetaRobotsEditor({ value = [], onChange }: SEOMetaRobotsEditorProps) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(value);
  const [showTooltip, setShowTooltip] = useState<string | null>(null);

  const options: MetaRobotsOption[] = [
    {
      value: 'index',
      label: 'Index',
      description: 'Allow search engines to index this page',
      conflicts: ['noindex']
    },
    {
      value: 'noindex',
      label: 'No Index',
      description: 'Prevent search engines from indexing this page',
      conflicts: ['index']
    },
    {
      value: 'follow',
      label: 'Follow',
      description: 'Allow search engines to follow links on this page',
      conflicts: ['nofollow']
    },
    {
      value: 'nofollow',
      label: 'No Follow',
      description: 'Prevent search engines from following links on this page',
      conflicts: ['follow']
    },
    {
      value: 'noarchive',
      label: 'No Archive',
      description: 'Prevent search engines from showing cached versions of this page'
    },
    {
      value: 'nosnippet',
      label: 'No Snippet',
      description: 'Prevent search engines from showing snippets in search results'
    },
    {
      value: 'notranslate',
      label: 'No Translate',
      description: 'Prevent search engines from offering translations of this page'
    },
    {
      value: 'noimageindex',
      label: 'No Image Index',
      description: 'Prevent search engines from indexing images on this page'
    }
  ];

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleOptionChange = (option: MetaRobotsOption) => {
    let newOptions = [...selectedOptions];

    if (newOptions.includes(option.value)) {
      // Remove the option
      newOptions = newOptions.filter(o => o !== option.value);
    } else {
      // Add the option and remove any conflicting options
      newOptions = newOptions.filter(o => !option.conflicts?.includes(o));
      newOptions.push(option.value);
    }

    setSelectedOptions(newOptions);
    onChange(newOptions);
  };

  const getMetaRobotsPreview = () => {
    if (selectedOptions.length === 0) {
      return 'index, follow (default)';
    }
    return selectedOptions.join(', ');
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        {options.map((option) => (
          <div
            key={option.value}
            className="relative"
            onMouseEnter={() => setShowTooltip(option.value)}
            onMouseLeave={() => setShowTooltip(null)}
          >
            <label
              className={`flex items-center gap-3 p-3 rounded-lg border transition-colors cursor-pointer ${
                selectedOptions.includes(option.value)
                  ? 'bg-primary/5 border-primary dark:bg-primary/10 dark:border-primary'
                  : 'bg-white border-gray-200 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700/50'
              }`}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOptionChange(option)}
                className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary dark:border-gray-600 dark:focus:ring-primary"
              />
              <div>
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  {option.label}
                </span>
                <Info className="inline-block w-4 h-4 ml-1 text-gray-400" />
              </div>
            </label>

            {showTooltip === option.value && (
              <div className="absolute z-10 w-64 p-2 mt-1 text-sm text-white bg-gray-900 rounded-lg shadow-lg">
                {option.description}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
        <p className="text-sm font-medium text-gray-900 dark:text-white mb-2">
          Meta Robots Preview:
        </p>
        <code className="block p-2 bg-gray-100 dark:bg-gray-700 rounded text-sm font-mono text-gray-900 dark:text-white">
          {getMetaRobotsPreview()}
        </code>
      </div>
    </div>
  );
}

export default SEOMetaRobotsEditor;