import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  icon?: LucideIcon;
  loading?: boolean;
  children: React.ReactNode;
}

function Button({
  variant = 'primary',
  size = 'md',
  icon: Icon,
  loading,
  children,
  className = '',
  disabled,
  ...props
}: ButtonProps) {
  const baseStyles = 'inline-flex items-center justify-center font-medium rounded-xl transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 transform hover:shadow-lg active:scale-95 dark:focus:ring-offset-gray-900';
  
  const variants = {
    primary: 'bg-primary dark:bg-accent text-white dark:text-primary hover:bg-primary/90 dark:hover:bg-accent/90 focus:ring-primary/50 dark:focus:ring-accent/50 hover:-translate-y-1 shadow-sm hover:shadow-primary/25 dark:hover:shadow-accent/25',
    secondary: 'bg-accent dark:bg-primary text-primary dark:text-white hover:bg-accent/90 dark:hover:bg-primary/90 focus:ring-accent/50 dark:focus:ring-primary/50 hover:-translate-y-1 shadow-sm hover:shadow-accent/25 dark:hover:shadow-primary/25',
    outline: 'border-2 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:ring-gray-500/50 hover:-translate-y-1 shadow-sm hover:shadow-gray-200/50 dark:hover:shadow-gray-700/50',
    danger: 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500/50 hover:-translate-y-1 shadow-sm hover:shadow-red-500/25'
  };

  const sizes = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2',
    lg: 'px-6 py-3 text-lg'
  };

  return (
    <button
      className={`
        ${baseStyles}
        ${variants[variant]}
        ${sizes[size]}
        ${loading || disabled ? 'opacity-50 cursor-not-allowed transform-none hover:transform-none' : ''}
        ${className}
      `}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? (
        <>
          <div className="w-4 h-4 border-2 border-current border-t-transparent rounded-full animate-spin mr-2"></div>
          <span>Loading...</span>
        </>
      ) : (
        <>
          {Icon && <Icon className="w-5 h-5 mr-2 transition-transform duration-300 group-hover:rotate-3" />}
          {children}
        </>
      )}
    </button>
  );
}

export default Button;