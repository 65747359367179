import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Mail, Linkedin, MapPin, ChevronDown } from 'lucide-react';

function Footer() {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const mainLinks = [
    { label: "About Us", path: "/about" },
    { label: "Services", path: "/services" },
    { label: "Industries", path: "/industries" },
    { label: "Case Studies", path: "/case-studies" },
    { label: "Contact", path: "/contact" }
  ];

  const serviceLinks = [
    { label: "IT Consultancy", path: "/services/it-consultancy" },
    { label: "Infrastructure", path: "/services/infrastructure" },
    { label: "Digital Transformation", path: "/services/digital-transformation" },
    { label: "App Development", path: "/services/app-development" },
    { label: "AI Solutions", path: "/services/ai-solutions" },
    { label: "Web Development", path: "/services/web-development" },
    { label: "Analytics", path: "/services/analytics" }
  ];

  const industryLinks = [
    { label: "Renewable Energy", path: "/industries/renewables" }
  ];

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const FooterSection = ({ title, links }: { title: string, links: typeof mainLinks }) => (
    <div className="border-b border-gray-800/50 md:border-none">
      <button
        className="w-full md:w-auto flex items-center justify-between py-4 md:py-0 text-left"
        onClick={() => toggleSection(title)}
      >
        <h4 className="text-lg font-semibold text-white/90">{title}</h4>
        <ChevronDown 
          className={`w-5 h-5 md:hidden transition-transform duration-300 ${
            activeSection === title ? 'rotate-180' : ''
          }`} 
        />
      </button>
      <ul className={`space-y-3 overflow-hidden transition-all duration-300 md:h-auto ${
        activeSection === title ? 'h-auto pb-4' : 'h-0 md:h-auto'
      }`}>
        {links.map((link, index) => (
          <li key={index}>
            <Link 
              to={link.path}
              className="text-gray-400 hover:text-white transition-colors"
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="bg-[#0B1329] text-white">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 py-16">
          {/* Logo and Address - Spans 4 columns on desktop */}
          <div className="md:col-span-4">
            <div className="flex flex-col gap-6">
              <Link to="/" className="block w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.76 154.55" className="w-[180px] h-[40px]">
                  <g>
                    <path fill="#fff" d="M132.69,154.55H0V50.43c0-15.99,7.34-30.68,20.13-40.31C28.92,3.51,39.39.01,50.42.01h132.69v104.12c0,11.9-4.23,23.46-11.9,32.53-9.61,11.37-23.65,17.89-38.52,17.89Z"/>
                    <g>
                      <path fill="#0B1329" d="M99.33,71.49h-38.61c-5.85,0-10.6-4.76-10.6-10.6s4.76-10.6,10.6-10.6h50.65c3.48,0,6.31-2.83,6.31-6.31s-2.83-6.31-6.31-6.31h-50.65c-12.8,0-23.21,10.41-23.21,23.21s10.41,23.21,23.21,23.21h38.61c5.9,0,10.71,4.8,10.71,10.71s-4.8,10.71-10.71,10.71h-51.64c-3.48,0-6.31,2.83-6.31,6.3s2.83,6.31,6.31,6.31h51.64c12.86,0,23.32-10.46,23.32-23.32s-10.46-23.32-23.32-23.32ZM113.01,94.81c0-7.54-6.13-13.68-13.68-13.68h-38.61c-11.16,0-20.25-9.08-20.25-20.25s9.08-20.25,20.25-20.25h50.65c1.84,0,3.34,1.5,3.34,3.34s-1.5,3.34-3.34,3.34h-50.65c-7.48,0-13.57,6.09-13.57,13.57s6.09,13.57,13.57,13.57h38.61c11.22,0,20.36,9.13,20.36,20.35s-9.13,20.35-20.36,20.35h-51.64c-1.84,0-3.34-1.5-3.34-3.34s1.5-3.34,3.34-3.34h51.64c7.54,0,13.68-6.13,13.68-13.68Z"/>
                      <path fill="#0B1329" d="M138.46,37.78c-3.48,0-6.31,2.83-6.31,6.31v67.64c0,3.48,2.83,6.31,6.31,6.31s6.3-2.83,6.3-6.31V44.09c0-3.48-2.83-6.31-6.3-6.31ZM141.8,44.09v67.64c0,1.84-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34V44.09c0-1.84,1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34Z"/>
                    </g>
                    <g>
                      <path fill="#fff" d="M614.06,69.7c2.41,2.36,4.27,5.29,5.28,8.75.94,3.22,1.17,6.49.82,9.82-.24,2.31-.8,4.54-1.72,6.67-1.27,2.92-3.13,5.41-5.56,7.47-9.36,8.25-27.62,6.79-35.04,2.47-5.34-3.01-8.78-7.49-10.24-13.47-.57-2.35-.77-4.73-.66-7.14.17-3.68,1.04-7.17,2.88-10.38,1.57-2.73,3.57-5.12,6.26-6.74,7.79-4.71,17.41-5.85,26.22-3.73,4.47,1.08,8.6,3.19,11.77,6.29ZM604.99,100.64c4.72-2.09,7.77-5.61,8.96-10.66.77-3.28.72-6.56-.2-9.81-1.12-3.95-3.92-7.37-7.56-9.32-3.51-1.88-7.88-2.43-11.81-2.58-5.08-.19-10.68.5-14.92,3.52-2.71,1.84-4.63,4.32-5.71,7.41-1.18,3.39-1.31,6.87-.6,10.37,3.17,16.27,26.66,13.67,31.85,11.07Z"/>
                      <path fill="#fff" d="M247.2,46.02h-30.61c-1.58,0-2.86-1.28-2.86-2.86s1.28-2.86,2.86-2.86h30.61c3.98,0,7.23-3.24,7.23-7.23s-3.24-7.23-7.23-7.23h-22.88c-7.1,0-12.88-5.78-12.88-12.88S217.21.09,224.31.09h30.02c1.58,0,2.86,1.28,2.86,2.86s-1.28,2.86-2.86,2.86h-30.02c-3.95,0-7.16,3.21-7.16,7.16s3.21,7.16,7.16,7.16h22.88c7.14,0,12.94,5.81,12.94,12.94s-5.81,12.94-12.94,12.94Z"/>
                      <path fill="#fff" d="M418.23,46.02h-30.61c-1.58,0-2.86-1.28-2.86-2.86s1.28-2.86,2.86-2.86h30.61c3.98,0,7.23-3.24,7.23-7.23s-3.24-7.23-7.23-7.23h-22.88c-7.1,0-12.88-5.78-12.88-12.88S388.24.09,395.35.09h89.8c1.58,0,2.86,1.28,2.86,2.86s-1.28,2.86-2.86,2.86h-89.8c-3.95,0-7.16,3.21-7.16,7.16s3.21,7.16,7.16,7.16h22.88c7.14,0,12.94,5.81,12.94,12.94s-5.81,12.94-12.94,12.94Z"/>
                      <path fill="#fff" d="M531.41,46.02h-88.96c-.9,0-1.72-.39-2.28-1-.51-.55-.82-1.29-.82-2.1v-19.52c-.01-1.07.53-2.02,1.35-2.58.5-.34,1.09-.53,1.74-.53l42.7-.02c1.63,0,2.94,1.36,2.85,3.01-.08,1.55-1.47,2.7-3.02,2.7h-39.93s0,14.31,0,14.31h86.49c3.67,0,6.95-2.63,7.42-6.28.57-4.41-2.87-8.18-7.17-8.18h-22.51c-6.7,0-12.57-4.97-13.2-11.64-.72-7.66,5.31-14.12,12.82-14.12h31.45c1.55,0,2.94,1.16,3.02,2.7.09,1.65-1.22,3.01-2.85,3.01h-31.36c-3.64,0-6.89,2.61-7.35,6.22-.56,4.37,2.84,8.11,7.1,8.11h22.88c7.35,0,13.29,6.16,12.93,13.6-.34,6.95-6.34,12.29-13.3,12.29Z"/>
                      <g>
                        <path fill="#fff" d="M313.96,5.81h-42.95c-1.58,0-2.86-1.28-2.86-2.86s1.28-2.86,2.86-2.86h42.95c1.58,0,2.86,1.28,2.86,2.86s-1.28,2.86-2.86,2.86Z"/>
                        <path fill="#fff" d="M313.96,26.01h-42.95c-1.58,0-2.86-1.28-2.86-2.86s1.28-2.86,2.86-2.86h42.95c1.58,0,2.86,1.28,2.86,2.86s-1.28,2.86-2.86,2.86Z"/>
                        <path fill="#fff" d="M314.02,46.02h-42.95c-1.58,0-2.86-1.28-2.86-2.86s1.28-2.86,2.86-2.86h42.95c1.58,0,2.86,1.28,2.86,2.86s-1.28,2.86-2.86,2.86Z"/>
                      </g>
                      <path fill="#fff" d="M328.04,46.02c-1.58,0-2.86-1.27-2.86-2.84V3.1c0-.59.18-1.16.49-1.63s.77-.86,1.32-1.09c.27-.12.56-.19.85-.22.86-.1,1.74.18,2.39.8l37.76,35.6V2.84c0-1.57,1.28-2.84,2.86-2.84s2.86,1.27,2.86,2.84v40.18c0,.57-.17,1.11-.46,1.57s-.76.86-1.3,1.1c-.49.21-1.01.28-1.52.21-.6-.08-1.18-.34-1.64-.77l-37.88-35.71v33.78c0,1.57-1.28,2.84-2.86,2.84Z"/>
                      <path fill="#fff" d="M720.82,82.5h-22.88c-3.95,0-7.16-3.21-7.16-7.16s3.21-7.16,7.16-7.16h30.02c1.58,0,2.86-1.28,2.86-2.86s-1.28-2.86-2.86-2.86h-30.02c-7.1,0-12.88,5.78-12.88,12.88s5.78,12.88,12.88,12.88h22.88c3.98,0,7.23,3.24,7.23,7.23s-3.24,7.23-7.23,7.23h-30.61c-1.58,0-2.86,1.28-2.86,2.86s1.28,2.86,2.86,2.86h30.61c7.14,0,12.94-5.81,12.94-12.94s-5.81-12.94-12.94-12.94Z"/>
                      <path fill="#fff" d="M214.29,62.58c-1.58,0-2.86,1.28-2.86,2.86v40.09c0,1.58,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86v-40.09c0-1.58-1.28-2.86-2.86-2.86Z"/>
                      <path fill="#fff" d="M556.27,62.45c-1.58,0-2.86,1.28-2.86,2.86v40.22c0,1.58,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86v-40.22c0-1.58-1.28-2.86-2.86-2.86Z"/>
                      <path fill="#fff" d="M270.58,62.51c-1.58,0-2.86,1.27-2.86,2.84v33.54l-37.76-35.63c-.54-.51-1.24-.79-1.95-.81-.43-.01-.87.06-1.28.24-.54.23-.98.6-1.3,1.06s-.51,1.06-.51,1.67v40.12c0,1.57,1.28,2.84,2.86,2.84s2.86-1.27,2.86-2.84v-33.8l37.87,35.73c.42.4.94.66,1.5.76s1.13.04,1.67-.19c.51-.22.93-.56,1.24-.99.34-.48.53-1.06.53-1.67v-40.02c0-1.57-1.28-2.84-2.86-2.84Z"/>
                      <path fill="#fff" d="M327.17,62.45c-1.58,0-2.86,1.27-2.86,2.85v33.59l-37.76-35.68c-.43-.41-.97-.67-1.53-.77s-1.16-.04-1.7.2c-.27.12-.53.27-.75.46-.66.56-1.06,1.38-1.06,2.28v40.18c0,1.57,1.28,2.85,2.86,2.85s2.86-1.27,2.86-2.85v-33.85l37.87,35.79c.42.4.95.66,1.5.76s1.13.04,1.67-.19c.51-.22.94-.58,1.25-1.01.33-.48.52-1.05.52-1.66v-40.08c0-1.57-1.28-2.85-2.86-2.85Z"/>
                      <path fill="#fff" d="M674.26,62.46c-1.58,0-2.86,1.27-2.86,2.84v33.61l-37.76-35.6c-.53-.5-1.2-.77-1.89-.81-.45-.02-.91.05-1.35.24-.42.18-.78.44-1.07.77-.47.53-.74,1.22-.74,1.96v40.09c0,1.57,1.28,2.84,2.86,2.84s2.86-1.27,2.86-2.84v-33.78l37.88,35.71c.42.4.95.65,1.5.75s1.13.04,1.66-.19c.49-.21.9-.54,1.2-.95.36-.49.56-1.08.56-1.71v-40.08c0-1.57-1.28-2.84-2.86-2.84Z"/>
                      <path fill="#fff" d="M448.7,62.76c-1.43-.66-3.13-.04-3.79,1.4l-15.49,33.54c-.8,1.72-2.04,3.06-3.56,3.91-1.25.7-2.68,1.07-4.23,1.07h-.04c-1.84,0-3.52-.55-4.91-1.52-1.22-.86-2.21-2.06-2.88-3.53l-15.13-33.46c-.65-1.44-2.34-2.08-3.78-1.43-1.44.65-2.08,2.34-1.43,3.78l15.13,33.46c1.32,2.92,3.48,5.21,6.13,6.67,2.04,1.12,4.38,1.74,6.85,1.75h.06c2.35,0,4.58-.55,6.56-1.56,2.77-1.42,5.04-3.75,6.42-6.74l15.49-33.54c.66-1.43.04-3.13-1.4-3.79Z"/>
                      <path fill="#fff" d="M485.11,70.91c-1.3-2.89-3.43-5.17-6.05-6.62-2.06-1.15-4.43-1.78-6.93-1.79-2.51-.02-4.89.6-6.97,1.74-2.61,1.43-4.75,3.7-6.08,6.56l-15.49,33.54c-.66,1.43-.04,3.13,1.4,3.79.39.18.8.26,1.2.26,1.08,0,2.11-.62,2.6-1.66l15.49-33.54c.78-1.69,1.99-3.01,3.47-3.86,1.27-.73,2.74-1.12,4.32-1.12h.04c1.73,0,3.32.48,4.66,1.35,1.34.87,2.42,2.13,3.13,3.7l15.11,33.41c.65,1.44,2.34,2.08,3.78,1.43,1.44-.65,2.08-2.34,1.43-3.78l-15.11-33.41Z"/>
                      <circle fill="#fff" cx="472.02" cy="94.3" r="5.96" transform="translate(303.33 545.13) rotate(-80.78)"/>
                      <path fill="#fff" d="M542.63,62.49h-42.66c-1.58,0-2.86,1.28-2.86,2.86s1.28,2.86,2.86,2.86h18.45v37.33c0,1.58,1.28,2.86,2.86,2.86s2.86-1.28,2.86-2.86v-37.33h18.5c1.58,0,2.86-1.28,2.86-2.86s-1.28-2.86-2.86-2.86Z"/>
                      <path fill="#fff" d="M384.85,69.7c2.41,2.36,4.27,5.29,5.28,8.75.94,3.22,1.17,6.49.82,9.82-.24,2.31-.8,4.54-1.72,6.67-1.27,2.92-3.13,5.41-5.56,7.47-9.36,8.25-27.62,6.79-35.04,2.47-5.34-3.01-8.78-7.49-10.24-13.47-.57-2.35-.77-4.73-.66-7.14.17-3.68,1.04-7.17,2.88-10.38,1.57-2.73,3.57-5.12,6.26-6.74,7.79-4.71,17.41-5.85,26.22-3.73,4.47,1.08,8.6,3.19,11.77,6.29ZM375.78,100.64c4.72-2.09,7.77-5.61,8.96-10.66.77-3.28.72-6.56-.2-9.81-1.12-3.95-3.92-7.37-7.56-9.32-3.51-1.88-7.88-2.43-11.81-2.58-5.08-.19-10.68.5-14.92,3.52-2.71,1.84-4.63,4.32-5.71,7.41-1.18,3.39-1.31,6.87-.6,10.37,3.17,16.27,26.66,13.67,31.85,11.07Z"/>
                    </g>
                  </g>
                </svg>
              </Link>
              <div className="flex items-start gap-3 text-gray-400">
                <MapPin className="w-5 h-5 flex-shrink-0 mt-1" />
                <div className="space-y-1">
                  <p>5900 Balcones Dr. STE 100</p>
                  <p>Austin, TX 78731</p>
                  <p>United States</p>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation Links - Spans 8 columns on desktop */}
          <div className="md:col-span-8 grid grid-cols-1 md:grid-cols-3 gap-8">
            <FooterSection title="Quick Links" links={mainLinks} />
            <FooterSection title="Services" links={serviceLinks} />
            <FooterSection title="Industries" links={industryLinks} />
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="py-6 border-t border-gray-800/50">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
            {/* Copyright and Legal Links */}
            <div className="text-gray-400 text-sm order-2 md:order-1 text-center md:text-left">
              <div className="space-x-4">
                <span>&copy; {new Date().getFullYear()} Senses Innovations. All rights reserved.</span>
                <Link to="/privacy-policy" className="hover:text-white transition-colors">Privacy Policy</Link>
                <Link to="/terms" className="hover:text-white transition-colors">Terms & Conditions</Link>
              </div>
            </div>

            {/* Social Links */}
            <div className="flex items-center justify-center md:justify-end gap-4 order-1 md:order-2">
              <a 
                href="https://www.linkedin.com/company/senses-innovations"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-8 h-8 text-gray-400 hover:text-white transition-colors rounded-full hover:bg-gray-800"
                aria-label="Follow us on LinkedIn"
              >
                <Linkedin className="w-4 h-4" />
              </a>
              <a 
                href="mailto:info@sensesinnovations.com"
                className="flex items-center justify-center w-8 h-8 text-gray-400 hover:text-white transition-colors rounded-full hover:bg-gray-800"
                aria-label="Email us"
              >
                <Mail className="w-4 h-4" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;