import React from 'react';
import { Link } from 'react-router-dom';
import { Brain, Cpu, Network, LineChart, Workflow, Database, Shield, Zap, Code, GitBranch, Lock, RefreshCw } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function AISolutions() {
  const capabilities = [
    {
      icon: Brain,
      title: "Machine Learning",
      description: "Advanced ML model development"
    },
    {
      icon: Network,
      title: "Neural Networks",
      description: "Deep learning solutions"
    },
    {
      icon: LineChart,
      title: "Predictive Analytics",
      description: "Data-driven forecasting"
    },
    {
      icon: Workflow,
      title: "Process Automation",
      description: "AI-powered automation"
    }
  ];

  const solutions = [
    {
      icon: Brain,
      title: "Machine Learning Solutions",
      description: "Custom ML models for complex business challenges",
      features: [
        "Supervised Learning",
        "Unsupervised Learning",
        "Reinforcement Learning",
        "Model Optimization"
      ]
    },
    {
      icon: Network,
      title: "Deep Learning",
      description: "Advanced neural network architectures",
      features: [
        "Computer Vision",
        "Natural Language Processing",
        "Speech Recognition",
        "Pattern Recognition"
      ]
    },
    {
      icon: LineChart,
      title: "Predictive Analytics",
      description: "Data-driven insights and forecasting",
      features: [
        "Time Series Analysis",
        "Anomaly Detection",
        "Risk Assessment",
        "Trend Prediction"
      ]
    },
    {
      icon: Workflow,
      title: "AI Automation",
      description: "Intelligent process automation solutions",
      features: [
        "Workflow Automation",
        "Decision Support",
        "Task Optimization",
        "Intelligent Routing"
      ]
    }
  ];

  const features = [
    {
      icon: Database,
      title: "Scalable Infrastructure",
      description: "Cloud-native AI solutions"
    },
    {
      icon: Shield,
      title: "Security",
      description: "Enterprise-grade security"
    },
    {
      icon: Code,
      title: "Custom Development",
      description: "Tailored AI solutions"
    },
    {
      icon: GitBranch,
      title: "Version Control",
      description: "Model versioning & tracking"
    },
    {
      icon: Lock,
      title: "Data Privacy",
      description: "Compliant data handling"
    },
    {
      icon: RefreshCw,
      title: "Continuous Learning",
      description: "Adaptive AI systems"
    }
  ];

  const process = [
    {
      phase: "01",
      title: "Discovery",
      steps: [
        "Business Requirements",
        "Data Assessment",
        "Feasibility Analysis",
        "Solution Planning"
      ]
    },
    {
      phase: "02",
      title: "Development",
      steps: [
        "Data Preparation",
        "Model Development",
        "Training & Testing",
        "Performance Tuning"
      ]
    },
    {
      phase: "03",
      title: "Deployment",
      steps: [
        "Infrastructure Setup",
        "Integration",
        "Testing & Validation",
        "User Training"
      ]
    },
    {
      phase: "04",
      title: "Optimization",
      steps: [
        "Performance Monitoring",
        "Model Updates",
        "System Optimization",
        "Ongoing Support"
      ]
    }
  ];

  const caseStudies = getCaseStudiesByService('ai-solutions');

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="AI Solutions"
        description="Transforming businesses with intelligent AI solutions. Leverage machine learning, deep learning, and predictive analytics for your organization."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'AI Solutions',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'Artificial Intelligence',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'AI Solutions',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Machine Learning Solutions',
                  description: 'Custom ML models for complex business challenges'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Deep Learning',
                  description: 'Advanced neural network architectures'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'AI Automation',
                  description: 'Intelligent process automation solutions'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="AI Solutions"
        subtitle="Transforming businesses with intelligent AI solutions"
      />

      {/* Capabilities Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our AI Capabilities</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Advanced artificial intelligence solutions for modern enterprises
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {capabilities.map((capability, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <capability.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-2">{capability.title}</h3>
                <p className="text-sm text-gray-600">{capability.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Solutions Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Solutions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive AI solutions tailored to your business needs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <solution.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{solution.title}</h3>
                <p className="text-gray-600 mb-6">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Features Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Key Features</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Enterprise-grade features for reliable AI solutions
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10 flex items-start gap-4">
                <div className="flex-shrink-0">
                  <feature.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Process Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Process</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to AI solution development
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {process.map((phase, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{phase.phase}</div>
                <h3 className="text-xl font-semibold mb-2">{phase.title}</h3>
                <ul className="space-y-2">
                  {phase.steps.map((step, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful AI implementations"
        background="gray"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Implement AI Solutions?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how our AI solutions can transform your business operations.
          </p>
          <Link to="/contact" className="button-secondary">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
}

export default AISolutions;