import React, { useState, useEffect } from 'react';
import { Activity, Filter, RefreshCw, Clock, AlertTriangle, CheckCircle, Info, XCircle, Download, Archive } from 'lucide-react';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import SearchInput from '../../components/admin/SearchInput';
import FilterDropdown from '../../components/admin/FilterDropdown';
import DataTable from '../../components/admin/DataTable';
import { getActivityLogs, exportActivityLogs, archiveActivityLogs } from '../../lib/audit';
import { format, subMonths } from 'date-fns';
import toast from 'react-hot-toast';

function ActivityPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [showArchived, setShowArchived] = useState(false);
  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [stats, setStats] = useState({
    total: 0,
    errors: 0,
    warnings: 0,
    info: 0
  });

  useEffect(() => {
    loadLogs();
  }, [showArchived]);

  useEffect(() => {
    // Calculate stats whenever logs change
    const newStats = logs.reduce((acc, log) => ({
      total: acc.total + 1,
      errors: acc.errors + (log.status === 'error' ? 1 : 0),
      warnings: acc.warnings + (log.status === 'warning' ? 1 : 0),
      info: acc.info + (log.status === 'info' ? 1 : 0)
    }), { total: 0, errors: 0, warnings: 0, info: 0 });
    setStats(newStats);
  }, [logs]);

  const loadLogs = async () => {
    try {
      setLoading(true);
      const { data } = await getActivityLogs(1, 100, { archived: showArchived });
      setLogs(data);
    } catch (error) {
      console.error('Error loading activity logs:', error);
      toast.error('Failed to load activity logs');
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      setExporting(true);
      const data = await exportActivityLogs({
        status: statusFilter !== 'all' ? statusFilter : undefined,
        category: typeFilter !== 'all' ? typeFilter : undefined,
        archived: showArchived
      });

      // Convert to CSV
      const headers = ['Timestamp', 'User', 'Action', 'Details', 'Status', 'Category', 'IP Address'];
      const csvContent = [
        headers.join(','),
        ...data.map(log => [
          format(new Date(log.timestamp), 'yyyy-MM-dd HH:mm:ss'),
          log.get_activity_log_user ? `${log.get_activity_log_user.first_name} ${log.get_activity_log_user.last_name}` : 'System',
          log.action,
          `"${log.details.replace(/"/g, '""')}"`,
          log.status,
          log.category || '',
          log.ip_address || ''
        ].join(','))
      ].join('\n');

      // Download file
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `activity-logs-${format(new Date(), 'yyyy-MM-dd')}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      toast.success('Activity logs exported successfully');
    } catch (error) {
      console.error('Error exporting logs:', error);
      toast.error('Failed to export logs');
    } finally {
      setExporting(false);
    }
  };

  const handleArchive = async () => {
    if (!confirm('Are you sure you want to archive logs older than 3 months?')) return;

    try {
      setArchiving(true);
      await archiveActivityLogs(subMonths(new Date(), 3));
      toast.success('Old logs archived successfully');
      loadLogs();
    } catch (error) {
      console.error('Error archiving logs:', error);
      toast.error('Failed to archive logs');
    } finally {
      setArchiving(false);
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'success':
        return <CheckCircle className="w-4 h-4 text-green-500" />;
      case 'error':
        return <XCircle className="w-4 h-4 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="w-4 h-4 text-amber-500" />;
      default:
        return <Info className="w-4 h-4 text-blue-500" />;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'success':
        return 'bg-green-100 text-green-800';
      case 'error':
        return 'bg-red-100 text-red-800';
      case 'warning':
        return 'bg-amber-100 text-amber-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  return (
    <div>
      <ContentHeader
        title="Activity Logs"
        description="Monitor system activity and user actions"
        icon={Activity}
        actions={
          <>
            <Button
              variant="outline"
              icon={Archive}
              onClick={handleArchive}
              loading={archiving}
            >
              Archive Old Logs
            </Button>
            <Button
              variant="outline"
              icon={Download}
              onClick={handleExport}
              loading={exporting}
            >
              Export Logs
            </Button>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={loadLogs}
            >
              Refresh
            </Button>
          </>
        }
      />

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
        <Card
          title="Total Events"
          icon={Activity}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-primary">{stats.total}</div>
            <p className="text-sm text-gray-600 mt-1">Activity events</p>
          </div>
        </Card>

        <Card
          title="Errors"
          icon={XCircle}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-red-600">{stats.errors}</div>
            <p className="text-sm text-gray-600 mt-1">Error events</p>
          </div>
        </Card>

        <Card
          title="Warnings"
          icon={AlertTriangle}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-amber-600">{stats.warnings}</div>
            <p className="text-sm text-gray-600 mt-1">Warning events</p>
          </div>
        </Card>

        <Card
          title="Info Events"
          icon={Info}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-blue-600">{stats.info}</div>
            <p className="text-sm text-gray-600 mt-1">Info events</p>
          </div>
        </Card>
      </div>

      <Card>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search activity logs..."
            className="md:w-64"
          />
          <div className="flex gap-4">
            <FilterDropdown
              label="Status"
              options={[
                { value: 'all', label: 'All Status' },
                { value: 'success', label: 'Success' },
                { value: 'error', label: 'Error' },
                { value: 'warning', label: 'Warning' },
                { value: 'info', label: 'Info' }
              ]}
              value={statusFilter}
              onChange={setStatusFilter}
            />
            <FilterDropdown
              label="Category"
              options={[
                { value: 'all', label: 'All Categories' },
                { value: 'auth', label: 'Authentication' },
                { value: 'content', label: 'Content' },
                { value: 'email', label: 'Email' },
                { value: 'system', label: 'System' }
              ]}
              value={categoryFilter}
              onChange={setCategoryFilter}
            />
            <FilterDropdown
              label="Type"
              options={[
                { value: 'all', label: 'All Types' },
                { value: 'user', label: 'User Activity' },
                { value: 'content', label: 'Content Changes' },
                { value: 'system', label: 'System Events' },
                { value: 'security', label: 'Security Events' }
              ]}
              value={typeFilter}
              onChange={setTypeFilter}
            />
          </div>
          <div className="flex items-center ml-auto">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={showArchived}
                onChange={(e) => setShowArchived(e.target.checked)}
                className="w-4 h-4 text-accent border-gray-300 rounded focus:ring-accent"
              />
              <span className="text-sm text-gray-600">Show Archived</span>
            </label>
          </div>
        </div>

        <DataTable
          columns={[
            { 
              header: 'Timestamp',
              accessor: (row) => (
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4 text-gray-400" />
                  <span>{format(new Date(row.timestamp), 'MMM d, yyyy HH:mm:ss')}</span>
                </div>
              ),
              className: 'whitespace-nowrap'
            },
            {
              header: 'User',
              accessor: (row) => {
                const user = row.get_activity_log_user;
                return user ? (
                  <div>
                    <div className="font-medium text-gray-900">
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">{user.email}</div>
                  </div>
                ) : (
                  <span className="text-gray-500">System</span>
                );
              }
            },
            {
              header: 'Action',
              accessor: 'action',
              className: 'font-medium text-gray-900'
            },
            {
              header: 'Details',
              accessor: (row) => (
                <div>
                  <div className="text-gray-600">{row.details}</div>
                  {row.get_activity_log_metadata && Object.keys(row.get_activity_log_metadata).length > 0 && (
                    <div className="mt-1 text-xs text-gray-500">
                      {JSON.stringify(row.get_activity_log_metadata)}
                    </div>
                  )}
                </div>
              )
            },
            {
              header: 'Status',
              accessor: (row) => (
                <div className="flex items-center gap-2">
                  {getStatusIcon(row.status)}
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    getStatusColor(row.status)
                  }`}>
                    {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                  </span>
                </div>
              )
            }
          ]}
          data={logs.filter(log => {
            const matchesSearch = 
              log.action?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              log.details?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              log.get_activity_log_user?.email?.toLowerCase().includes(searchQuery.toLowerCase());
            
            const matchesStatus = statusFilter === 'all' || log.status === statusFilter;
            const matchesCategory = categoryFilter === 'all' || log.category === categoryFilter;
            
            let matchesType = true;
            if (typeFilter !== 'all') {
              switch (typeFilter) {
                case 'user':
                  matchesType = log.action.startsWith('User');
                  break;
                case 'content':
                  matchesType = log.action.includes('created') || log.action.includes('updated') || log.action.includes('deleted');
                  break;
                case 'system':
                  matchesType = log.action.startsWith('System');
                  break;
                case 'security':
                  matchesType = log.action.startsWith('Security');
                  break;
              }
            }
            
            return matchesSearch && matchesStatus && matchesCategory && matchesType;
          })}
          loading={loading}
        />
      </Card>
    </div>
  );
}

export default ActivityPage;