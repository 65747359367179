import React, { useState, useEffect, useRef } from 'react';
import { MapPin } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import GlobalReachMap from '../components/GlobalReachMap';
import { ContactFormSkeleton, MapSkeleton } from '../components/Skeleton'; 
import EmailAddress from '../components/EmailAddress';
import ContactForm from '../components/ContactForm';
import { Toaster } from 'react-hot-toast';

function Contact() {
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState({
    form: false,
    map: false
  });
  const [activeRegion, setActiveRegion] = useState<string | undefined>();

  const sectionsRef = useRef<{ [key: string]: HTMLDivElement | null }>({
    form: null,
    map: null
  });

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.getAttribute('data-section');
          if (sectionId) {
            setIsVisible(prev => ({
              ...prev,
              [sectionId]: entry.isIntersecting
            }));
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    // Observe all sections
    Object.keys(sectionsRef.current).forEach(sectionId => {
      const element = sectionsRef.current[sectionId];
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [loading]); // Re-run when loading state changes

  const regions = [
    {
      name: "Middle East",
      description: "Comprehensive coverage across GCC countries"
    },
    {
      name: "North Africa",
      description: "Strong presence in renewable energy sector"
    },
    {
      name: "Europe",
      description: "Strategic partnerships and operations"
    },
    {
      name: "North America",
      description: "Advanced technology solutions"
    }
  ];

  if (loading) {
    return (
      <div className="pt-20 bg-white">
        <PageHeader
          title="Contact Us"
          subtitle="Get in touch with our team to discuss your technology needs"
        />
        <Section>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-5">
              <ContactFormSkeleton />
            </div>
            <div className="lg:col-span-7">
              <MapSkeleton />
            </div>
          </div>
        </Section>
      </div>
    );
  }

  return (
    <div className="pt-20 bg-white">
      <Toaster position="top-right" />
      <SEO 
        title="Contact Us"
        description="Get in touch with our global team. With offices in Austin, Dubai, and key locations worldwide, we're ready to help with your technology needs."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'ContactPage',
          mainEntity: {
            '@type': 'Organization',
            name: 'Senses Innovations',
            url: 'https://sensesinnovations.com',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+1-713-474-4747',
                contactType: 'customer service',
                areaServed: ['US', 'SA', 'AE', 'EG'],
                availableLanguage: ['English', 'Arabic'],
                contactOption: ['TollFree', 'HearingImpairedSupported']
              },
              {
                '@type': 'ContactPoint',
                email: 'info@sensesinnovations.com',
                contactType: 'customer support'
              }
            ],
            location: [
              {
                '@type': 'Place',
                name: 'Global Headquarters',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: '5900 Balcones Dr. STE 100',
                  addressLocality: 'Austin',
                  addressRegion: 'TX',
                  postalCode: '78731',
                  addressCountry: 'US'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="Contact Us"
        subtitle="Get in touch with our team to discuss your technology needs"
      />

      <Section>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Left Column - Form */}
          <div 
            ref={(el) => { sectionsRef.current.form = el; }}
            data-section="form"
            className={`lg:col-span-5 space-y-8 transition-all duration-700 transform ${
              isVisible.form ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-8'
            }`}
          >
            <div className="card group">
              <div className="card-gradient" />
              <div className="relative z-10">
                <h2 className="text-xl md:text-2xl font-bold text-primary mb-6">Send us a message</h2>
                <ContactForm />
              </div>
            </div>

            {/* Contact Information */}
            <div className="card group">
              <div className="card-gradient" />
              <div className="relative z-10">
                <h3 className="text-lg font-semibold text-primary mb-4">Contact Information</h3>
                <div className="space-y-4">
                  <EmailAddress 
                    maskedEmail="info@sensesin***.com"
                    actualEmail="info@sensesinnovations.com"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Map */}
          <div 
            ref={(el) => { sectionsRef.current.map = el; }}
            data-section="map"
            className={`lg:col-span-7 transition-all duration-700 transform ${
              isVisible.map ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-8'
            }`}
          >
            <div className="card group h-full">
              <div className="card-gradient" />
              <div className="relative z-10 h-full">
                <h2 className="text-xl md:text-2xl font-bold text-primary mb-6">Global Presence</h2>
                
                {/* Address Section */}
                <div className="flex items-start gap-3 mb-6">
                  <MapPin className="w-5 h-5 text-accent flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-semibold text-primary">United States</h3>
                    <p className="text-xs text-accent mb-2">Global Headquarters</p>
                    <p className="text-gray-600 text-sm">5900 Balcones Dr. STE 100</p>
                    <p className="text-gray-600 text-sm">Austin, TX 78731</p>
                    <p className="text-gray-600 text-sm">United States</p>
                  </div>
                </div>

                <div className="h-[600px]">
                  <GlobalReachMap activeRegion={activeRegion} />
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 mt-6">
                  {regions.map((region, index) => (
                    <button 
                      key={index}
                      className={`px-3 md:px-4 py-2 rounded-lg text-sm md:text-base font-medium transition-all duration-300 border ${
                        activeRegion === region.name
                          ? 'bg-primary text-white border-primary shadow-lg shadow-primary/10'
                          : 'bg-white text-gray-600 border-gray-200 hover:bg-gray-50 hover:border-primary/20'
                      }`}
                      onMouseEnter={() => setActiveRegion(region.name)}
                      onMouseLeave={() => setActiveRegion(undefined)}
                    >
                      <span className="block truncate">{region.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default Contact;