import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronLeft, ChevronRight, ExternalLink } from 'lucide-react';
import type { CaseStudy } from '../data/caseStudies';
import { CardSkeleton } from './Skeleton';

interface CaseStudiesSectionProps {
  title?: string;
  subtitle?: string;
  caseStudies: CaseStudy[];
  showViewAll?: boolean;
  viewAllLink?: string;
  background?: 'white' | 'gray';
  limit?: number;
  loading?: boolean;
}

function CaseStudiesSection({ 
  title = "Success Stories",
  subtitle = "Real-world examples of successful implementations",
  caseStudies,
  showViewAll = false,
  viewAllLink,
  background = 'white',
  limit,
  loading = false
}: CaseStudiesSectionProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const autoScrollIntervalRef = useRef<NodeJS.Timeout>();

  if (loading) {
    return (
      <section className={`py-16 ${background === 'gray' ? 'bg-gray-50' : 'bg-white'}`}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">{title}</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              {subtitle}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[1, 2, 3].map((i) => (
              <CardSkeleton key={i} />
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (!caseStudies || caseStudies.length === 0) {
    return null;
  }

  const displayedCaseStudies = limit ? caseStudies.slice(0, limit) : caseStudies;
  const cardsPerView = 3;
  const totalSlides = Math.ceil(displayedCaseStudies.length / cardsPerView);

  useEffect(() => {
    if (autoScrollEnabled && displayedCaseStudies.length > cardsPerView) {
      autoScrollIntervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % totalSlides;
          return nextIndex;
        });
      }, 5000); // Scroll every 5 seconds
    }

    return () => {
      if (autoScrollIntervalRef.current) {
        clearInterval(autoScrollIntervalRef.current);
      }
    };
  }, [autoScrollEnabled, displayedCaseStudies.length, totalSlides]);

  const handleNavigation = (direction: 'prev' | 'next') => {
    setAutoScrollEnabled(false);
    if (direction === 'prev' && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (direction === 'next' && currentIndex < totalSlides - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <section className={`py-16 ${background === 'gray' ? 'bg-gray-50' : 'bg-white'}`}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">{title}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {subtitle}
          </p>
        </div>
        <div className="relative">
          <div 
            ref={scrollContainerRef}
            className="overflow-hidden"
          >
            <div 
              className="flex transition-transform duration-500 ease-in-out"
              style={{ 
                transform: `translateX(-${currentIndex * (100 / totalSlides)}%)`,
                width: `${totalSlides * 100}%`
              }}
            >
              {displayedCaseStudies.map((study, index) => (
                <div
                  key={index}
                  className="px-3"
                  style={{ width: `${100 / displayedCaseStudies.length}%` }}
                >
                  <div className="card group h-full hover:shadow-xl hover:shadow-primary/5 hover:scale-[1.02] transition-all duration-500">
                    <div className="card-gradient" />
                    <div className="relative z-10 p-2 md:p-0">
                      <div 
                        className="h-64 rounded-xl bg-cover bg-center mb-6 transition-all duration-500 relative overflow-hidden transform group-hover:scale-[1.03]"
                        style={{ backgroundImage: `url(${study.image})` }}
                      >
                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500" />
                      </div>
                      <div className="space-y-4 transform transition-all duration-500 group-hover:translate-y-[-8px]">
                        <div>
                          <h3 className="text-xl md:text-2xl font-semibold text-primary group-hover:text-accent transition-colors duration-300">{study.title}</h3>
                          <p className="text-base md:text-lg text-primary/80 font-medium">{study.subtitle}</p>
                        </div>
                        <p className="text-sm md:text-base text-gray-600 leading-relaxed">{study.description}</p>
                        <ul className="space-y-2 mb-4">
                          {study.results.map((result, idx) => (
                            <li key={idx} className="text-sm md:text-base text-gray-600 flex items-start gap-2 md:gap-3">
                              <span className="w-1.5 h-1.5 bg-accent rounded-full flex-shrink-0 mt-1.5"></span>
                              {result}
                            </li>
                          ))}
                        </ul> 
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Buttons */}
          {displayedCaseStudies.length > cardsPerView && (
            <>
              <button
                onClick={() => handleNavigation('prev')}
                className={`absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-lg hover:shadow-xl hover:scale-110 flex items-center justify-center text-primary hover:text-accent transition-all duration-300 ${
                  currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={currentIndex === 0}
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleNavigation('next')}
                className={`absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-lg hover:shadow-xl hover:scale-110 flex items-center justify-center text-primary hover:text-accent transition-all duration-300 ${
                  currentIndex === totalSlides - 1 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={currentIndex === totalSlides - 1}
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </>
          )}

        </div>

        {showViewAll && viewAllLink && caseStudies.length > (limit || 0) && (
          <div className="text-center mt-12">
            <Link 
              to={viewAllLink}
              className="inline-flex items-center gap-2 px-8 py-4 text-primary border-2 border-primary rounded-xl hover:bg-primary hover:text-white transition-all duration-300 font-medium hover:-translate-y-1 hover:shadow-lg"
            >
              <span>View All Case Studies</span>
              <ArrowRight className="w-4 h-4" />
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}

export default CaseStudiesSection;