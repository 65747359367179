import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';

interface CardProps {
  title?: string;
  description?: string;
  icon?: LucideIcon;
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
}

function Card({ title, description, icon: Icon, children, className = '', footer }: CardProps) {
  return (
    <div className={`bg-white dark:bg-gray-800 border border-gray-200/80 dark:border-gray-700/80 rounded-xl overflow-hidden shadow-sm hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-[1.01] hover:-translate-y-1 ${className}`}>
      {(title || description) && (
        <div className="px-6 py-4 border-b border-gray-200/80 dark:border-gray-700/80">
          <div className="flex items-center gap-3">
            {Icon && (
              <div className="p-2 bg-primary/5 dark:bg-primary/10 rounded-lg transition-all duration-300 group-hover:bg-primary/10 dark:group-hover:bg-primary/20">
                <Icon className="w-5 h-5 text-primary dark:text-accent transition-colors duration-300 group-hover:text-accent dark:group-hover:text-primary" />
              </div>
            )}
            <div>
              {title && (
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
              )}
              {description && (
                <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">{description}</p>
              )}
            </div>
          </div>
        </div>
      )}
      
      <div className="px-6 py-4">{children}</div>

      {footer && (
        <div className="px-6 py-4 bg-gray-50/80 dark:bg-gray-800/80 border-t border-gray-200/80 dark:border-gray-700/80">
          {footer}
        </div>
      )}
    </div>
  );
}

export default Card;