import { supabase } from './supabase/client';
import type { SEOData } from '../types/admin';
import { createActivityLog } from './audit';

// Get all routes from the application
function getAllRoutes() {
  return [
    { path: '/', component: 'Home' },
    { path: '/about', component: 'About' },
    { path: '/services', component: 'Services' },
    { path: '/services/web-development', component: 'WebDevelopment' },
    { path: '/services/app-development', component: 'AppDevelopment' },
    { path: '/services/digital-transformation', component: 'DigitalTransformation' },
    { path: '/services/it-consultancy', component: 'ITConsultancy' },
    { path: '/services/infrastructure', component: 'Infrastructure' },
    { path: '/services/ai-solutions', component: 'AISolutions' },
    { path: '/services/analytics', component: 'Analytics' },
    { path: '/industries', component: 'Industries' },
    { path: '/industries/renewables', component: 'Renewables' },
    { path: '/case-studies', component: 'CaseStudies' },
    { path: '/renewable-case-studies', component: 'RenewableCaseStudies' },
    { path: '/contact', component: 'Contact' },
    { path: '/faq', component: 'FAQPage' }
  ];
}

// Extract SEO data from a component
function extractSEOData(component: string): SEOData {
  const title = component.replace(/([A-Z])/g, ' $1').trim();
  const description = `${title} - Senses Innovations provides comprehensive ${title.toLowerCase()} solutions for businesses.`;
  
  return {
    title: `${title} | Senses Innovations`,
    description,
    slug: component.toLowerCase(),
    ogTitle: title,
    ogDescription: description,
    ogImage: 'https://sensesinnovations.com/og-image.jpg',
    noIndex: false,
    canonicalUrl: ''
  };
}

// Scan all pages and update SEO data
export async function scanPages(userId: string) {
  const results = { created: 0, updated: 0, errors: 0 };
  
  try {
    // Check if user exists and has admin/editor role
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', userId)
      .single();

    if (profileError) {
      throw new Error('Failed to verify user permissions');
    }

    if (!profile || !['admin', 'editor'].includes(profile.role)) {
      throw new Error('Unauthorized: Insufficient permissions');
    }

    const routes = getAllRoutes();
    const timestamp = new Date().toISOString();

    // Process each route
    for (const route of routes) {
      try {
        const seoData = extractSEOData(route.component);

        // Check if page exists
        const { data: existingPage, error: fetchError } = await supabase
          .from('seo_pages')
          .select('id, last_modified')
          .eq('path', route.path)
          .maybeSingle(); // Use maybeSingle instead of single to avoid errors

        if (fetchError) {
          throw fetchError;
        }

        if (existingPage) {
          // Update existing page
          const { error: updateError } = await supabase
            .from('seo_pages')
            .update({
              title: seoData.title,
              description: seoData.description,
              og_title: seoData.ogTitle,
              og_description: seoData.ogDescription,
              og_image: seoData.ogImage,
              no_index: seoData.noIndex,
              canonical_url: seoData.canonicalUrl,
              last_scanned: timestamp,
              updated_by: userId,
              last_modified: timestamp
            })
            .eq('id', existingPage.id);

          if (updateError) throw updateError;
          results.updated++;
        } else {
          // Insert new page
          const { error: insertError } = await supabase
            .from('seo_pages')
            .insert({
              path: route.path,
              title: seoData.title,
              description: seoData.description,
              og_title: seoData.ogTitle,
              og_description: seoData.ogDescription,
              og_image: seoData.ogImage,
              no_index: seoData.noIndex,
              canonical_url: seoData.canonicalUrl,
              last_scanned: timestamp,
              last_modified: timestamp,
              created_by: userId,
              updated_by: userId
            });

          if (insertError) throw insertError;
          results.created++;
        }

        // Log success for each page
        await createActivityLog(
          userId,
          'SEO Update',
          `Updated SEO data for ${route.path}`,
          'success'
        );
      } catch (error) {
        console.error(`Error processing ${route.path}:`, error);
        results.errors++;
        
        // Log error for each failed page
        await createActivityLog(
          userId,
          'SEO Error',
          `Failed to process ${route.path}: ${(error as Error).message}`,
          'error'
        );
      }
    }

    // Log final summary
    await createActivityLog(
      userId,
      'SEO Scan Complete',
      `Scan completed: ${results.created} created, ${results.updated} updated, ${results.errors} errors`,
      results.errors > 0 ? 'warning' : 'success'
    );

    return { success: true, results };
  } catch (error) {
    console.error('Error scanning pages:', error);
    
    // Log critical error
    await createActivityLog(
      userId,
      'SEO Scan Failed',
      `Critical error: ${(error as Error).message}`,
      'error'
    );

    throw error;
  }
}

// Get SEO data for a specific page
export async function getPageSEO(path: string) {
  const { data, error } = await supabase
    .from('seo_pages')
    .select('*')
    .eq('path', path)
    .single();

  if (error) throw error;
  return data;
}

// Update SEO data for a specific page
export async function updatePageSEO(pageId: string, seoData: Partial<SEOData>, userId: string) {
  try {
    // Check permissions
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', userId)
      .single();

    if (profileError) {
      throw new Error('Failed to verify user permissions');
    }

    if (!profile || !['admin', 'editor'].includes(profile.role)) {
      throw new Error('Unauthorized: Insufficient permissions');
    }

    const { error } = await supabase
      .from('seo_pages')
      .update({
        ...seoData,
        last_modified: new Date().toISOString(),
        updated_by: userId
      })
      .eq('id', pageId);

    if (error) throw error;

    // Log success
    await createActivityLog(
      userId,
      'SEO Update',
      `Updated SEO data for page ID: ${pageId}`,
      'success'
    );

    return { success: true };
  } catch (error) {
    // Log error
    await createActivityLog(
      userId,
      'SEO Update Failed',
      `Failed to update page ${pageId}: ${(error as Error).message}`,
      'error'
    );

    throw error;
  }
}

// Schedule regular SEO scans
export function scheduleScans(userId: string, intervalMinutes = 60) {
  // Validate user permissions before scheduling
  supabase
    .from('profiles')
    .select('role')
    .eq('id', userId)
    .single()
    .then(({ data: profile, error }) => {
      if (error || !profile || !['admin', 'editor'].includes(profile.role)) {
        console.error('Cannot schedule scans: Insufficient permissions');
        return;
      }

      setInterval(() => {
        scanPages(userId).catch(console.error);
      }, intervalMinutes * 60 * 1000);
    })
    .catch(console.error);
}