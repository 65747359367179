import React, { memo } from 'react';
import { MapSkeleton } from './Skeleton';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

interface Location {
  name: string;
  coordinates: [number, number];
  region: string;
  subtext?: string;
  tooltipPosition: 'top' | 'bottom' | 'left' | 'right';
}

const locations: Location[] = [
  {
    name: "USA",
    coordinates: [-98.5795, 39.8283],
    region: "North America",
    subtext: "HQ in Austin, TX with nationwide coverage",
    tooltipPosition: 'right'
  },
  {
    name: "UAE",
    coordinates: [55.2708, 25.2048], // Dubai coordinates
    region: "Middle East",
    subtext: "Regional operations across the GCC",
    tooltipPosition: 'right'
  },
  {
    name: "KSA",
    coordinates: [46.6753, 24.7136], // Riyadh coordinates
    region: "Middle East",
    subtext: "Renewalble Projects Experience",
    tooltipPosition: 'left'
  },
  {
    name: "Egypt",
    coordinates: [30.5192, 27.5142],
    region: "North Africa",
    subtext: "Specialized teams supporting major renewable projects",
    tooltipPosition: 'left'
  },
  {
    name: "France",
    coordinates: [2.2137, 46.2276],
    region: "Europe",
    subtext: "Technology innovation hub serving European markets",
    tooltipPosition: 'top'
  }
];

interface GlobalReachMapProps {
  activeRegion?: string;
  loading?: boolean;
}

function GlobalReachMap({ activeRegion, loading = false }: GlobalReachMapProps) {
  if (loading) {
    return <MapSkeleton />;
  }

  return (
    <div className="relative w-full h-full min-h-[600px]">
      <div className="absolute inset-0 bg-white rounded-2xl shadow-lg">
        <div className="absolute inset-0 bg-gradient-to-br from-accent/5 to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
        <div className="relative h-full">
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{
              scale: 150,
              center: [20, 30],
              rotate: [-10, 0, 0]
            }}
            style={{
              width: "100%",
              height: "100%",
              minHeight: "600px",
              backgroundColor: "#0B132905"
            }}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#0B132908"
                    stroke="#0B132915"
                    strokeWidth={0.5}
                    style={{
                      default: {
                        outline: "none",
                        transition: "all 250ms"
                      },
                      hover: {
                        fill: "#0B132915",
                        stroke: "#0B1329",
                        strokeWidth: 0.75,
                        outline: "none",
                        cursor: "default"
                      },
                      pressed: {
                        fill: "#0B132915",
                        stroke: "#0B1329",
                        strokeWidth: 0.75,
                        outline: "none"
                      }
                    }}
                  />
                ))
              }
            </Geographies>

            {locations.map((location) => {
              const isActive = activeRegion === location.region;
              const scale = isActive ? 1.5 : 1;
              const opacity = activeRegion ? (isActive ? 1 : 0.3) : 1;

              return (
                <Marker key={location.name} coordinates={location.coordinates}>
                  <g style={{ 
                    transform: `scale(${scale})`,
                    transition: "all 0.3s ease-in-out",
                    opacity: opacity
                  }}>
                    {/* Outer pulse ring */}
                    <circle
                      r="20"
                      fill="#6EFFEA"
                      fillOpacity="0.1"
                      className={`animate-[ping_3s_cubic-bezier(0,0,0.2,1)_infinite] ${isActive ? 'animate-[ping_2s_cubic-bezier(0,0,0.2,1)_infinite]' : ''}`}
                    />
                    
                    {/* Middle pulse ring */}
                    <circle
                      r="15"
                      fill="#6EFFEA"
                      fillOpacity="0.2"
                      className={`animate-[ping_2.5s_cubic-bezier(0,0,0.2,1)_infinite] ${isActive ? 'animate-[ping_1.5s_cubic-bezier(0,0,0.2,1)_infinite]' : ''}`}
                    />
                    
                    {/* Inner pulse ring */}
                    <circle
                      r="10"
                      fill="#6EFFEA"
                      fillOpacity="0.3"
                      className={`animate-[ping_2s_cubic-bezier(0,0,0.2,1)_infinite] ${isActive ? 'animate-[ping_1s_cubic-bezier(0,0,0.2,1)_infinite]' : ''}`}
                    />

                    {/* Main marker dot with glow */}
                    <circle
                      r="4"
                      fill="#6EFFEA"
                      className={`drop-shadow-[0_0_8px_rgba(110,255,234,0.8)] ${isActive ? 'drop-shadow-[0_0_12px_rgba(110,255,234,1)]' : ''}`}
                    />

                    {/* Inner dot for contrast */}
                    <circle
                      r="2"
                      fill="#6EFFEA"
                      className={`drop-shadow-[0_0_4px_rgba(110,255,234,1)] ${isActive ? 'drop-shadow-[0_0_8px_rgba(110,255,234,1)]' : ''}`}
                    />

                    {/* Location name */}
                    <text
                      textAnchor="middle"
                      y={-20}
                      style={{
                        fontFamily: "'Hind', sans-serif",
                        fontSize: "14px",
                        fontWeight: "600",
                        fill: "#0B1329",
                        opacity: 0.8,
                        textShadow: "0 0 10px rgba(255,255,255,0.8)"
                      }}
                      className="drop-shadow-sm"
                    >
                      {location.name}
                    </text>

                    {/* Tooltip */}
                    {isActive && location.subtext && (
                      <foreignObject
                        x={location.tooltipPosition === 'left' ? -220 : location.tooltipPosition === 'right' ? 20 : -100}
                        y={location.tooltipPosition === 'top' ? -80 : location.tooltipPosition === 'bottom' ? 20 : -30}
                        width="200"
                        height="100"
                        style={{
                          overflow: 'visible',
                          pointerEvents: 'none'
                        }}
                      >
                        <div className="bg-white rounded-lg p-3 shadow-lg border border-gray-100 relative">
                          <p className="text-sm text-gray-600">
                            {location.subtext}
                          </p>
                          {/* Tooltip arrow */}
                          <div 
                            className={`absolute w-3 h-3 bg-white border-t border-l border-gray-100 transform rotate-45 ${
                              location.tooltipPosition === 'left' ? 'right-0 top-1/2 -translate-y-1/2 translate-x-1.5' :
                              location.tooltipPosition === 'right' ? 'left-0 top-1/2 -translate-y-1/2 -translate-x-1.5' :
                              location.tooltipPosition === 'top' ? 'bottom-0 left-1/2 -translate-x-1/2 translate-y-1.5' :
                              'top-0 left-1/2 -translate-x-1/2 -translate-y-1.5'
                            }`}
                          />
                        </div>
                      </foreignObject>
                    )}
                  </g>
                </Marker>
              );
            })}
          </ComposableMap>
        </div>
      </div>
    </div>
  );
}

export default memo(GlobalReachMap);