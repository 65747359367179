import React, { useState, useEffect } from 'react';
import { LayoutDashboard, FileText, Users, Activity, AlertTriangle } from 'lucide-react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import ContentHeader from '../../components/admin/ContentHeader';
import DashboardCard from '../../components/admin/DashboardCard';
import Card from '../../components/admin/Card';
import DataTable from '../../components/admin/DataTable';
import { format } from 'date-fns';
import { supabase } from '../../lib/supabase/client';
import { useTheme } from '../../context/ThemeContext';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function DashboardPage() {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(true);
  const [recentActivity, setRecentActivity] = useState<any[]>([]);
  const [securityAlerts, setSecurityAlerts] = useState<any[]>([]);
  const [stats, setStats] = useState({
    caseStudies: { total: 0, published: 0, trend: 0 },
    users: { total: 0, active: 0, trend: 0 },
    pageViews: { total: 0, trend: 0 },
    securityAlerts: { total: 0, trend: 0 }
  });
  const [analyticsData, setAnalyticsData] = useState({
    pageViews: [] as any[],
    userActivity: [] as any[],
    contentDistribution: [] as any[],
    securityEvents: [] as any[]
  });
  const [lastUpdate, setLastUpdate] = useState(new Date());

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async (showLoadingState = true) => {
    try {
      if (showLoadingState) {
        setLoading(true);
      }

      // Load case studies stats
      const { data: caseStudies, error: caseStudiesError } = await supabase
        .from('case_studies')
        .select('id, status', { count: 'exact' });

      if (caseStudiesError) throw caseStudiesError;

      // Load user stats
      const { data: users, error: usersError } = await supabase
        .from('profiles')
        .select('id, status', { count: 'exact' });

      if (usersError) throw usersError;

      // Load recent activity
      const { data: activity, error: activityError } = await supabase
        .from('activity_logs')
        .select(`
          *,
          get_activity_log_user
        `)
        .order('timestamp', { ascending: false })
        .limit(5);

      if (activityError) throw activityError;

      // Load security alerts
      const { data: alerts, error: alertsError } = await supabase
        .from('activity_logs')
        .select(`
          *,
          get_activity_log_user
        `)
        .eq('category', 'security')
        .order('timestamp', { ascending: false })
        .limit(5);

      if (alertsError) throw alertsError;

      // Generate sample analytics data (replace with real data in production)
      const last30Days = Array.from({ length: 30 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - (29 - i));
        return date;
      });

      const pageViewsData = last30Days.map(date => ({
        date,
        value: Math.floor(Math.random() * 1000) + 500
      }));

      const userActivityData = last30Days.map(date => ({
        date,
        active: Math.floor(Math.random() * 100) + 50,
        new: Math.floor(Math.random() * 20) + 5
      }));

      const contentDistribution = [
        { label: 'Case Studies', value: caseStudies?.length || 0 },
        { label: 'FAQs', value: Math.floor(Math.random() * 50) + 20 },
        { label: 'Services', value: Math.floor(Math.random() * 20) + 10 },
        { label: 'Pages', value: Math.floor(Math.random() * 30) + 15 }
      ];

      const securityEventsData = last30Days.map(date => ({
        date,
        success: Math.floor(Math.random() * 100) + 80,
        failed: Math.floor(Math.random() * 20) + 5
      }));

      setStats({
        caseStudies: {
          total: caseStudies?.length || 0,
          published: caseStudies?.filter(cs => cs.status === 'published').length || 0,
          trend: 15
        },
        users: {
          total: users?.length || 0,
          active: users?.filter(u => u.status === 'active').length || 0,
          trend: 8
        },
        pageViews: {
          total: pageViewsData.reduce((sum, day) => sum + day.value, 0),
          trend: 12
        },
        securityAlerts: {
          total: alerts?.length || 0,
          trend: -5
        }
      });

      setAnalyticsData({
        pageViews: pageViewsData,
        userActivity: userActivityData,
        contentDistribution,
        securityEvents: securityEventsData
      });

      setRecentActivity(activity || []);
      setSecurityAlerts(alerts || []);
      setLastUpdate(new Date());
    } catch (error) {
      console.error('Error loading dashboard data:', error);
    } finally {
      if (showLoadingState) {
        setLoading(false);
      }
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: theme === 'dark' ? '#e5e7eb' : '#1f2937',
          font: {
            family: "'Hind', sans-serif"
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          color: theme === 'dark' ? '#e5e7eb' : '#1f2937',
          font: {
            family: "'Hind', sans-serif"
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          color: theme === 'dark' ? '#e5e7eb' : '#1f2937',
          font: {
            family: "'Hind', sans-serif"
          }
        }
      }
    }
  };

  const chartColors = {
    primary: theme === 'dark' ? '#6EFFEA' : '#0B1329',
    accent: theme === 'dark' ? '#0B1329' : '#6EFFEA',
    line: theme === 'dark' ? 'rgba(110, 255, 234, 0.2)' : 'rgba(11, 19, 41, 0.1)',
    text: theme === 'dark' ? '#e5e7eb' : '#1f2937'
  };

  return (
    <div>
      <ContentHeader
        title="Dashboard"
        description={`Overview of your website's performance and recent activity • Last updated ${format(lastUpdate, 'h:mm:ss a')}`}
        icon={LayoutDashboard}
      />

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <DashboardCard
          title="Case Studies"
          value={stats.caseStudies.total.toString()}
          icon={FileText}
          description={`${stats.caseStudies.published} published`}
          trend={{ 
            value: stats.caseStudies.trend, 
            label: "vs last month", 
            isPositive: stats.caseStudies.trend > 0 
          }}
        />
        <DashboardCard
          title="Active Users"
          value={stats.users.active.toString()}
          icon={Users}
          description={`${stats.users.total} total users`}
          trend={{ 
            value: stats.users.trend, 
            label: "vs last month", 
            isPositive: stats.users.trend > 0 
          }}
        />
        <DashboardCard
          title="Page Views"
          value={stats.pageViews.total.toLocaleString()}
          icon={Activity}
          description="Last 30 days"
          trend={{ 
            value: stats.pageViews.trend, 
            label: "vs last month", 
            isPositive: stats.pageViews.trend > 0 
          }}
        />
        <DashboardCard
          title="Security Alerts"
          value={stats.securityAlerts.total.toString()}
          icon={AlertTriangle}
          description="Requires attention"
          trend={{ 
            value: Math.abs(stats.securityAlerts.trend), 
            label: "vs last week", 
            isPositive: stats.securityAlerts.trend < 0 
          }}
        />
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Page Views Chart */}
        <Card className="col-span-2">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Page Views</h3>
          <div className="h-[300px]">
            <Line
              data={{
                labels: analyticsData.pageViews.map(day => 
                  format(day.date, 'MMM d')
                ),
                datasets: [
                  {
                    label: 'Page Views',
                    data: analyticsData.pageViews.map(day => day.value),
                    borderColor: chartColors.primary,
                    backgroundColor: chartColors.line,
                    fill: true,
                    tension: 0.4
                  }
                ]
              }}
              options={chartOptions}
            />
          </div>
        </Card>

        {/* User Activity Chart */}
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">User Activity</h3>
          <div className="h-[300px]">
            <Bar
              data={{
                labels: analyticsData.userActivity.slice(-7).map(day => 
                  format(day.date, 'MMM d')
                ),
                datasets: [
                  {
                    label: 'Active Users',
                    data: analyticsData.userActivity.slice(-7).map(day => day.active),
                    backgroundColor: chartColors.primary
                  },
                  {
                    label: 'New Users',
                    data: analyticsData.userActivity.slice(-7).map(day => day.new),
                    backgroundColor: chartColors.accent
                  }
                ]
              }}
              options={chartOptions}
            />
          </div>
        </Card>

        {/* Content Distribution Chart */}
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Content Distribution</h3>
          <div className="h-[300px]">
            <Doughnut
              data={{
                labels: analyticsData.contentDistribution.map(item => item.label),
                datasets: [
                  {
                    data: analyticsData.contentDistribution.map(item => item.value),
                    backgroundColor: [
                      theme === 'dark' ? '#6EFFEA' : '#0B1329',
                      theme === 'dark' ? '#0B1329' : '#6EFFEA',
                      '#4A90E2',
                      '#50E3C2'
                    ]
                  }
                ]
              }}
              options={{
                ...chartOptions,
                cutout: '70%'
              }}
            />
          </div>
        </Card>
      </div>

      {/* Activity and Security */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Recent Activity */}
        <div className="lg:col-span-2">
          <Card
            title="Recent Activity"
            description="Latest actions taken by users"
          >
            <DataTable
              columns={[
                { 
                  header: 'User',
                  accessor: (row) => {
                    const user = row.get_activity_log_user;
                    return user ? (
                      <div>
                        <div className="font-medium text-gray-900 dark:text-white">
                          {user.first_name} {user.last_name}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {user.email}
                        </div>
                      </div>
                    ) : (
                      <span className="text-gray-500 dark:text-gray-400">System</span>
                    );
                  }
                },
                {
                  header: 'Action',
                  accessor: (row) => (
                    <span className="font-medium text-gray-900 dark:text-white">
                      {row.action}
                    </span>
                  )
                },
                {
                  header: 'Details',
                  accessor: (row) => (
                    <span className="text-gray-600 dark:text-gray-300">
                      {row.details}
                    </span>
                  )
                },
                { 
                  header: 'Time',
                  accessor: (row) => (
                    <div className="text-right text-gray-500 dark:text-gray-400">
                      {format(new Date(row.timestamp), 'MMM d, h:mm:ss a')}
                    </div>
                  )
                }
              ]}
              data={recentActivity}
              loading={loading}
            />
          </Card>
        </div>

        {/* Security Alerts */}
        <Card
          title="Security Alerts"
          description="Recent security events"
        >
          <div className="space-y-4">
            {securityAlerts.map((alert) => (
              <div
                key={alert.id}
                className={`p-4 rounded-lg ${
                  alert.status === 'error'
                    ? 'bg-red-50 dark:bg-red-900/20'
                    : alert.status === 'warning'
                    ? 'bg-yellow-50 dark:bg-yellow-900/20'
                    : 'bg-blue-50 dark:bg-blue-900/20'
                }`}
              >
                <div className="flex items-start justify-between">
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">{alert.action}</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      {format(new Date(alert.timestamp), 'MMM d, h:mm:ss a')}
                    </p>
                  </div>
                  {alert.status === 'error' && <AlertTriangle className="w-5 h-5 text-red-500" />}
                  {alert.status === 'warning' && <AlertTriangle className="w-5 h-5 text-yellow-500" />}
                  {alert.status === 'success' && <Activity className="w-5 h-5 text-blue-500" />}
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">{alert.details}</p>
                {alert.ip_address && (
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">IP: {alert.ip_address}</p>
                )}
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default DashboardPage;