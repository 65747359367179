import { FAQItem } from '../components/FAQ';
import { supabase } from '../lib/supabase/client';

export type ServiceTag = 
  | 'it-consultancy'
  | 'infrastructure'
  | 'digital-transformation'
  | 'app-development'
  | 'ai-solutions'
  | 'web-development'
  | 'analytics'
  | 'renewable-energy'
  | 'general';

export interface TaggedFAQItem extends FAQItem {
  tags: ServiceTag[];
}

export const serviceLabels: Record<ServiceTag, string> = {
  'it-consultancy': 'IT Consultancy',
  'infrastructure': 'Infrastructure',
  'digital-transformation': 'Digital Transformation',
  'app-development': 'App Development',
  'ai-solutions': 'AI Solutions',
  'web-development': 'Web Development',
  'analytics': 'Analytics',
  'renewable-energy': 'Renewable Energy',
  'general': 'General'
};

// Default FAQs for initial data
export const defaultFAQs: TaggedFAQItem[] = [
  // IT Infrastructure & Renewable Energy
  {
    question: "What IT infrastructure is needed for renewable energy projects?",
    answer: "Renewable energy projects require high-performance networking, cloud-based monitoring, cybersecurity, and scalable IT solutions. We specialize in rapid deployment, ensuring full IT infrastructure readiness in under three months, even in remote locations.",
    tags: ['infrastructure', 'renewable-energy']
  },
  {
    question: "How quickly can IT infrastructure be mobilized for renewable energy projects?",
    answer: "Our teams complete full IT mobilization in under three months, ensuring seamless project execution while navigating local regulatory requirements, risk mitigation strategies, and operational constraints.",
    tags: ['infrastructure', 'renewable-energy']
  },
  {
    question: "How does IT improve efficiency in wind farm operations?",
    answer: "IT solutions enable real-time data monitoring, predictive maintenance, and remote management, reducing downtime and improving energy output. Our expertise in fast-track deployments ensures that energy projects remain operational without delays.",
    tags: ['infrastructure', 'renewable-energy', 'analytics']
  },
  {
    question: "Can Senses Innovations support remote wind farms with real-time connectivity?",
    answer: "Yes. Our expertise in backhaul networks, SDN solutions, and cloud-based connectivity ensures that even the most remote renewable energy sites maintain high-speed, secure communication.",
    tags: ['infrastructure', 'renewable-energy']
  }
];

export async function getFAQsByTag(tag: ServiceTag | 'all'): Promise<TaggedFAQItem[]> {
  try {
    const { data, error } = await supabase
      .from('faqs')
      .select('*')
      .eq('status', 'published')
      .order('sort_order', { ascending: true });

    if (error) throw error;

    const faqs = data.map(faq => ({
      question: faq.question,
      answer: faq.answer,
      tags: faq.tags || []
    }));

    return tag === 'all' ? faqs : faqs.filter(faq => faq.tags.includes(tag));
  } catch (error) {
    console.error('Error fetching FAQs:', error);
    // Fallback to default FAQs
    return tag === 'all' ? defaultFAQs : defaultFAQs.filter(faq => faq.tags.includes(tag));
  }
}

export async function getRelatedFAQs(tags: ServiceTag[], limit: number = 3): Promise<TaggedFAQItem[]> {
  try {
    const { data, error } = await supabase
      .from('faqs')
      .select('*')
      .eq('status', 'published')
      .order('sort_order', { ascending: true });

    if (error) throw error;

    const faqs = data.map(faq => ({
      question: faq.question,
      answer: faq.answer,
      tags: faq.tags || []
    }));

    return faqs
      .filter(faq => faq.tags.some(tag => tags.includes(tag as ServiceTag)))
      .slice(0, limit);
  } catch (error) {
    console.error('Error fetching related FAQs:', error);
    // Fallback to default FAQs
    return defaultFAQs
      .filter(faq => faq.tags.some(tag => tags.includes(tag)))
      .slice(0, limit);
  }
}

export { defaultFAQs as faqs };