import React, { useState, useEffect } from 'react';
import { Mail, Code, Eye } from 'lucide-react';
import Modal from './Modal';
import FormField from './FormField';
import Button from './Button';
import { supabase } from '../../lib/supabase/client';
import toast from 'react-hot-toast';

interface EmailTemplateEditorProps {
  isOpen: boolean;
  onClose: () => void;
  template?: any;
}

interface FormData {
  name: string;
  subject: string;
  body: string;
  variables: string[];
  category: string;
  status: 'draft' | 'published';
}

function EmailTemplateEditor({ isOpen, onClose, template }: EmailTemplateEditorProps) {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    subject: '',
    body: '',
    variables: [],
    category: '',
    status: 'draft'
  });

  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (template) {
      const templateVars = Array.isArray(template.variables) ? template.variables : [];
      setFormData({
        name: template.name || '',
        subject: template.subject || '',
        body: template.body || '',
        variables: templateVars,
        category: template.category || '',
        status: template.status || 'draft'
      });

      // Initialize preview data
      const initialPreviewData: Record<string, string> = {};
      templateVars.forEach(v => {
        initialPreviewData[v] = `[${v}]`;
      });
      setPreviewData(initialPreviewData);
    } else {
      // Reset form for new template
      setFormData({
        name: '',
        subject: '',
        body: '',
        variables: [],
        category: '',
        status: 'draft'
      });
      setPreviewData({});
    }
  }, [template]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationErrors = [];
    if (!formData.name) validationErrors.push('Name is required');
    if (!formData.subject) validationErrors.push('Subject is required');
    if (!formData.body) validationErrors.push('Body is required');
    if (!formData.category) validationErrors.push('Category is required');

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setSaving(true);
      
      // For development, use the temporary admin user ID
      const userId = '00000000-0000-0000-0000-000000000000';
      
      const { error } = await supabase
        .from('email_templates')
        .upsert({
          id: template?.id,
          ...formData,
          updated_by: userId,
          created_by: template ? undefined : userId
        });

      if (error) throw error;

      toast.success(template ? 'Template updated' : 'Template created');
      onClose();
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Failed to save template');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field: keyof FormData, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors([]);

    // Update preview data when variables change
    if (field === 'variables' && Array.isArray(value)) {
      const newPreviewData: Record<string, string> = {};
      value.forEach(v => {
        newPreviewData[v] = previewData[v] || `[${v}]`;
      });
      setPreviewData(newPreviewData);
    }
  };

  const handleVariablesChange = (value: string) => {
    const vars = value.split(',')
      .map(v => v.trim())
      .filter(v => v);
    handleChange('variables', vars);
  };

  const handlePreviewDataChange = (v: string, value: string) => {
    setPreviewData(prev => ({
      ...prev,
      [v]: value
    }));
  };

  const renderPreview = () => {
    let previewSubject = formData.subject;
    let previewBody = formData.body;

    // Replace variables with preview values
    formData.variables.forEach(v => {
      const regex = new RegExp(`{{${v}}}`, 'g');
      const value = previewData[v] || `[${v}]`;
      previewSubject = previewSubject.replace(regex, value);
      previewBody = previewBody.replace(regex, value);
    });

    return (
      <div className="space-y-6">
        <div>
          <h4 className="text-sm font-medium text-gray-500 mb-2">Subject Preview</h4>
          <div className="p-4 bg-white border rounded-lg">
            {previewSubject}
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-500 mb-2">Body Preview</h4>
          <div 
            className="p-4 bg-white border rounded-lg prose prose-sm max-w-none"
            dangerouslySetInnerHTML={{ __html: previewBody }}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={template ? 'Edit Email Template' : 'Create Email Template'}
      size="xl"
      footer={
        <div className="flex justify-between items-center">
          <Button
            variant="outline"
            icon={Eye}
            onClick={() => setShowPreview(!showPreview)}
          >
            {showPreview ? 'Hide Preview' : 'Show Preview'}
          </Button>
          <div className="flex gap-3">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} loading={saving}>
              {template ? 'Update Template' : 'Create Template'}
            </Button>
          </div>
        </div>
      }
    >
      <div className="flex gap-6">
        <div className={`flex-1 transition-all duration-300 ${showPreview ? 'w-1/2' : 'w-full'}`}>
          <form onSubmit={handleSubmit} className="space-y-6">
            {errors.length > 0 && (
              <div className="p-4 bg-red-50 rounded-lg">
                <ul className="list-disc list-inside text-sm text-red-600">
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            <FormField label="Template Name" required icon={Mail}>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => handleChange('name', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="Enter template name"
              />
            </FormField>

            <FormField label="Subject" required>
              <input
                type="text"
                value={formData.subject}
                onChange={(e) => handleChange('subject', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="Enter email subject"
              />
            </FormField>

            <FormField label="Body" required icon={Code}>
              <textarea
                value={formData.body}
                onChange={(e) => handleChange('body', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent font-mono text-sm"
                rows={12}
                placeholder="Enter email body (HTML supported)"
              />
              <p className="mt-1 text-sm text-gray-500">
                HTML is supported. Use double curly braces to insert variables (e.g., {'{{name}}'})
              </p>
            </FormField>

            <FormField label="Variables">
              <input
                type="text"
                value={formData.variables.join(', ')}
                onChange={(e) => handleVariablesChange(e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="Enter variables separated by commas"
              />
              <p className="mt-1 text-sm text-gray-500">
                Example: firstName, lastName, email
              </p>
            </FormField>

            <FormField label="Category" required>
              <select
                value={formData.category}
                onChange={(e) => handleChange('category', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
              >
                <option value="">Select Category</option>
                <option value="forms">Forms</option>
                <option value="auto-response">Auto Response</option>
                <option value="notifications">Notifications</option>
              </select>
            </FormField>

            <FormField label="Status">
              <select
                value={formData.status}
                onChange={(e) => handleChange('status', e.target.value as 'draft' | 'published')}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
              </select>
            </FormField>

            {showPreview && formData.variables.length > 0 && (
              <div className="border-t border-gray-200 pt-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Preview Data</h3>
                <div className="grid grid-cols-2 gap-4">
                  {formData.variables.map(v => (
                    <FormField key={v} label={v}>
                      <input
                        type="text"
                        value={previewData[v] || ''}
                        onChange={(e) => handlePreviewDataChange(v, e.target.value)}
                        className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                        placeholder={`Value for ${v}`}
                      />
                    </FormField>
                  ))}
                </div>
              </div>
            )}
          </form>
        </div>

        {showPreview && (
          <div className="w-1/2 border-l border-gray-200 pl-6">
            {renderPreview()}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default EmailTemplateEditor;