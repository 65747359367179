import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronDown, Search, Wind, ShoppingCart, Building2, Code, Cloud, Database, Shield, Brain, Globe, LineChart, Home, Info, Mail, FileText } from 'lucide-react';
import monogram from '../assets/monogram.svg';
import logo from '../assets/logo.svg';
import Fuse from 'fuse.js';
import searchData from '../data/searchData';

const fuse = new Fuse(searchData, {
  keys: ['title', 'description', 'tags'],
  threshold: 0.3,
  includeScore: true
});

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuState, setMenuState] = useState<'entering' | 'entered' | 'exiting'>('entering');
  const [expandedItem, setExpandedItem] = useState<string | null>('/services');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Fuse.FuseResult<any>[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const expandTimeoutRef = useRef<NodeJS.Timeout>();
  const lastScrollY = useRef(0);
  
  const isActive = (path: string) => location.pathname === path;

  const toggleSubmenu = (path: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedItem(prev => prev === path ? null : path);
  };

  const handleMobileMenuToggle = () => {
    if (!isMobileMenuOpen) {
      setIsMobileMenuOpen(true);
      setMenuState('entering');
      setExpandedItem('/services');
      requestAnimationFrame(() => {
        setMenuState('entered');
      });
    } else {
      setMenuState('exiting');
      setTimeout(() => {
        setIsMobileMenuOpen(false);
        setMenuState('entering');
      }, 500);
    }
  };

  const navLinks = [
    { path: '/', label: 'Home', icon: Home },
    { path: '/about', label: 'About', icon: Info },
    { 
      path: '/services', 
      label: 'Services',
      icon: Database,
      subItems: [
        { label: 'Web Development', path: '/services/web-development', icon: Globe },
        { label: 'App Development', path: '/services/app-development', icon: Code },
        { label: 'Digital Transformation', path: '/services/digital-transformation', icon: Cloud },
        { label: 'IT Consultancy', path: '/services/it-consultancy', icon: Shield },
        { label: 'Infrastructure', path: '/services/infrastructure', icon: Database },
        { label: 'AI Solutions', path: '/services/ai-solutions', icon: Brain },
        { label: 'Analytics', path: '/services/analytics', icon: LineChart }
      ]
    },
    { 
      path: '/industries', 
      label: 'Industries',
      icon: Building2,
      subItems: [
        { label: 'Renewable Energy', path: '/industries/renewables', icon: Wind }
      ]
    },
    { 
      path: '/case-studies', 
      label: 'Case Studies',
      icon: FileText,
      subItems: [
        { label: 'Renewable Energy', path: '/renewable-case-studies', icon: Wind }
      ]
    },
    { path: '/contact', label: 'Contact', icon: Mail }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY < lastScrollY.current) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }

      setIsScrolled(currentScrollY > 50);
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Handle search wrapper clicks
      if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target as Node)) {
        setSearchQuery('');
      }

      // Only handle menu clicks if the menu is open
      if (isMobileMenuOpen) {
        // Check if click is outside both menu and menu button
        if (
          mobileMenuRef.current && 
          !mobileMenuRef.current.contains(event.target as Node) &&
          menuButtonRef.current &&
          !menuButtonRef.current.contains(event.target as Node)
        ) {
          handleMobileMenuToggle();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (searchQuery) {
      const results = fuse.search(searchQuery);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isMobileMenuOpen]);

  const handleSearchSelect = (result: any) => {
    setSearchQuery('');
    navigate(result.path);
  };

  return (
    <nav className="navbar">
      <div className="navbar-inner">
        <div className="navbar-container">
          <div className="navbar-content">
            {/* Logo */}
            <Link to="/" className="flex items-center">
              <div className={`logo-container ${
                isScrolled && !isExpanded 
                  ? 'w-[48px] md:w-[56px]' 
                  : 'w-[140px] md:w-[180px]'
              }`}>
                <img
                  src={logo}
                  alt="Senses Innovations"
                  className={`logo-full ${
                    isScrolled && !isExpanded ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
                  }`}
                />
                <img
                  src={monogram}
                  alt="SI"
                  className={`logo-monogram w-[48px] md:w-[56px] ${
                    isScrolled && !isExpanded ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
                  }`}
                />
              </div>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-1">
              {navLinks.map((item) => (
                <div key={item.path} className="relative group">
                  <Link
                    to={item.path}
                    className={`flex items-center px-3 py-2 rounded-lg transition-all duration-200 ${
                      isActive(item.path) || location.pathname.startsWith(item.path + '/')
                        ? 'text-primary font-medium bg-gray-100/50'
                        : 'text-gray-600 hover:text-primary hover:bg-gray-100/50'
                    }`}
                  >
                    <span>{item.label}</span>
                    {item.subItems && (
                      <ChevronDown className="ml-1 w-4 h-4 transition-transform duration-200 group-hover:rotate-180" />
                    )}
                  </Link>
                  {item.subItems && (
                    <div className="dropdown-menu">
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.path}
                          to={subItem.path}
                          className={`dropdown-item ${
                            location.pathname === subItem.path ? 'active' : ''
                          }`}
                        >
                          {subItem.icon && <subItem.icon className="w-4 h-4" />}
                          <span>{subItem.label}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {/* Desktop Search */}
              <div ref={searchWrapperRef} className="relative ml-2">
                <div className="search-wrapper">
                  <input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Search..."
                    className="search-input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button className="search-icon">
                    <Search className="w-5 h-5 text-primary" />
                  </button>
                </div>

                {/* Search Results */}
                {searchResults.length > 0 && (
                  <div className="absolute top-full right-0 mt-1 w-[320px] bg-white rounded-xl shadow-lg border border-gray-200/80 max-h-[400px] overflow-y-auto z-[201]">
                    {searchResults.map((result, index) => (
                      <button
                        key={index}
                        className="w-full p-3 text-left hover:bg-gray-50 flex items-start gap-3 border-b border-gray-100 last:border-none"
                        onClick={() => handleSearchSelect(result.item)}
                      >
                        {result.item.image && (
                          <div 
                            className="w-12 h-12 rounded-lg bg-cover bg-center flex-shrink-0"
                            style={{ backgroundImage: `url(${result.item.image})` }}
                          />
                        )}
                        <div>
                          <div className="text-sm font-medium text-primary">
                            {result.item.title}
                          </div>
                          <p className="text-xs text-gray-500 line-clamp-1">
                            {result.item.description}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Mobile Menu Button */}
            <button 
              ref={menuButtonRef}
              className="md:hidden p-2 -mr-2 rounded-full text-primary hover:bg-gray-100/50 transition-colors"
              onClick={handleMobileMenuToggle}
              aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
            >
              <div className="relative w-6 h-6">
                <span className={`absolute left-0 w-6 h-0.5 bg-current transform transition-all duration-300 ${
                  isMobileMenuOpen 
                    ? 'top-3 rotate-45' 
                    : 'top-1'
                }`} />
                <span className={`absolute left-0 top-3 w-6 h-0.5 bg-current transition-opacity duration-300 ${
                  isMobileMenuOpen ? 'opacity-0' : 'opacity-100'
                }`} />
                <span className={`absolute left-0 w-6 h-0.5 bg-current transform transition-all duration-300 ${
                  isMobileMenuOpen 
                    ? 'top-3 -rotate-45' 
                    : 'top-5'
                }`} />
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div 
        ref={mobileMenuRef}
        className={`fixed inset-0 z-[200] md:hidden ${
          isMobileMenuOpen ? 'visible' : 'invisible'
        }`}
        aria-hidden={!isMobileMenuOpen}
      >
        {/* Backdrop */}
        <div 
          className="mobile-menu-backdrop"
          data-active={isMobileMenuOpen}
          onClick={handleMobileMenuToggle}
        />

        {/* Menu Panel */}
        <div 
          className="mobile-menu-panel"
          data-state={menuState}
          data-position="below"
        >
          {/* Navigation Links */}
          <div className="overflow-y-auto h-full p-4 space-y-1">
            {navLinks.map((item, index) => (
              <div
                key={item.path}
                className={`transform transition-all duration-300 delay-[${index * 50}ms] ${
                  isMobileMenuOpen ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'
                }`}
              >
                <div className="flex items-center">
                  <Link
                    to={item.path}
                    className={`flex-1 mobile-link ${isActive(item.path) ? 'active' : ''}`}
                    onClick={() => handleMobileMenuToggle()}
                  >
                    {item.icon && <item.icon className="w-5 h-5" />}
                    <span>{item.label}</span>
                  </Link>
                  {item.subItems && (
                    <button
                      onClick={(e) => toggleSubmenu(item.path, e)}
                      className="p-2 text-gray-600 hover:text-primary"
                    >
                      <ChevronDown className={`w-5 h-5 transition-transform duration-300 ease-in-out ${
                        expandedItem === item.path ? 'rotate-180' : ''
                      }`} />
                    </button>
                  )}
                </div>

                {item.subItems && (
                  <div className={`mobile-submenu ${
                    expandedItem === item.path ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                  }`}>
                    {item.subItems.map((subItem) => (
                      <Link
                        key={subItem.path}
                        to={subItem.path}
                        className={`mobile-link ${location.pathname === subItem.path ? 'active' : ''}`}
                        onClick={handleMobileMenuToggle}
                      >
                        {subItem.icon && <subItem.icon className="w-4 h-4" />}
                        <span>{subItem.label}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;