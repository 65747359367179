import React, { useState, useEffect } from 'react';
import { Mail, Settings, RefreshCw, Plus, Edit, Trash, CheckCircle, XCircle } from 'lucide-react';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import SearchInput from '../../components/admin/SearchInput';
import FilterDropdown from '../../components/admin/FilterDropdown';
import DataTable from '../../components/admin/DataTable';
import { supabase } from '../../lib/supabase/client';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import EmailTemplateEditor from '../../components/admin/EmailTemplateEditor';
import EmailSettingsEditor from '../../components/admin/EmailSettingsEditor';

function EmailPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [templates, setTemplates] = useState<any[]>([]);
  const [settings, setSettings] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isTemplateEditorOpen, setIsTemplateEditorOpen] = useState(false);
  const [isSettingsEditorOpen, setIsSettingsEditorOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    draft: 0
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    // Calculate stats whenever templates change
    const newStats = templates.reduce((acc, template) => ({
      total: acc.total + 1,
      active: acc.active + (template.status === 'published' ? 1 : 0),
      draft: acc.draft + (template.status === 'draft' ? 1 : 0)
    }), { total: 0, active: 0, draft: 0 });
    setStats(newStats);
  }, [templates]);

  const loadData = async () => {
    try {
      setLoading(true);
      
      // Load templates
      const { data: templatesData, error: templatesError } = await supabase
        .from('email_templates')
        .select('*')
        .order('created_at', { ascending: false });

      if (templatesError) throw templatesError;
      setTemplates(templatesData || []);

      // Load settings
      const { data: settingsData, error: settingsError } = await supabase
        .from('email_settings')
        .select('*')
        .single();

      if (settingsError && settingsError.code !== 'PGRST116') throw settingsError;
      setSettings(settingsData);

    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Failed to load email data');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTemplate = async (template: any) => {
    if (!confirm('Are you sure you want to delete this template?')) return;

    try {
      const { error } = await supabase
        .from('email_templates')
        .delete()
        .eq('id', template.id);

      if (error) throw error;

      toast.success('Template deleted successfully');
      loadData();
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('Failed to delete template');
    }
  };

  return (
    <div>
      <ContentHeader
        title="Email Management"
        description="Manage email templates and settings"
        icon={Mail}
        actions={
          <>
            <Button
              variant="outline"
              icon={Settings}
              onClick={() => setIsSettingsEditorOpen(true)}
            >
              Email Settings
            </Button>
            <Button
              icon={Plus}
              onClick={() => {
                setSelectedTemplate(null);
                setIsTemplateEditorOpen(true);
              }}
            >
              New Template
            </Button>
          </>
        }
      />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
        <Card
          title="Total Templates"
          icon={Mail}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-primary">{stats.total}</div>
            <p className="text-sm text-gray-600 mt-1">Email templates</p>
          </div>
        </Card>

        <Card
          title="Active Templates"
          icon={CheckCircle}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-green-600">{stats.active}</div>
            <p className="text-sm text-gray-600 mt-1">Published templates</p>
          </div>
        </Card>

        <Card
          title="Draft Templates"
          icon={XCircle}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-gray-600">{stats.draft}</div>
            <p className="text-sm text-gray-600 mt-1">Templates in draft</p>
          </div>
        </Card>
      </div>

      <Card>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search templates..."
            className="md:w-64"
          />
          <FilterDropdown
            label="Category"
            options={[
              { value: 'all', label: 'All Categories' },
              { value: 'forms', label: 'Forms' },
              { value: 'auto-response', label: 'Auto Response' },
              { value: 'notifications', label: 'Notifications' }
            ]}
            value={categoryFilter}
            onChange={setCategoryFilter}
          />
        </div>

        <DataTable
          columns={[
            { 
              header: 'Template',
              accessor: (row) => (
                <div>
                  <div className="font-medium text-gray-900">{row.name}</div>
                  <div className="text-sm text-gray-500 mt-1">{row.subject}</div>
                </div>
              )
            },
            {
              header: 'Category',
              accessor: (row) => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  {row.category}
                </span>
              )
            },
            {
              header: 'Status',
              accessor: (row) => (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  row.status === 'published'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {row.status === 'published' ? 'Active' : 'Draft'}
                </span>
              )
            },
            {
              header: 'Last Modified',
              accessor: (row) => format(new Date(row.updated_at), 'MMM d, yyyy'),
              className: 'text-right'
            },
            {
              header: 'Actions',
              accessor: (row) => (
                <div className="flex items-center justify-end gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Edit}
                    onClick={() => {
                      setSelectedTemplate(row);
                      setIsTemplateEditorOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Trash}
                    onClick={() => handleDeleteTemplate(row)}
                  >
                    Delete
                  </Button>
                </div>
              )
            }
          ]}
          data={templates.filter(template => {
            const matchesSearch = 
              template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              template.subject.toLowerCase().includes(searchQuery.toLowerCase());
            
            const matchesCategory = categoryFilter === 'all' || template.category === categoryFilter;
            
            return matchesSearch && matchesCategory;
          })}
          loading={loading}
        />
      </Card>

      <EmailTemplateEditor
        isOpen={isTemplateEditorOpen}
        onClose={() => {
          setIsTemplateEditorOpen(false);
          setSelectedTemplate(null);
          loadData();
        }}
        template={selectedTemplate}
      />

      <EmailSettingsEditor
        isOpen={isSettingsEditorOpen}
        onClose={() => {
          setIsSettingsEditorOpen(false);
          loadData();
        }}
        settings={settings}
      />
    </div>
  );
}

export default EmailPage;