import React, { useState, useEffect } from 'react';
import { Users, Plus, Shield, RefreshCw } from 'lucide-react';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import SearchInput from '../../components/admin/SearchInput';
import FilterDropdown from '../../components/admin/FilterDropdown';
import DataTable from '../../components/admin/DataTable';
import { supabase } from '../../lib/supabase/client';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import UserEditor from '../../components/admin/UserEditor';
import RoleManager from '../../components/admin/RoleManager';

function UsersPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [users, setUsers] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isRoleManagerOpen, setIsRoleManagerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    inactive: 0
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    // Calculate stats whenever users change
    const newStats = users.reduce((acc, user) => ({
      total: acc.total + 1,
      active: acc.active + (user.status === 'active' ? 1 : 0),
      inactive: acc.inactive + (user.status !== 'active' ? 1 : 0)
    }), { total: 0, active: 0, inactive: 0 });
    setStats(newStats);
  }, [users]);

  const loadData = async () => {
    try {
      setLoading(true);
      
      // Load users
      const { data: usersData, error: usersError } = await supabase
        .from('profiles')
        .select(`
          *,
          user_roles (
            name,
            description,
            permissions
          )
        `)
        .order('created_at', { ascending: false });

      if (usersError) throw usersError;
      setUsers(usersData || []);

      // Load roles
      const { data: rolesData, error: rolesError } = await supabase
        .from('user_roles')
        .select('*')
        .order('name');

      if (rolesError) throw rolesError;
      setRoles(rolesData || []);

    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Failed to load users data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ContentHeader
        title="User Management"
        description="Manage users and their permissions"
        icon={Users}
        actions={
          <>
            <Button
              variant="outline"
              icon={Shield}
              onClick={() => setIsRoleManagerOpen(true)}
            >
              Manage Roles
            </Button>
            <Button
              icon={Plus}
              onClick={() => {
                setSelectedUser(null);
                setIsEditorOpen(true);
              }}
            >
              Add User
            </Button>
          </>
        }
      />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
        <Card
          title="Total Users"
          icon={Users}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-primary">{stats.total}</div>
            <p className="text-sm text-gray-600 mt-1">Registered users</p>
          </div>
        </Card>

        <Card
          title="Active Users"
          icon={Shield}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-green-600">{stats.active}</div>
            <p className="text-sm text-gray-600 mt-1">Active accounts</p>
          </div>
        </Card>

        <Card
          title="Inactive Users"
          icon={RefreshCw}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-gray-600">{stats.inactive}</div>
            <p className="text-sm text-gray-600 mt-1">Inactive or suspended</p>
          </div>
        </Card>
      </div>

      <Card>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search users..."
            className="md:w-64"
          />
          <FilterDropdown
            label="Role"
            options={[
              { value: 'all', label: 'All Roles' },
              ...roles.map(role => ({
                value: role.name,
                label: role.name.charAt(0).toUpperCase() + role.name.slice(1)
              }))
            ]}
            value={roleFilter}
            onChange={setRoleFilter}
          />
        </div>

        <DataTable
          columns={[
            { 
              header: 'User',
              accessor: (row) => (
                <div>
                  <div className="font-medium text-gray-900">
                    {row.first_name} {row.last_name}
                  </div>
                  <div className="text-sm text-gray-500">{row.email}</div>
                </div>
              )
            },
            {
              header: 'Role',
              accessor: (row) => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary/10 text-primary">
                  {row.user_roles?.name || 'No Role'}
                </span>
              )
            },
            {
              header: 'Status',
              accessor: (row) => (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  row.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
              )
            },
            {
              header: 'Last Login',
              accessor: (row) => row.last_login ? format(new Date(row.last_login), 'MMM d, yyyy') : 'Never',
              className: 'text-right'
            },
            {
              header: 'Actions',
              accessor: (row) => (
                <div className="flex items-center justify-end gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setSelectedUser(row);
                      setIsEditorOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )
            }
          ]}
          data={users.filter(user => {
            const matchesSearch = 
              `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
              user.email.toLowerCase().includes(searchQuery.toLowerCase());
            
            const matchesRole = roleFilter === 'all' || user.user_roles?.name === roleFilter;
            
            return matchesSearch && matchesRole;
          })}
          loading={loading}
        />
      </Card>

      <UserEditor
        isOpen={isEditorOpen}
        onClose={() => {
          setIsEditorOpen(false);
          setSelectedUser(null);
          loadData();
        }}
        user={selectedUser}
        roles={roles}
      />

      <RoleManager
        isOpen={isRoleManagerOpen}
        onClose={() => {
          setIsRoleManagerOpen(false);
          loadData();
        }}
      />
    </div>
  );
}

export default UsersPage;