import React, { useState } from 'react';
import { Mail, Send } from 'lucide-react';
import emailjs from '@emailjs/browser';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import toast from 'react-hot-toast';

function EmailTest() {
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState({
    from_name: 'Test User',
    from_email: 'test@example.com',
    company: 'Test Company',
    industry: 'Test Industry',
    service: 'Test Service',
    message: 'This is a test message from the admin panel.',
    to_name: 'Senses Innovations Team',
    to_email: 'a.elfouly@sensesinnovations.com'
  });

  const handleTest = async () => {
    try {
      setLoading(true);
      
      // Prepare email data
      const emailData = {
        ...testData,
        test_mode: 'true',
        to_name: testData.to_name,
        to_email: testData.to_email,
        reply_to: testData.from_email,
        origin: window.location.origin
      };
      
      // Send test email
      const response = await emailjs.send(
        'service_r3wgpdq',
        'template_contact',
        emailData
      );

      if (!response) {
        throw new Error('No response received from email service');
      }
      
      if (response.status !== 200) {
        throw new Error(`Failed to send test email: ${response.text || 'Unknown error'}`);
      }
      
      toast.success('Test email sent successfully');
    } catch (error) {
      console.error('Error sending test email:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to send test email';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setTestData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <div>
      <ContentHeader
        title="Email Testing"
        description="Test email functionality"
        icon={Mail}
      />

      <Card>
        <div className="space-y-6">
          <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
            <h3 className="text-lg font-medium text-yellow-800 dark:text-yellow-400 mb-4">
              Test Information
            </h3>
            <ul className="list-disc list-inside text-sm text-yellow-700 dark:text-yellow-300 space-y-1">
              <li>Service ID: service_r3wgpdq</li>
              <li>Template ID: template_contact</li>
              <li>Default Recipient: a.elfouly@sensesinnovations.com</li>
            </ul>
            <p className="mt-4 text-sm text-yellow-600 dark:text-yellow-400">
              Note: This will send a test email using the contact form template. No actual emails will be sent in test mode.
            </p>
          </div>

          {/* Test Form */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              Test Email Data
            </h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  From Name
                </label>
                <input
                  type="text"
                  value={testData.from_name}
                  onChange={(e) => handleInputChange('from_name', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  From Email
                </label>
                <input
                  type="email"
                  value={testData.from_email}
                  onChange={(e) => handleInputChange('from_email', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Company
                </label>
                <input
                  type="text"
                  value={testData.company}
                  onChange={(e) => handleInputChange('company', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Industry
                </label>
                <input
                  type="text"
                  value={testData.industry}
                  onChange={(e) => handleInputChange('industry', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Service
                </label>
                <input
                  type="text"
                  value={testData.service}
                  onChange={(e) => handleInputChange('service', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  To Name
                </label>
                <input
                  type="text"
                  value={testData.to_name}
                  onChange={(e) => handleInputChange('to_name', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  To Email
                </label>
                <input
                  type="email"
                  value={testData.to_email}
                  onChange={(e) => handleInputChange('to_email', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
                />
                <p className="mt-1 text-xs text-gray-500">
                  Default recipient: a.elfouly@sensesinnovations.com
                </p>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Message
              </label>
              <textarea
                value={testData.message}
                onChange={(e) => handleInputChange('message', e.target.value)}
                rows={4}
                className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Send Test Email
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                This will send a test email using the contact form template
              </p>
            </div>
            <Button
              icon={Send}
              onClick={handleTest}
              loading={loading}
            >
              Send Test
            </Button>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
              Test Email Content Preview
            </h4>
            <pre className="text-xs text-gray-600 dark:text-gray-300 whitespace-pre-wrap">
              {JSON.stringify(testData, null, 2)}
            </pre>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default EmailTest;