import { supabase } from './supabase/client';
import { UserProfile } from '../types/admin';

export async function signIn(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });

  if (error) throw error;
  return data;
}

export async function signOut() {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
}

export async function getCurrentUser() {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return null;

  const { data: profile } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', user.id)
    .single();

  return profile as UserProfile | null;
}

export async function updateProfile(profile: Partial<UserProfile>) {
  const { data, error } = await supabase
    .from('profiles')
    .update(profile)
    .eq('id', profile.id)
    .select()
    .single();

  if (error) throw error;
  return data as UserProfile;
}

export async function enable2FA(userId: string) {
  const { data, error } = await supabase
    .from('profiles')
    .update({ isTwoFactorEnabled: true })
    .eq('id', userId)
    .select()
    .single();

  if (error) throw error;
  return data as UserProfile;
}

export async function disable2FA(userId: string) {
  const { data, error } = await supabase
    .from('profiles')
    .update({ isTwoFactorEnabled: false })
    .eq('id', userId)
    .select()
    .single();

  if (error) throw error;
  return data as UserProfile;
}

export async function getUserSessions(userId: string) {
  const { data, error } = await supabase
    .from('sessions')
    .select('*')
    .eq('user_id', userId)
    .eq('is_active', true)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function terminateSession(sessionId: string) {
  const { error } = await supabase
    .from('sessions')
    .update({ is_active: false })
    .eq('id', sessionId);

  if (error) throw error;
}

export async function terminateAllSessions(userId: string, exceptSessionId?: string) {
  const query = supabase
    .from('sessions')
    .update({ is_active: false })
    .eq('user_id', userId)
    .eq('is_active', true);

  if (exceptSessionId) {
    query.neq('id', exceptSessionId);
  }

  const { error } = await query;
  if (error) throw error;
}