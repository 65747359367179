import React, { useState, useEffect } from 'react';
import SEO from '../components/SEO';
import CaseStudyCard from '../components/CaseStudyCard';

function RenewableCaseStudies() {
  const [counts, setCounts] = useState({
    capacity: 0,
    uptime: 0,
    projects: 0,
    monitoring: 0
  });

  // Calculate total capacity: 1.1GW + 262.5MW + 500MW + 30MW + 500MW = 2392.5MW ≈ 2.39GW
  const targetCounts = {
    capacity: 2.39,
    uptime: 99.9,
    projects: 5,
    monitoring: 24
  };

  useEffect(() => {
    const duration = 2000; // 2 seconds for the animation
    const steps = 60; // Number of steps in the animation
    const interval = duration / steps;

    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / duration, 1);

      setCounts({
        capacity: Number((targetCounts.capacity * progress).toFixed(2)),
        uptime: Number((targetCounts.uptime * progress).toFixed(1)),
        projects: Math.floor(targetCounts.projects * progress),
        monitoring: Math.floor(targetCounts.monitoring * progress)
      });

      if (progress === 1) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer);
  }, []);

  const caseStudies = [
    {
      title: "Ras Ghareb Wind Energy",
      subtitle: "262.5MW Wind Farm",
      description: "Complete IT infrastructure deployment with 99.9% uptime achievement",
      image: "https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?auto=format&fit=crop&q=80",
      results: [
        "Successful deployment in under 3 months",
        "99.9% system uptime",
        "Full regulatory compliance",
        "Integrated monitoring systems"
      ],
      certificateUrl: "/certificates/ras-ghareb-performance.pdf"
    },
    {
      title: "Red Sea Wind Energy",
      subtitle: "500MW Wind Farm",
      description: "End-to-end IT solutions with complete regulatory compliance",
      image: "https://images.unsplash.com/photo-1466611653911-95081537e5b7?auto=format&fit=crop&q=80",
      results: [
        "Rapid deployment timeline",
        "Enhanced security measures",
        "Real-time monitoring",
        "Automated reporting systems"
      ],
      certificateUrl: "/certificates/red-sea-performance.pdf"
    },
    {
      title: "ACWA Power",
      subtitle: "1.1GW Wind Farm",
      description: "Large-scale IT infrastructure enablement",
      image: "https://images.unsplash.com/photo-1548337138-e87d889cc369?auto=format&fit=crop&q=80",
      results: [
        "Scalable infrastructure",
        "Integrated security systems",
        "Performance optimization",
        "24/7 monitoring capability"
      ],
      certificateUrl: "/certificates/acwa-power-performance.pdf"
    },
    {
      title: "ENGIE Solar KSA (Nadec)",
      subtitle: "30MW Solar Farm",
      description: "Advanced infrastructure connectivity solution enabling automated maintenance",
      image: "https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?auto=format&fit=crop&q=80",
      results: [
        "High-reliability network infrastructure",
        "Redundant connectivity systems",
        "Seamless automation integration",
        "99.99% connectivity uptime"
      ],
      certificateUrl: "/certificates/engie-solar-performance.pdf"
    },
    {
      title: "Amunet Wind Energy",
      subtitle: "500MW Wind Farm",
      description: "Comprehensive IT and security infrastructure implementation",
      image: "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?auto=format&fit=crop&q=80",
      results: [
        "Advanced security systems",
        "Remote monitoring capabilities",
        "Integrated SCADA systems",
        "Rapid deployment success"
      ],
      certificateUrl: "/certificates/amunet-performance.pdf"
    }
  ];

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Renewable Energy Case Studies"
        description="Discover our success stories in renewable energy projects. From wind farms to solar installations, see how we deliver reliable IT infrastructure solutions."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'CollectionPage',
          name: 'Renewable Energy Case Studies',
          description: 'Success stories in renewable energy projects',
          mainEntity: {
            '@type': 'ItemList',
            itemListElement: caseStudies.map((study, index) => ({
              '@type': 'Article',
              position: index + 1,
              name: study.title,
              description: study.description,
              image: study.image,
              author: {
                '@type': 'Organization',
                name: 'Senses Innovations'
              },
              publisher: {
                '@type': 'Organization',
                name: 'Senses Innovations',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://sensesinnovations.com/logo.svg'
                }
              }
            }))
          },
          breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': 'https://sensesinnovations.com',
                  name: 'Home'
                }
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': 'https://sensesinnovations.com/case-studies',
                  name: 'Case Studies'
                }
              },
              {
                '@type': 'ListItem',
                position: 3,
                item: {
                  '@id': 'https://sensesinnovations.com/renewable-case-studies',
                  name: 'Renewable Energy'
                }
              }
            ]
          },
          specialty: 'Renewable Energy Infrastructure',
          about: {
            '@type': 'Thing',
            name: 'Renewable Energy',
            description: 'IT infrastructure solutions for wind farms and solar installations'
          }
        }}
      />
      {/* Header Section */}
      <section className="bg-primary py-12 md:py-16">
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4 opacity-0 animate-[fadeUp_0.8s_ease-out_forwards]">
            Renewable Energy Success Stories
          </h1>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto text-center opacity-0 animate-[fadeUp_0.8s_ease-out_0.2s_forwards]">
            Discover how we've helped leading renewable energy projects achieve their technology goals
          </p>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-center text-primary mb-12">Our Impact in Numbers</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { value: counts.capacity.toFixed(2) + "GW", label: "Total Capacity" },
              { value: counts.uptime.toFixed(1) + "%", label: "Average Uptime" },
              { value: counts.projects + "+", label: "Projects Completed" },
              { value: counts.monitoring + "/7", label: "Monitoring" }
            ].map((stat, index) => (
              <div 
                key={index}
                className="text-center opacity-0 translate-y-4 animate-[fadeUp_0.5s_ease-out_forwards]"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <div className="text-3xl md:text-4xl font-bold text-primary mb-2">{stat.value}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies Grid */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {caseStudies.map((study, index) => (
              <div 
                key={index}
                className="opacity-0 translate-y-4 animate-[fadeUp_0.5s_ease-out_forwards]"
                style={{ animationDelay: `${(index + 4) * 100}ms` }}
              >
                <CaseStudyCard {...study} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Write Your Success Story?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help your renewable energy project achieve similar results.
          </p>
          <button className="button-secondary">
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
}

export default RenewableCaseStudies;