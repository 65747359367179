import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ZohoDesk from './components/ZohoDesk';
import ScrollRestoration from './components/ScrollRestoration';

// Public Pages
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Industries from './pages/Industries';
import CaseStudies from './pages/CaseStudies';
import RenewableCaseStudies from './pages/RenewableCaseStudies';
import Contact from './pages/Contact';
import AppDevelopment from './pages/AppDevelopment';
import DigitalTransformation from './pages/DigitalTransformation';
import ITConsultancy from './pages/ITConsultancy';
import Infrastructure from './pages/Infrastructure';
import AISolutions from './pages/AISolutions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Analytics from './pages/Analytics';
import Renewables from './pages/Renewables';
import WebDevelopment from './pages/WebDevelopment';

// Admin Pages
import AdminLayout from './components/admin/AdminLayout';
import DashboardPage from './pages/admin/DashboardPage';
import SEOPage from './pages/admin/SEOPage';
import CaseStudiesPage from './pages/admin/CaseStudiesPage';
import EmailPage from './pages/admin/EmailPage';
import EmailTest from './pages/admin/EmailTest';
import UsersPage from './pages/admin/UsersPage';
import ActivityPage from './pages/admin/ActivityPage';
import SecurityPage from './pages/admin/SecurityPage';

function PublicLayout() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      <ZohoDesk />
      <ScrollRestoration />
    </>
  );
}

function App() {
  return (
    <Routes>
      {/* Admin Routes */}
      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<DashboardPage />} />
        <Route path="seo" element={<SEOPage />} />
        <Route path="case-studies" element={<CaseStudiesPage />} />
        <Route path="email" element={<EmailPage />} />
        <Route path="email-test" element={<EmailTest />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="activity" element={<ActivityPage />} />
        <Route path="security" element={<SecurityPage />} />
      </Route>

      {/* Public Routes */}
      <Route element={<PublicLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="services/web-development" element={<WebDevelopment />} />
        <Route path="services/app-development" element={<AppDevelopment />} />
        <Route path="services/digital-transformation" element={<DigitalTransformation />} />
        <Route path="services/it-consultancy" element={<ITConsultancy />} />
        <Route path="services/infrastructure" element={<Infrastructure />} />
        <Route path="services/ai-solutions" element={<AISolutions />} />
        <Route path="services/analytics" element={<Analytics />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="industries" element={<Industries />} />
        <Route path="industries/renewables" element={<Renewables />} />
        <Route path="case-studies" element={<CaseStudies />} />
        <Route path="renewable-case-studies" element={<RenewableCaseStudies />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
  );
}

export default App;