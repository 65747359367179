import React from 'react';
import { Link } from 'react-router-dom';
import { Cloud, ArrowUpRight, BarChart3, Brain, Workflow, Database, Shield, Zap, Users, LineChart, Settings, RefreshCw } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function DigitalTransformation() {
  const capabilities = [
    {
      icon: Cloud,
      title: "Cloud Migration",
      description: "Seamless transition to cloud infrastructure"
    },
    {
      icon: Brain,
      title: "AI Integration",
      description: "Smart automation solutions"
    },
    {
      icon: BarChart3,
      title: "Data Analytics",
      description: "Actionable business insights"
    },
    {
      icon: Workflow,
      title: "Process Automation",
      description: "Streamlined workflows"
    }
  ];

  const solutions = [
    {
      icon: Database,
      title: "Legacy Modernization",
      description: "Transform legacy systems into modern, cloud-native applications",
      features: [
        "System Assessment",
        "Architecture Modernization",
        "Data Migration",
        "Cloud Integration"
      ]
    },
    {
      icon: Brain,
      title: "AI & Machine Learning",
      description: "Implement intelligent automation and predictive analytics",
      features: [
        "Predictive Analytics",
        "Process Automation",
        "Natural Language Processing",
        "Computer Vision"
      ]
    },
    {
      icon: LineChart,
      title: "Data Analytics",
      description: "Turn your data into actionable business insights",
      features: [
        "Business Intelligence",
        "Real-time Analytics",
        "Data Visualization",
        "Predictive Modeling"
      ]
    },
    {
      icon: Users,
      title: "Digital Workplace",
      description: "Create a modern, collaborative work environment",
      features: [
        "Collaboration Tools",
        "Document Management",
        "Process Automation",
        "Mobile Enablement"
      ]
    }
  ];

  const benefits = [
    {
      icon: Zap,
      title: "Increased Efficiency",
      description: "Streamline operations and reduce manual processes"
    },
    {
      icon: Shield,
      title: "Enhanced Security",
      description: "Implement robust security measures"
    },
    {
      icon: Settings,
      title: "Scalability",
      description: "Grow your infrastructure as needed"
    },
    {
      icon: RefreshCw,
      title: "Business Agility",
      description: "Adapt quickly to market changes"
    }
  ];

  const transformationProcess = [
    {
      phase: "01",
      title: "Assessment",
      description: "Current state analysis and planning",
      steps: [
        "Current State Analysis",
        "Technology Stack Review",
        "Process Mapping",
        "Gap Analysis"
      ]
    },
    {
      phase: "02",
      title: "Strategy",
      description: "Transformation roadmap development",
      steps: [
        "Transformation Roadmap",
        "Technology Selection",
        "Risk Assessment",
        "Timeline Planning"
      ]
    },
    {
      phase: "03",
      title: "Implementation",
      description: "Executing the transformation plan",
      steps: [
        "Phased Rollout",
        "System Integration",
        "Data Migration",
        "User Training"
      ]
    },
    {
      phase: "04",
      title: "Optimization",
      description: "Continuous improvement and refinement",
      steps: [
        "Performance Monitoring",
        "User Feedback",
        "Process Refinement",
        "Continuous Improvement"
      ]
    }
  ];

  const caseStudies = getCaseStudiesByService('digital-transformation');

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Digital Transformation"
        description="Comprehensive digital evolution for modern businesses. Transform your operations with cloud migration, AI automation, and process optimization."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'Digital Transformation Services',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'Digital Transformation',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Digital Transformation Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Legacy Modernization',
                  description: 'Transform legacy systems into modern, cloud-native applications'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'AI & Machine Learning',
                  description: 'Implement intelligent automation and predictive analytics'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Digital Workplace',
                  description: 'Create a modern, collaborative work environment'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="Digital Transformation"
        subtitle="Transform your business with cutting-edge digital solutions"
      />

      {/* Capabilities Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Capabilities</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive digital transformation solutions to modernize your business
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {capabilities.map((capability, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <capability.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-2">{capability.title}</h3>
                <p className="text-sm text-gray-600">{capability.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Solutions Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Solutions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive transformation solutions tailored to your business needs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <solution.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{solution.title}</h3>
                <p className="text-gray-600 mb-6">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Benefits Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Benefits</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Key advantages of digital transformation for your business
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <benefit.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-2">{benefit.title}</h3>
                <p className="text-sm text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Transformation Process */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Transformation Process</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to digital transformation
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {transformationProcess.map((phase, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{phase.phase}</div>
                <h3 className="text-xl font-semibold mb-2">{phase.title}</h3>
                <p className="text-gray-600 mb-4">{phase.description}</p>
                <ul className="space-y-2">
                  {phase.steps.map((step, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful digital transformations"
        background="gray"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Transform Your Business?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help digitize and modernize your operations.
          </p>
          <Link to="/contact" className="button-secondary">
            Start Your Transformation
          </Link>
        </div>
      </section>
    </div>
  );
}

export default DigitalTransformation;