import React, { useState, useEffect } from 'react';
import { Bell, Settings, LogOut, Sun, Moon, Search, Calendar, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { signOut } from '../../lib/auth';
import { useTheme } from '../../context/ThemeContext';
import monogram from '../../assets/monogram.svg';
import { format } from 'date-fns';

function AdminHeader() {
  const { theme, toggleTheme } = useTheme();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [unreadCount, setUnreadCount] = useState(3);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update every second instead of every minute
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      window.location.href = '/admin/login';
    } catch (error) {
      console.error('Sign out failed:', error);
    }
  };

  const sampleNotifications = [
    {
      id: 1,
      type: 'alert',
      message: 'New security alert detected',
      time: '5m ago',
      read: false
    },
    {
      id: 2,
      type: 'update',
      message: 'System update completed successfully',
      time: '1h ago',
      read: false
    },
    {
      id: 3,
      type: 'info',
      message: 'New case study published',
      time: '2h ago',
      read: false
    }
  ];

  const markAsRead = (id: number) => {
    setNotifications(prev => 
      prev.map(notif => 
        notif.id === id ? { ...notif, read: true } : notif
      )
    );
    setUnreadCount(prev => Math.max(0, prev - 1));
  };

  const clearAllNotifications = () => {
    setNotifications([]);
    setUnreadCount(0);
    setShowNotifications(false);
  };

  return (
    <header className="bg-white dark:bg-gray-800 border-b border-gray-200/80 dark:border-gray-700/80 fixed top-0 left-0 right-0 z-30 shadow-sm transition-colors duration-300">
      <div className="h-16 px-4 flex items-center justify-between">
        {/* Logo */}
        <Link 
          to="/admin" 
          className="flex items-center transition-transform duration-300 hover:scale-[1.02]"
        >
          <div className="p-1.5 bg-primary dark:bg-accent rounded-lg shadow-lg shadow-primary/25 dark:shadow-accent/25">
            <img src={monogram} alt="SI" className="h-6 w-6" />
          </div>
        </Link>

        {/* Center Section - Time & Date */}
        <div className="hidden md:flex items-center gap-2 text-gray-600 dark:text-gray-300">
          <Calendar className="w-4 h-4" />
          <span className="text-sm font-medium">
            {format(currentTime, 'EEEE, MMMM d, yyyy')}
          </span>
          <span className="mx-2">•</span>
          <span className="text-sm font-medium tabular-nums">
            {format(currentTime, 'h:mm:ss a')}
          </span>
        </div>

        {/* Right Section */}
        <div className="flex items-center gap-1">
          {/* Search */}
          <div className="relative">
            {showSearch ? (
              <div className="absolute right-0 top-1/2 -translate-y-1/2 w-64 flex items-center bg-white dark:bg-gray-700 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 overflow-hidden">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                  className="w-full px-4 py-2 bg-transparent text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none"
                />
                <button
                  onClick={() => setShowSearch(false)}
                  className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <button
                onClick={() => setShowSearch(true)}
                className="w-10 h-10 flex items-center justify-center text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-accent transition-all duration-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                title="Search"
              >
                <Search className="w-5 h-5" />
              </button>
            )}
          </div>

          {/* Theme Toggle */}
          <button
            onClick={toggleTheme}
            className="w-10 h-10 flex items-center justify-center text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-accent transition-all duration-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
            title={theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            {theme === 'dark' ? (
              <Sun className="w-5 h-5" />
            ) : (
              <Moon className="w-5 h-5" />
            )}
          </button>

          {/* Notifications */}
          <div className="relative">
            <button
              onClick={() => setShowNotifications(!showNotifications)}
              className="w-10 h-10 flex items-center justify-center text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-accent transition-all duration-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              title="Notifications"
            >
              <Bell className="w-5 h-5" />
              {unreadCount > 0 && (
                <span className="absolute top-2 right-2 w-4 h-4 bg-red-500 text-white text-xs flex items-center justify-center rounded-full">
                  {unreadCount}
                </span>
              )}
            </button>

            {showNotifications && (
              <div className="absolute right-0 mt-2 w-80 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
                <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
                  <h3 className="font-semibold text-gray-900 dark:text-white">Notifications</h3>
                  <button
                    onClick={clearAllNotifications}
                    className="text-sm text-gray-500 dark:text-gray-400 hover:text-primary dark:hover:text-accent"
                  >
                    Clear all
                  </button>
                </div>
                <div className="max-h-96 overflow-y-auto">
                  {sampleNotifications.map(notification => (
                    <div
                      key={notification.id}
                      className={`p-4 border-b border-gray-200 dark:border-gray-700 last:border-0 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200 ${
                        !notification.read ? 'bg-primary/5 dark:bg-accent/5' : ''
                      }`}
                    >
                      <div className="flex items-start gap-4">
                        <div className="flex-1">
                          <p className="text-sm text-gray-900 dark:text-white">
                            {notification.message}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                            {notification.time}
                          </p>
                        </div>
                        {!notification.read && (
                          <button
                            onClick={() => markAsRead(notification.id)}
                            className="text-xs text-primary dark:text-accent hover:underline"
                          >
                            Mark as read
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Settings */}
          <Link
            to="/admin/settings"
            className="w-10 h-10 flex items-center justify-center text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-accent transition-all duration-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
            title="Settings"
          >
            <Settings className="w-5 h-5" />
          </Link>

          {/* Profile Section */}
          <div className="flex items-center ml-1">
            <div className="h-10 border-l border-gray-200 dark:border-gray-700 mx-2"></div>
            <div className="flex items-center gap-3">
              <div className="hidden md:block text-right">
                <div className="text-sm font-medium text-gray-900 dark:text-white">Admin User</div>
                <div className="text-xs text-gray-500 dark:text-gray-400">admin@sensesinnovations.com</div>
              </div>
              <div className="relative group">
                <button className="w-10 h-10 rounded-full bg-primary/10 dark:bg-accent/10 flex items-center justify-center group-hover:ring-2 ring-primary dark:ring-accent transition-all duration-300">
                  <span className="text-sm font-medium text-primary dark:text-accent">AU</span>
                </button>
                <div className="absolute right-0 mt-2 w-48 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
                  <div className="p-2">
                    <button
                      onClick={handleSignOut}
                      className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-600 dark:text-gray-300 hover:text-primary dark:hover:text-accent hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-lg transition-colors duration-200"
                    >
                      <LogOut className="w-4 h-4" />
                      <span>Sign Out</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;