import React from 'react';
import { FileText, X } from 'lucide-react';

interface CaseStudyCardProps {
  title: string;
  subtitle: string;
  description: string;
  image: string;
  results: string[];
}

function CaseStudyCard({ title, subtitle, description, image, results }: CaseStudyCardProps) {
  return (
    <div className="card group h-full">
      <div className="card-gradient" />
      <div className="relative z-10 p-3 md:p-4 lg:p-6">
        <div 
          className="h-48 md:h-56 lg:h-64 rounded-xl bg-cover bg-center mb-4 md:mb-6 transition-all duration-500 relative overflow-hidden transform group-hover:scale-[1.03]"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500" />
        </div>
        <div className="space-y-4 transform transition-all duration-500 group-hover:translate-y-[-8px]">
          <div>
            <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-primary group-hover:text-accent transition-colors duration-300">{title}</h3>
            <p className="text-sm md:text-base lg:text-lg text-primary/80 font-medium">{subtitle}</p>
          </div>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 leading-relaxed">{description}</p>
          <ul className="space-y-2">
            {results.map((result, index) => (
              <li key={index} className="text-xs md:text-sm lg:text-base text-gray-600 flex items-start">
                <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                {result}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyCard;