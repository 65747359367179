import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Wind, ShoppingCart, Building2, Factory, Briefcase, Stethoscope, ChevronRight } from 'lucide-react';
import SEO from '../components/SEO';
import IndustryCard from '../components/IndustryCard';
import { ProcessSkeleton } from '../components/Skeleton';

function Industries() {
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState({
    grid: false,
    cta: false
  });

  const sectionsRef = useRef<{ [key: string]: HTMLDivElement | null }>({
    grid: null,
    cta: null
  });

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.getAttribute('data-section');
          if (sectionId) {
            setIsVisible(prev => ({
              ...prev,
              [sectionId]: entry.isIntersecting
            }));
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    // Observe all sections
    Object.keys(sectionsRef.current).forEach(sectionId => {
      const element = sectionsRef.current[sectionId];
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [loading]); // Re-run when loading state changes

  const industries = [
    {
      icon: Wind,
      title: "Renewable Energy",
      description: "Comprehensive IT solutions for wind and solar energy projects",
      features: [
        "Remote Site Infrastructure",
        "SCADA Systems",
        "Performance Monitoring",
        "Compliance Management"
      ],
      link: "/industries/renewables"
    },
    {
      icon: ShoppingCart,
      title: "Retail & E-commerce",
      description: "Digital solutions for modern retail operations",
      features: [
        "POS Systems",
        "Inventory Management",
        "E-commerce Platforms",
        "Customer Analytics"
      ]
    },
    {
      icon: Building2,
      title: "Enterprise",
      description: "End-to-end digital transformation solutions",
      features: [
        "Cloud Migration",
        "Process Automation",
        "Data Analytics",
        "Security Solutions"
      ]
    },
    {
      icon: Factory,
      title: "Manufacturing",
      description: "Smart manufacturing and Industry 4.0 solutions",
      features: [
        "IoT Integration",
        "Production Monitoring",
        "Quality Control",
        "Supply Chain Management"
      ]
    },
    {
      icon: Briefcase,
      title: "Financial Services",
      description: "Secure and compliant financial technology solutions",
      features: [
        "Security Systems",
        "Transaction Processing",
        "Risk Management",
        "Regulatory Compliance"
      ]
    },
    {
      icon: Stethoscope,
      title: "Healthcare",
      description: "Digital healthcare and telemedicine solutions",
      features: [
        "Patient Management",
        "Telemedicine Platforms",
        "Data Security",
        "Compliance Solutions"
      ]
    }
  ];

  if (loading) {
    return (
      <div className="pt-20 bg-white">
        <section className="bg-primary py-12 md:py-16">
          <div className="max-w-5xl mx-auto px-4">
            <div className="text-center">
              <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">Industries We Serve</h1>
              <p className="text-lg text-gray-300 max-w-3xl mx-auto">
                Delivering specialized technology solutions across key sectors
              </p>
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 text-primary">Our Industry Expertise</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <ProcessSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Industries"
        description="Specialized technology solutions across key sectors including renewable energy, retail, and enterprise. Discover our industry expertise and tailored solutions."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'CollectionPage',
          name: 'Industries - Senses Innovations',
          description: 'Industry solutions from Senses Innovations',
          mainEntity: {
            '@type': 'ItemList',
            itemListElement: industries.map((industry, index) => ({
              '@type': 'Service',
              position: index + 1,
              name: industry.title,
              description: industry.description,
              provider: {
                '@type': 'Organization',
                name: 'Senses Innovations'
              },
              serviceType: industry.title,
              areaServed: ['US', 'SA', 'AE', 'EG'],
              hasOfferCatalog: {
                '@type': 'OfferCatalog',
                name: industry.title,
                itemListElement: industry.features.map((feature, i) => ({
                  '@type': 'Offer',
                  itemOffered: {
                    '@type': 'Service',
                    name: feature
                  }
                }))
              }
            }))
          },
          breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': 'https://sensesinnovations.com',
                  name: 'Home'
                }
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': 'https://sensesinnovations.com/industries',
                  name: 'Industries'
                }
              }
            ]
          }
        }}
      />
      {/* Header Section */}
      <section className="bg-primary py-12 md:py-16">
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4 opacity-0 animate-[fadeUp_0.8s_ease-out_forwards]">
            Industries We Serve
          </h1>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto text-center opacity-0 animate-[fadeUp_0.8s_ease-out_0.2s_forwards]">
            Delivering specialized technology solutions across key sectors
          </p>
        </div>
      </section>

      {/* Industries Grid */}
      <section 
        ref={(el) => { sectionsRef.current.grid = el; }}
        data-section="grid"
        className="py-16"
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className={`text-2xl md:text-3xl font-bold text-center mb-6 text-primary transition-all duration-700 transform ${
              isVisible.grid ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Our Industry Expertise
            </h2>
            <p className={`text-gray-600 text-center max-w-2xl mx-auto mb-12 transition-all duration-700 transform ${
              isVisible.grid ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              We deliver tailored solutions that address the unique challenges and opportunities in each industry
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {industries.map((industry, index) => (
              <div 
                key={index}
                className={`opacity-0 translate-y-4 transition-all duration-700 transform ${
                  isVisible.grid ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                {industry.link ? (
                  <Link to={industry.link} className="block h-full">
                    <div className="card group h-full">
                      <div className="card-gradient" />
                      <div className="relative z-10">
                        <div className="flex items-start justify-between mb-6">
                          <industry.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                          <ChevronRight className="w-6 h-6 text-primary transition-all duration-300 group-hover:text-accent group-hover:translate-x-1" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-3">{industry.title}</h3>
                        <p className="text-gray-600 mb-6">{industry.description}</p>
                        <ul className="space-y-2">
                          {industry.features.map((feature, idx) => (
                            <li key={idx} className="text-gray-600">
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="card group h-full">
                    <div className="card-gradient" />
                    <div className="relative z-10">
                      <div className="flex items-start justify-between mb-6">
                        <industry.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                      </div>
                      <h3 className="text-2xl font-semibold mb-3">{industry.title}</h3>
                      <p className="text-gray-600 mb-6">{industry.description}</p>
                      <ul className="space-y-2">
                        {industry.features.map((feature, idx) => (
                          <li key={idx} className="text-gray-600">
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section 
        ref={(el) => { sectionsRef.current.cta = el; }}
        data-section="cta"
        className="py-16 bg-primary"
      >
        <div className={`max-w-4xl mx-auto text-center px-4 transition-all duration-700 transform ${
          isVisible.cta ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
        }`}>
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Transform Your Industry</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how our industry-specific solutions can help your business thrive.
          </p>
          <Link 
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-accent text-primary rounded-xl text-lg font-semibold transition-all duration-300 hover:shadow-lg hover:-translate-y-1 hover:shadow-accent/20"
          >
            Get in Touch
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Industries;