import React, { useState } from 'react';
import { Mail } from 'lucide-react';

interface EmailAddressProps {
  maskedEmail: string;
  actualEmail: string;
}

function EmailAddress({ maskedEmail, actualEmail }: EmailAddressProps) {
  const [isRevealed, setIsRevealed] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = async () => {
    setIsRevealed(true);
    try {
      await navigator.clipboard.writeText(actualEmail);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy email:', err);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <button 
        onClick={handleClick}
        className="group flex items-center gap-2 hover:text-accent transition-colors duration-300"
      >
        <Mail className="w-6 h-6 text-[#0B1329] group-hover:text-[#6EFFEA] transition-colors" />
        <span className="font-medium">
          {isRevealed ? actualEmail : maskedEmail}
        </span>
        {isCopied && (
          <span className="text-sm text-accent">
            Copied!
          </span>
        )}
      </button>
      {isRevealed && (
        <a 
          href={`mailto:${actualEmail}`}
          className="text-accent hover:text-[#0B1329] transition-colors duration-300 text-sm"
        >
          Send email
        </a>
      )}
    </div>
  );
}

export default EmailAddress;