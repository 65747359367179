import React from 'react';
import { Shield, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';

interface SEOScoreCardProps {
  score: number;
  issues: Array<{
    type: 'critical' | 'major';
    message: string;
    field: string;
    fix?: string;
  }>;
  warnings: Array<{
    type: 'moderate' | 'minor';
    message: string;
    field: string;
    suggestion?: string;
  }>;
}

function SEOScoreCard({ score, issues, warnings }: SEOScoreCardProps) {
  const getScoreColor = () => {
    if (score >= 90) return 'text-green-500 dark:text-green-400';
    if (score >= 70) return 'text-yellow-500 dark:text-yellow-400';
    return 'text-red-500 dark:text-red-400';
  };

  const getScoreBackground = () => {
    if (score >= 90) return 'bg-green-50 dark:bg-green-900/20';
    if (score >= 70) return 'bg-yellow-50 dark:bg-yellow-900/20';
    return 'bg-red-50 dark:bg-red-900/20';
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 overflow-hidden">
      {/* Score Section */}
      <div className={`p-6 ${getScoreBackground()}`}>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
              SEO Score
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Based on best practices and analysis
            </p>
          </div>
          <div className={`text-4xl font-bold ${getScoreColor()}`}>
            {score}
          </div>
        </div>
      </div>

      {/* Issues & Warnings */}
      <div className="p-6">
        {/* Critical & Major Issues */}
        {issues.length > 0 && (
          <div className="mb-6">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-4 flex items-center">
              <XCircle className="w-4 h-4 text-red-500 mr-2" />
              Issues to Fix
            </h4>
            <div className="space-y-3">
              {issues.map((issue, index) => (
                <div
                  key={index}
                  className="p-3 bg-red-50 dark:bg-red-900/20 rounded-lg"
                >
                  <div className="flex items-start">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-red-800 dark:text-red-400">
                        {issue.message}
                      </p>
                      {issue.fix && (
                        <p className="text-xs text-red-600 dark:text-red-300 mt-1">
                          Fix: {issue.fix}
                        </p>
                      )}
                    </div>
                    <span className="text-xs font-medium text-red-600 dark:text-red-300 px-2 py-1 bg-red-100 dark:bg-red-900/40 rounded">
                      {issue.type}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Warnings */}
        {warnings.length > 0 && (
          <div>
            <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-4 flex items-center">
              <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2" />
              Warnings & Suggestions
            </h4>
            <div className="space-y-3">
              {warnings.map((warning, index) => (
                <div
                  key={index}
                  className="p-3 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg"
                >
                  <div className="flex items-start">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-yellow-800 dark:text-yellow-400">
                        {warning.message}
                      </p>
                      {warning.suggestion && (
                        <p className="text-xs text-yellow-600 dark:text-yellow-300 mt-1">
                          Suggestion: {warning.suggestion}
                        </p>
                      )}
                    </div>
                    <span className="text-xs font-medium text-yellow-600 dark:text-yellow-300 px-2 py-1 bg-yellow-100 dark:bg-yellow-900/40 rounded">
                      {warning.type}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* All Good Message */}
        {issues.length === 0 && warnings.length === 0 && (
          <div className="flex items-center justify-center py-8">
            <div className="text-center">
              <CheckCircle className="w-12 h-12 text-green-500 mx-auto mb-3" />
              <p className="text-lg font-medium text-gray-900 dark:text-white">
                Perfect Score!
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                All SEO best practices are being followed
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SEOScoreCard;