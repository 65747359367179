import { supabase } from './supabase/client';
import type { EmailSettings, EmailTemplate } from '../types/admin';

// Get email settings
export async function getEmailSettings(): Promise<EmailSettings> {
  const { data, error } = await supabase
    .from('email_settings')
    .select('*')
    .single();

  if (error) throw error;
  return data as EmailSettings;
}

// Get email template by name
export async function getEmailTemplate(name: string): Promise<EmailTemplate> {
  const { data, error } = await supabase
    .from('email_templates')
    .select('*')
    .eq('name', name)
    .eq('status', 'published')
    .single();

  if (error) throw error;
  return data as EmailTemplate;
}

// Verify email settings
export async function verifyEmailSettings(settings: EmailSettings): Promise<boolean> {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/verify-email`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`
        },
        body: JSON.stringify(settings)
      }
    );

    if (!response.ok) {
      throw new Error('Failed to verify email settings');
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    console.error('Error verifying email settings:', error);
    return false;
  }
}