import React from 'react';

interface SectionProps {
  title?: string;
  subtitle?: string;
  className?: string;
  children: React.ReactNode;
  background?: 'white' | 'gray' | 'primary';
}

function Section({ 
  title, 
  subtitle, 
  className = '', 
  children,
  background = 'white'
}: SectionProps) {
  const bgColors = {
    white: 'bg-white',
    gray: 'bg-gray-50',
    primary: 'bg-primary'
  };

  const titleColors = {
    white: 'text-primary',
    gray: 'text-primary',
    primary: 'text-white'
  };

  const subtitleColors = {
    white: 'text-gray-600',
    gray: 'text-gray-600',
    primary: 'text-gray-300'
  };

  return (
    <section className={`py-8 md:py-12 lg:py-16 ${bgColors[background]} ${className}`}>
      <div className="max-w-7xl mx-auto px-4">
        {(title || subtitle) && (
          <div className="mb-6 md:mb-8 lg:mb-12">
            {title && (
              <h2 className={`text-xl md:text-2xl lg:text-3xl font-bold text-center mb-2 md:mb-3 ${titleColors[background]}`}>
                {title}
              </h2>
            )}
            {subtitle && (
              <p className={`text-sm md:text-base text-center max-w-2xl mx-auto px-2 ${subtitleColors[background]}`}>
                {subtitle}
              </p>
            )}
          </div>
        )}
        {children}
      </div>
    </section>
  );
}

export default Section;