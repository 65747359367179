import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';

interface FormFieldProps {
  label: string;
  error?: string;
  icon?: LucideIcon;
  required?: boolean;
  className?: string;
  children: React.ReactNode;
}

function FormField({ label, error, icon: Icon, required, className = '', children }: FormFieldProps) {
  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        <div className="flex items-center gap-1">
          {Icon && <Icon className="w-4 h-4 text-gray-400" />}
          <span>{label}</span>
          {required && <span className="text-red-500">*</span>}
        </div>
      </label>
      {children}
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}

export default FormField;