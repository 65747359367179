import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Zap } from 'lucide-react';
import SEO from '../components/SEO';
import caseStudies, { getCaseStudiesByIndustry, getCaseStudiesByService, getIndustryCounts, getServiceCounts, getPublishedCaseStudies } from '../data/caseStudies';
import CaseStudiesFilter from '../components/CaseStudiesFilter';
import CaseStudyCard from '../components/CaseStudyCard';
import Button from '../components/admin/Button';
import { ProcessSkeleton } from '../components/Skeleton';

function CaseStudies() {
  const [loading, setLoading] = useState(true);
  const [showLive, setShowLive] = useState(false);
  const [isVisible, setIsVisible] = useState({
    filter: false,
    grid: false,
    cta: false
  });
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState<'industry' | 'service'>('industry');
  const [activeIndustry, setActiveIndustry] = useState('all');
  const [activeService, setActiveService] = useState('all');

  const sectionsRef = useRef<{ [key: string]: HTMLDivElement | null }>({
    filter: null,
    grid: null,
    cta: null
  });

  const industryCounts = getIndustryCounts();
  const serviceCounts = getServiceCounts();

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.getAttribute('data-section');
          if (sectionId) {
            setIsVisible(prev => ({
              ...prev,
              [sectionId]: entry.isIntersecting
            }));
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    Object.keys(sectionsRef.current).forEach(sectionId => {
      const element = sectionsRef.current[sectionId];
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [loading]); // Re-run when loading state changes

  // Handle URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const industry = params.get('industry');
    if (industry) {
      setActiveFilter('industry');
      setActiveIndustry(industry);
    }
  }, [location.search]);

  // Handle filter events from navbar
  useEffect(() => {
    const handleIndustryFilter = (event: CustomEvent<{ industry: string }>) => {
      setActiveFilter('industry');
      setActiveIndustry(event.detail.industry || 'all');
    };

    window.addEventListener('setIndustryFilter', handleIndustryFilter as EventListener);
    return () => {
      window.removeEventListener('setIndustryFilter', handleIndustryFilter as EventListener);
    };
  }, []);

  const filteredCaseStudies = showLive 
    ? getPublishedCaseStudies().filter(study => {
        if (activeFilter === 'industry') {
          return activeIndustry === 'all' ? true : study.industry === activeIndustry;
        } else {
          return activeService === 'all' ? true : study.service === activeService;
        }
      })
    : activeFilter === 'industry'
      ? getCaseStudiesByIndustry(activeIndustry)
      : getCaseStudiesByService(activeService);

  if (loading) {
    return (
      <div className="pt-20 bg-white">
        <section className="bg-primary py-12 md:py-16">
          <div className="max-w-5xl mx-auto px-4">
            <div className="text-center">
              <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">Case Studies</h1>
              <p className="text-lg text-gray-300 max-w-3xl mx-auto">
                Explore our success stories across different industries and services
              </p>
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="mb-12">
              <ProcessSkeleton />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[1, 2, 3, 4].map((i) => (
                <ProcessSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Case Studies"
        description="Explore our success stories across renewable energy, enterprise, and digital transformation projects. Real-world examples of our technology solutions in action."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'CollectionPage',
          name: 'Case Studies - Senses Innovations',
          description: 'Success stories and case studies from Senses Innovations',
          mainEntity: {
            '@type': 'ItemList',
            itemListElement: caseStudies.map((study, index) => ({
              '@type': 'Article',
              position: index + 1,
              name: study.title,
              description: study.description,
              image: study.image,
              url: `https://sensesinnovations.com/case-studies#${study.title.toLowerCase().replace(/\s+/g, '-')}`,
              author: {
                '@type': 'Organization',
                name: 'Senses Innovations'
              },
              publisher: {
                '@type': 'Organization',
                name: 'Senses Innovations',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://sensesinnovations.com/logo.svg'
                }
              },
              datePublished: study.createdAt,
              dateModified: study.updatedAt
            }))
          },
          breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': 'https://sensesinnovations.com',
                  name: 'Home'
                }
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': 'https://sensesinnovations.com/case-studies',
                  name: 'Case Studies'
                }
              }
            ]
          }
        }}
      />
      {/* Header Section */}
      <section className="bg-primary py-12 md:py-16">
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4 opacity-0 animate-[fadeUp_0.8s_ease-out_forwards]">
            Case Studies
          </h1>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto text-center opacity-0 animate-[fadeUp_0.8s_ease-out_0.2s_forwards]">
            Explore our success stories across different industries and services
          </p>
        </div>
      </section>

      {/* Case Studies Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          {/* Filters */}
          <div 
            ref={(el) => { sectionsRef.current.filter = el; }}
            data-section="filter"
            className={`transition-all duration-700 transform ${
              isVisible.filter ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}
          >
            <CaseStudiesFilter
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              activeIndustry={activeIndustry}
              setActiveIndustry={setActiveIndustry}
              activeService={activeService}
              setActiveService={setActiveService}
              industryCounts={industryCounts}
              serviceCounts={serviceCounts}
            />
          </div>

          {/* Case Studies Grid */}
          <div 
            ref={(el) => { sectionsRef.current.grid = el; }}
            data-section="grid"
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            {filteredCaseStudies.map((study, index) => (
              <div 
                key={index}
                className={`transition-all duration-700 transform ${
                  isVisible.grid ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <CaseStudyCard {...study} />
              </div>
            ))}
          </div>

          {/* No Results Message */}
          {filteredCaseStudies.length === 0 && (
            <div className="text-center py-12">
              <p className="text-lg text-gray-600">
                No case studies found for the selected filter.
              </p>
            </div>
          )}
        </div>
      </section>

      {/* CTA Section */}
      <section 
        ref={(el) => { sectionsRef.current.cta = el; }}
        data-section="cta"
        className="py-16 bg-primary"
      >
        <div className={`max-w-4xl mx-auto text-center px-4 transition-all duration-700 transform ${
          isVisible.cta ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
        }`}>
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Write Your Success Story?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help transform your business with our technology solutions.
          </p>
          <button className="button-secondary">
            Contact Us
          </button>
        </div>
      </section>
    </div>
  );
}

export default CaseStudies;