import React from 'react';
import { DivideIcon as LucideIcon, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ServiceCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  features: string[];
  link?: string;
}

function ServiceCard({ icon: Icon, title, description, features, link }: ServiceCardProps) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const CardWrapper = ({ children }: { children: React.ReactNode }) => {
    if (link) {
      return (
        <Link to={link} className="block" onClick={handleClick}>
          {children}
        </Link>
      );
    }
    return <>{children}</>;
  };

  return (
    <CardWrapper>
      <div className="card group">
        <div className="card-gradient" />
        <div className="relative z-10">
          <div className="flex items-start justify-between">
            <div className="mb-6">
              <Icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
            </div>
            {link && (
              <ChevronRight className="w-6 h-6 text-primary transition-all duration-300 group-hover:text-accent group-hover:translate-x-1" />
            )}
          </div>
          <h3 className="text-2xl font-semibold mb-3">{title}</h3>
          <p className="text-gray-600 mb-6">{description}</p>
          <ul className="space-y-2">
            {features.map((feature, index) => (
              <li key={index} className="text-gray-600 flex items-center">
                <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </CardWrapper>
  );
}

export default ServiceCard;