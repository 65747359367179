import { supabase } from './supabase/client';
import type { AuditLog, ActivityLog } from '../types/admin';

export async function createAuditLog(
  userId: string,
  action: string,
  entityType: string,
  entityId: string,
  changes: Record<string, any>,
  ipAddress?: string,
  userAgent?: string
) {
  const { data, error } = await supabase
    .from('audit_logs')
    .insert({
      user_id: userId,
      action,
      entity_type: entityType,
      entity_id: entityId,
      changes,
      ip_address: ipAddress,
      user_agent: userAgent
    })
    .select()
    .single();

  if (error) throw error;
  return data as AuditLog;
}

export async function createActivityLog(
  userId: string,
  action: string,
  details: string,
  status: 'success' | 'error' | 'warning' | 'info',
  category?: string,
  metadata?: Record<string, any>,
  ipAddress?: string,
  userAgent?: string
) {
  const { data, error } = await supabase
    .from('activity_logs')
    .insert({
      user_id: userId,
      action,
      details,
      status,
      category,
      metadata,
      ip_address: ipAddress,
      user_agent: userAgent
    })
    .select()
    .single();

  if (error) throw error;
  return data as ActivityLog;
}

export async function getAuditLogs(
  page = 1,
  limit = 10,
  filters?: {
    userId?: string;
    entityType?: string;
    action?: string;
    fromDate?: string;
    toDate?: string;
  }
) {
  let query = supabase
    .from('audit_logs')
    .select('*', { count: 'exact' });

  if (filters?.userId) {
    query = query.eq('user_id', filters.userId);
  }

  if (filters?.entityType) {
    query = query.eq('entity_type', filters.entityType);
  }

  if (filters?.action) {
    query = query.eq('action', filters.action);
  }

  if (filters?.fromDate) {
    query = query.gte('timestamp', filters.fromDate);
  }

  if (filters?.toDate) {
    query = query.lte('timestamp', filters.toDate);
  }

  const { data, error, count } = await query
    .order('timestamp', { ascending: false })
    .range((page - 1) * limit, page * limit - 1);

  if (error) throw error;
  return { data: data as AuditLog[], count };
}

export async function getActivityLogs(
  page = 1,
  limit = 10,
  filters?: {
    userId?: string;
    category?: string;
    status?: string;
    fromDate?: string;
    toDate?: string;
    archived?: boolean;
  }
) {
  let query = supabase
    .from('activity_logs')
    .select(`
      *,
      get_activity_log_user,
      get_activity_log_metadata
    `, { count: 'exact' });

  if (filters?.userId) {
    query = query.eq('user_id', filters.userId);
  }

  if (filters?.category) {
    query = query.eq('category', filters.category);
  }

  if (filters?.status) {
    query = query.eq('status', filters.status);
  }

  if (filters?.fromDate) {
    query = query.gte('timestamp', filters.fromDate);
  }

  if (filters?.toDate) {
    query = query.lte('timestamp', filters.toDate);
  }

  if (filters?.archived !== undefined) {
    if (filters.archived) {
      query = query.not('archived_at', 'is', null);
    } else {
      query = query.is('archived_at', null);
    }
  }

  const { data, error, count } = await query
    .order('timestamp', { ascending: false })
    .range((page - 1) * limit, page * limit - 1);

  if (error) throw error;
  return { data: data as ActivityLog[], count };
}

export async function archiveActivityLogs(olderThan: Date) {
  const { error } = await supabase
    .from('activity_logs')
    .update({ archived_at: new Date().toISOString() })
    .lt('timestamp', olderThan.toISOString())
    .is('archived_at', null);

  if (error) throw error;
}

export async function exportActivityLogs(filters?: {
  userId?: string;
  category?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
  archived?: boolean;
}) {
  let query = supabase
    .from('activity_logs')
    .select(`
      *,
      get_activity_log_user,
      get_activity_log_metadata
    `);

  // Apply filters
  if (filters?.userId) query = query.eq('user_id', filters.userId);
  if (filters?.category) query = query.eq('category', filters.category);
  if (filters?.status) query = query.eq('status', filters.status);
  if (filters?.fromDate) query = query.gte('timestamp', filters.fromDate);
  if (filters?.toDate) query = query.lte('timestamp', filters.toDate);
  if (filters?.archived !== undefined) {
    if (filters.archived) {
      query = query.not('archived_at', 'is', null);
    } else {
      query = query.is('archived_at', null);
    }
  }

  const { data, error } = await query.order('timestamp', { ascending: false });

  if (error) throw error;
  return data;
}