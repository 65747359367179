import React from 'react';
import { Link } from 'react-router-dom';
import { Server, Cloud, Network, Database, Shield, Workflow, Settings, Zap, Monitor, GitBranch, Lock, RefreshCw } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function Infrastructure() {
  const capabilities = [
    {
      icon: Server,
      title: "Data Centers",
      description: "State-of-the-art data center solutions"
    },
    {
      icon: Cloud,
      title: "Cloud Services",
      description: "Multi-cloud infrastructure management"
    },
    {
      icon: Network,
      title: "Networking",
      description: "Advanced network architecture"
    },
    {
      icon: Shield,
      title: "Security",
      description: "Comprehensive security measures"
    }
  ];

  const services = [
    {
      icon: Server,
      title: "Data Center Solutions",
      description: "Modern and efficient data center infrastructure",
      features: [
        "Server Infrastructure",
        "Storage Solutions",
        "Backup Systems",
        "Disaster Recovery"
      ]
    },
    {
      icon: Cloud,
      title: "Cloud Infrastructure",
      description: "Scalable cloud solutions for modern businesses",
      features: [
        "Multi-cloud Management",
        "Cloud Migration",
        "Hybrid Solutions",
        "Cost Optimization"
      ]
    },
    {
      icon: Network,
      title: "Network Architecture",
      description: "Robust and secure network infrastructure",
      features: [
        "Network Design",
        "Implementation",
        "Performance Monitoring",
        "Security Integration"
      ]
    },
    {
      icon: Database,
      title: "Storage Solutions",
      description: "Efficient data storage and management systems",
      features: [
        "Storage Architecture",
        "Data Management",
        "Backup Solutions",
        "Recovery Systems"
      ]
    }
  ];

  const features = [
    {
      icon: Monitor,
      title: "24/7 Monitoring",
      description: "Continuous infrastructure monitoring and alerts"
    },
    {
      icon: GitBranch,
      title: "Scalability",
      description: "Easily scale resources based on demand"
    },
    {
      icon: Lock,
      title: "Security",
      description: "Enterprise-grade security measures"
    },
    {
      icon: RefreshCw,
      title: "High Availability",
      description: "Redundant systems for maximum uptime"
    },
    {
      icon: Workflow,
      title: "Automation",
      description: "Automated deployment and management"
    },
    {
      icon: Settings,
      title: "Management",
      description: "Comprehensive infrastructure management"
    }
  ];

  const process = [
    {
      phase: "01",
      title: "Assessment",
      description: "Infrastructure evaluation and planning",
      steps: [
        "Current State Analysis",
        "Requirements Gathering",
        "Performance Assessment",
        "Security Audit"
      ]
    },
    {
      phase: "02",
      title: "Design",
      description: "Architecture and solution design",
      steps: [
        "Architecture Planning",
        "Security Design",
        "Scalability Planning",
        "Cost Analysis"
      ]
    },
    {
      phase: "03",
      title: "Implementation",
      description: "Infrastructure deployment and setup",
      steps: [
        "Phased Deployment",
        "Security Implementation",
        "Performance Testing",
        "Documentation"
      ]
    },
    {
      phase: "04",
      title: "Management",
      description: "Ongoing support and optimization",
      steps: [
        "24/7 Monitoring",
        "Regular Maintenance",
        "Performance Tuning",
        "Security Updates"
      ]
    }
  ];

  const caseStudies = getCaseStudiesByService('infrastructure');

  return (
    <div className="pt-20 bg-white">
      <PageHeader
        title="Infrastructure Solutions"
        subtitle="Robust and scalable infrastructure solutions for modern enterprises"
      />

      {/* Capabilities Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Capabilities</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive infrastructure solutions to power your business
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {capabilities.map((capability, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <capability.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-2">{capability.title}</h3>
                <p className="text-sm text-gray-600">{capability.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Services Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive infrastructure services tailored to your needs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <service.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Features Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Key Features</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Enterprise-grade features for reliable infrastructure
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10 flex items-start gap-4">
                <div className="flex-shrink-0">
                  <feature.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Process Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Process</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to infrastructure implementation
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {process.map((phase, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{phase.phase}</div>
                <h3 className="text-xl font-semibold mb-2">{phase.title}</h3>
                <p className="text-gray-600 mb-4">{phase.description}</p>
                <ul className="space-y-2">
                  {phase.steps.map((step, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful infrastructure implementations"
        background="gray"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Build Your Infrastructure?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help create a robust infrastructure solution for your business.
          </p>
          <Link to="/contact" className="button-secondary">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Infrastructure;