import React from 'react';
import { Lightbulb } from 'lucide-react';

interface SEOSuggestion {
  field: string;
  current: string;
  suggested: string;
  reason: string;
}

interface SEOSuggestionsProps {
  suggestions: SEOSuggestion[];
  onApply: (suggestion: SEOSuggestion) => void;
}

function SEOSuggestions({ suggestions, onApply }: SEOSuggestionsProps) {
  if (suggestions.length === 0) return null;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 overflow-hidden">
      <div className="p-4 border-b border-gray-200 dark:border-gray-700">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center gap-2">
          <Lightbulb className="w-5 h-5 text-yellow-500" />
          Smart Suggestions
        </h3>
      </div>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        {suggestions.map((suggestion, index) => (
          <div key={index} className="p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200">
            <div className="flex items-start justify-between gap-4">
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {suggestion.field}
                </p>
                <div className="mt-1 space-y-1">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Current: {suggestion.current}
                  </p>
                  <p className="text-sm text-primary dark:text-accent">
                    Suggested: {suggestion.suggested}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {suggestion.reason}
                  </p>
                </div>
              </div>
              <button
                onClick={() => onApply(suggestion)}
                className="px-3 py-1 text-sm text-primary dark:text-accent border border-primary/20 dark:border-accent/20 rounded-lg hover:bg-primary/5 dark:hover:bg-accent/5 transition-colors duration-200"
              >
                Apply
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SEOSuggestions;