import React, { useState, useEffect } from 'react';
import { Shield, AlertTriangle, Users, Lock, Key, RefreshCw, Eye, Clock, XCircle } from 'lucide-react';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import SearchInput from '../../components/admin/SearchInput';
import DataTable from '../../components/admin/DataTable';
import { supabase } from '../../lib/supabase/client';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

function SecurityPage() {
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [securityEvents, setSecurityEvents] = useState<any[]>([]);
  const [stats, setStats] = useState({
    activeUsers: 0,
    failedLogins: 0,
    suspiciousActivities: 0,
    twoFactorEnabled: 0
  });

  useEffect(() => {
    loadSecurityData();
  }, []);

  const loadSecurityData = async () => {
    try {
      setLoading(true);

      // Get active sessions count
      const { data: sessions, error: sessionsError } = await supabase
        .from('sessions')
        .select('user_id', { count: 'exact', head: true })
        .eq('is_active', true);

      if (sessionsError) throw sessionsError;

      // Get users with 2FA enabled
      const { data: twoFactorUsers, error: twoFactorError } = await supabase
        .from('profiles')
        .select('id', { count: 'exact', head: true })
        .eq('is_two_factor_enabled', true);

      if (twoFactorError) throw twoFactorError;

      // Get security events
      const { data: events, error: eventsError } = await supabase
        .from('activity_logs')
        .select(`
          *,
          get_activity_log_user
        `)
        .eq('category', 'security')
        .order('timestamp', { ascending: false })
        .limit(100);

      if (eventsError) throw eventsError;

      // Calculate stats
      const failedLogins = events?.filter(e => e.action === 'Failed Login Attempt').length || 0;
      const suspiciousActivities = events?.filter(e => e.status === 'warning').length || 0;

      setStats({
        activeUsers: sessions?.count || 0,
        failedLogins,
        suspiciousActivities,
        twoFactorEnabled: twoFactorUsers?.count || 0
      });

      setSecurityEvents(events || []);
    } catch (error) {
      console.error('Error loading security data:', error);
      toast.error('Failed to load security data');
    } finally {
      setLoading(false);
    }
  };

  const getEventIcon = (event: any) => {
    switch (event.action) {
      case 'Failed Login Attempt':
        return <XCircle className="w-4 h-4 text-red-500" />;
      case 'New Device Login':
        return <Eye className="w-4 h-4 text-amber-500" />;
      case 'Password Changed':
        return <Key className="w-4 h-4 text-green-500" />;
      case '2FA Enabled':
        return <Lock className="w-4 h-4 text-green-500" />;
      default:
        return <AlertTriangle className="w-4 h-4 text-amber-500" />;
    }
  };

  const getEventColor = (event: any) => {
    switch (event.status) {
      case 'error':
        return 'bg-red-100 text-red-800';
      case 'warning':
        return 'bg-amber-100 text-amber-800';
      case 'success':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div>
      <ContentHeader
        title="Security"
        description="Monitor security events and manage security settings"
        icon={Shield}
        actions={
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={loadSecurityData}
          >
            Refresh
          </Button>
        }
      />

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
        <Card
          title="Active Users"
          icon={Users}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-primary">{stats.activeUsers}</div>
            <p className="text-sm text-gray-600 mt-1">Current sessions</p>
          </div>
        </Card>

        <Card
          title="Failed Logins"
          icon={XCircle}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-red-600">{stats.failedLogins}</div>
            <p className="text-sm text-gray-600 mt-1">Last 24 hours</p>
          </div>
        </Card>

        <Card
          title="Suspicious Activity"
          icon={AlertTriangle}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-amber-600">{stats.suspiciousActivities}</div>
            <p className="text-sm text-gray-600 mt-1">Requires review</p>
          </div>
        </Card>

        <Card
          title="2FA Enabled"
          icon={Lock}
          className="bg-white"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-green-600">{stats.twoFactorEnabled}</div>
            <p className="text-sm text-gray-600 mt-1">Protected accounts</p>
          </div>
        </Card>
      </div>

      <Card>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search security events..."
            className="md:w-64"
          />
        </div>

        <DataTable
          columns={[
            { 
              header: 'Timestamp',
              accessor: (row) => (
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4 text-gray-400" />
                  <span>{format(new Date(row.timestamp), 'MMM d, yyyy HH:mm:ss')}</span>
                </div>
              ),
              className: 'whitespace-nowrap'
            },
            {
              header: 'Event',
              accessor: (row) => (
                <div className="flex items-center gap-2">
                  {getEventIcon(row)}
                  <span className="font-medium text-gray-900">{row.action}</span>
                </div>
              )
            },
            {
              header: 'User',
              accessor: (row) => {
                const user = row.get_activity_log_user;
                return user ? (
                  <div>
                    <div className="font-medium text-gray-900">
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">{user.email}</div>
                  </div>
                ) : (
                  <span className="text-gray-500">System</span>
                );
              }
            },
            {
              header: 'Details',
              accessor: (row) => (
                <div>
                  <div className="text-gray-600">{row.details}</div>
                  {row.ip_address && (
                    <div className="text-sm text-gray-500 mt-1">
                      IP: {row.ip_address}
                    </div>
                  )}
                </div>
              )
            },
            {
              header: 'Status',
              accessor: (row) => (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  getEventColor(row)
                }`}>
                  {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
              )
            }
          ]}
          data={securityEvents.filter(event => {
            const matchesSearch = 
              event.action?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              event.details?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              event.get_activity_log_user?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              event.ip_address?.toLowerCase().includes(searchQuery.toLowerCase());
            
            return matchesSearch;
          })}
          loading={loading}
        />
      </Card>
    </div>
  );
}

export default SecurityPage;