import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, Users, Lightbulb, Target, Workflow, Cloud, Database, Lock, Network, LineChart, Zap, Settings } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function ITConsultancy() {
  const expertise = [
    {
      icon: Cloud,
      title: "Cloud Strategy",
      description: "Expert guidance on cloud adoption and migration"
    },
    {
      icon: Shield,
      title: "Security",
      description: "Comprehensive cybersecurity solutions"
    },
    {
      icon: Database,
      title: "Infrastructure",
      description: "Optimized IT infrastructure design"
    },
    {
      icon: Network,
      title: "Network",
      description: "Advanced networking solutions"
    }
  ];

  const services = [
    {
      icon: Lightbulb,
      title: "Strategic Planning",
      description: "Comprehensive IT strategy development and roadmap creation",
      features: [
        "Technology Assessment",
        "Digital Strategy",
        "Risk Management",
        "Innovation Planning"
      ]
    },
    {
      icon: Shield,
      title: "Security Consulting",
      description: "Robust cybersecurity solutions and compliance management",
      features: [
        "Security Audits",
        "Compliance Management",
        "Threat Prevention",
        "Security Training"
      ]
    },
    {
      icon: Cloud,
      title: "Cloud Consulting",
      description: "Expert guidance for cloud adoption and optimization",
      features: [
        "Cloud Strategy",
        "Migration Planning",
        "Architecture Design",
        "Cost Optimization"
      ]
    },
    {
      icon: Workflow,
      title: "Process Optimization",
      description: "Streamline operations and improve efficiency",
      features: [
        "Workflow Analysis",
        "Process Automation",
        "Performance Tuning",
        "Best Practices"
      ]
    }
  ];

  const methodology = [
    {
      phase: "01",
      title: "Discovery",
      description: "Understanding your business needs and challenges",
      steps: [
        "Stakeholder Interviews",
        "Current State Analysis",
        "Requirements Gathering",
        "Gap Analysis"
      ]
    },
    {
      phase: "02",
      title: "Analysis",
      description: "Detailed assessment and solution planning",
      steps: [
        "Technical Assessment",
        "Risk Evaluation",
        "Solution Design",
        "ROI Analysis"
      ]
    },
    {
      phase: "03",
      title: "Implementation",
      description: "Executing the planned solutions",
      steps: [
        "Project Planning",
        "Change Management",
        "Solution Deployment",
        "Knowledge Transfer"
      ]
    },
    {
      phase: "04",
      title: "Optimization",
      description: "Continuous improvement and support",
      steps: [
        "Performance Monitoring",
        "Process Refinement",
        "Ongoing Support",
        "Regular Reviews"
      ]
    }
  ];

  const benefits = [
    {
      icon: Target,
      title: "Strategic Alignment",
      description: "Align IT initiatives with business goals"
    },
    {
      icon: Lock,
      title: "Risk Mitigation",
      description: "Identify and address potential risks"
    },
    {
      icon: LineChart,
      title: "Performance Optimization",
      description: "Improve operational efficiency"
    },
    {
      icon: Zap,
      title: "Innovation",
      description: "Stay ahead with latest technologies"
    },
    {
      icon: Settings,
      title: "Cost Optimization",
      description: "Optimize IT spending and ROI"
    },
    {
      icon: Users,
      title: "Expert Guidance",
      description: "Access to industry expertise"
    }
  ];

  const caseStudies = getCaseStudiesByService('it-consultancy');

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="IT Consultancy"
        description="Strategic technology consulting to drive your business forward. Expert guidance in IT strategy, cybersecurity, cloud adoption, and infrastructure optimization."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'IT Consultancy Services',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'IT Consulting',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'IT Consultancy Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Strategic Planning',
                  description: 'Comprehensive IT strategy development and roadmap creation'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Security Consulting',
                  description: 'Robust cybersecurity solutions and compliance management'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Process Optimization',
                  description: 'Streamline operations and improve efficiency'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="IT Consultancy"
        subtitle="Strategic technology consulting to drive your business forward"
      />

      {/* Expertise Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Expertise</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive IT consulting services backed by years of industry experience
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {expertise.map((item, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <item.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-2">{item.title}</h3>
                <p className="text-sm text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Services Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive consulting services to address your technology needs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <service.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Methodology Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Methodology</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to delivering successful IT consulting projects
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {methodology.map((phase, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{phase.phase}</div>
                <h3 className="text-xl font-semibold mb-2">{phase.title}</h3>
                <p className="text-gray-600 mb-4">{phase.description}</p>
                <ul className="space-y-2">
                  {phase.steps.map((step, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Benefits Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Benefits</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Key advantages of partnering with our IT consulting team
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10 flex items-start gap-4">
                <div className="flex-shrink-0">
                  <benefit.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">{benefit.title}</h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful IT consulting projects"
        background="gray"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Transform Your IT Strategy?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how our IT consulting services can help optimize your technology investments.
          </p>
          <Link to="/contact" className="button-secondary">
            Schedule a Consultation
          </Link>
        </div>
      </section>
    </div>
  );
}

export default ITConsultancy;