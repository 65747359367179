import { js2xml } from 'xml-js';
import slugify from 'slugify';
import type { SEOData } from '../types/admin';

export function generateSitemap(pages: { path: string; lastmod?: string; priority?: number }[]) {
  const urlset = {
    _declaration: { _attributes: { version: '1.0', encoding: 'UTF-8' } },
    urlset: {
      _attributes: {
        xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9'
      },
      url: pages.map(page => ({
        loc: `${window.location.origin}${page.path}`,
        lastmod: page.lastmod || new Date().toISOString(),
        changefreq: 'weekly',
        priority: page.priority || 0.8
      }))
    }
  };

  return js2xml(urlset, { compact: true, spaces: 2 });
}

export function generateRobotsTxt(options: {
  allowAll?: boolean;
  disallowPaths?: string[];
  sitemapUrl?: string;
}) {
  const lines = [
    'User-agent: *'
  ];

  if (options.allowAll) {
    lines.push('Allow: /');
  }

  if (options.disallowPaths?.length) {
    options.disallowPaths.forEach(path => {
      lines.push(`Disallow: ${path}`);
    });
  }

  if (options.sitemapUrl) {
    lines.push(`Sitemap: ${options.sitemapUrl}`);
  }

  return lines.join('\n');
}

export function generateSlug(title: string): string {
  return slugify(title, {
    lower: true,
    strict: true,
    trim: true
  });
}

export function validateSEOData(data: SEOData): string[] {
  const errors: string[] = [];

  if (data.title.length > 60) {
    errors.push('Meta title should not exceed 60 characters');
  }

  if (data.description.length > 160) {
    errors.push('Meta description should not exceed 160 characters');
  }

  if (data.ogTitle && data.ogTitle.length > 95) {
    errors.push('Open Graph title should not exceed 95 characters');
  }

  if (data.ogDescription && data.ogDescription.length > 200) {
    errors.push('Open Graph description should not exceed 200 characters');
  }

  return errors;
}