import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import { Send, CheckCircle, AlertCircle } from 'lucide-react';

interface ContactFormProps {
  onSuccess?: () => void;
}

interface ValidationError {
  field: string;
  message: string;
}

const HOLD_DURATION = 3000; // 3 seconds hold duration

function ContactForm({ onSuccess }: ContactFormProps) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    industry: '',
    service: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [holdProgress, setHoldProgress] = useState(0);
  const [isHolding, setIsHolding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [touchedFields, setTouchedFields] = useState<Set<string>>(new Set());
  const holdTimerRef = useRef<number | null>(null);
  const startTimeRef = useRef<number>(0);
  const formRef = useRef<HTMLFormElement>(null);

  const validateForm = (): ValidationError[] => {
    const errors: ValidationError[] = [];
    
    if (!formData.firstName.trim()) {
      errors.push({ field: 'firstName', message: 'First name is required' });
    }
    
    if (!formData.lastName.trim()) {
      errors.push({ field: 'lastName', message: 'Last name is required' });
    }
    
    if (!formData.email.trim()) {
      errors.push({ field: 'email', message: 'Email is required' });
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.push({ field: 'email', message: 'Please enter a valid email address' });
    }
    
    if (!formData.company.trim()) {
      errors.push({ field: 'company', message: 'Company name is required' });
    }
    
    if (!formData.industry) {
      errors.push({ field: 'industry', message: 'Please select an industry' });
    }
    
    if (!formData.service) {
      errors.push({ field: 'service', message: 'Please select a service' });
    }
    
    if (!formData.message.trim()) {
      errors.push({ field: 'message', message: 'Message is required' });
    } else if (formData.message.length < 10) {
      errors.push({ field: 'message', message: 'Message must be at least 10 characters' });
    }
    
    return errors;
  };

  const hasError = (field: string): boolean => {
    return errors.some(error => error.field === field);
  };

  const getErrorMessage = (field: string): string => {
    const error = errors.find(error => error.field === field);
    return error ? error.message : '';
  };

  const handleBlur = (field: string) => {
    setTouchedFields(prev => new Set(prev).add(field));
    const newErrors = validateForm();
    setErrors(newErrors);
  };

  const startHold = () => {
    const validationErrors = validateForm();
    setErrors(validationErrors);
    
    if (validationErrors.length > 0) {
      // Shake invalid fields
      validationErrors.forEach(error => {
        const element = formRef.current?.elements.namedItem(error.field) as HTMLElement;
        if (element) {
          element.classList.add('animate-[shake_0.5s_cubic-bezier(.36,.07,.19,.97)_both]');
          setTimeout(() => {
            element.classList.remove('animate-[shake_0.5s_cubic-bezier(.36,.07,.19,.97)_both]');
          }, 500);
        }
      });
      
      // Show error toast
      toast.error('Please fill in all required fields correctly');
      return;
    }

    setIsHolding(true);
    startTimeRef.current = Date.now();
    
    // Animate progress
    const animate = () => {
      const elapsed = Date.now() - startTimeRef.current;
      const progress = Math.min((elapsed / HOLD_DURATION) * 100, 100);
      setHoldProgress(progress);
      
      if (progress < 100) {
        holdTimerRef.current = requestAnimationFrame(animate);
      } else {
        handleSubmit();
      }
    };
    
    holdTimerRef.current = requestAnimationFrame(animate);
  };

  const endHold = () => {
    if (holdTimerRef.current) {
      cancelAnimationFrame(holdTimerRef.current);
    }
    setIsHolding(false);
    setHoldProgress(0);
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    
    const validationErrors = validateForm();
    setErrors(validationErrors);
    
    if (validationErrors.length > 0) {
      toast.error('Please fill in all required fields correctly');
      return;
    }

    try {
      setLoading(true);
      
      // Prepare email data
      const emailData = {
        from_name: `${formData.firstName} ${formData.lastName}`,
        from_email: formData.email,
        company: formData.company,
        industry: formData.industry,
        service: formData.service,
        message: formData.message,
        to_name: 'Senses Innovations Team',
        to_email: 'a.elfouly@sensesinnovations.com',
        test_mode: testMode ? 'true' : 'false',
        reply_to: formData.email,
        origin: window.location.origin
      };
      
      // Send email
      const response = await emailjs.send(
        'service_r3wgpdq',
        'template_contact',
        emailData
      );

      if (!response) {
        throw new Error('No response received from email service');
      }
      
      if (response.status !== 200) {
        throw new Error(`Failed to send message: ${response.text || 'Unknown error'}`);
      }
      
      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        industry: '',
        service: '',
        message: ''
      });

      toast.success('Message sent successfully!');
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 3000); // Reset after 3 seconds
      onSuccess?.();
    } catch (error) {
      console.error('Error submitting form:', error);
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'Failed to send message. Please try again.';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Only validate if field has been touched
    if (touchedFields.has(name)) {
      const newErrors = validateForm();
      setErrors(newErrors);
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
      {/* Test Mode Toggle (only visible in development) */}
      {process.env.NODE_ENV === 'development' && (
        <div className="flex items-center gap-2 p-4 bg-yellow-50 rounded-lg">
          <input
            type="checkbox"
            id="testMode"
            checked={testMode}
            onChange={(e) => setTestMode(e.target.checked)}
            className="w-4 h-4 text-accent border-gray-300 rounded focus:ring-accent"
          />
          <label htmlFor="testMode" className="text-sm text-yellow-800">
            Test Mode (No emails will be sent)
          </label>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            onBlur={() => handleBlur('firstName')}
            className="input-field"
            placeholder="John"
            required
            disabled={loading}
          />
          {hasError('firstName') && touchedFields.has('firstName') && (
            <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
              <AlertCircle className="w-4 h-4" />
              {getErrorMessage('firstName')}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            onBlur={() => handleBlur('lastName')}
            className="input-field"
            placeholder="Doe"
            required
            disabled={loading}
          />
          {hasError('lastName') && touchedFields.has('lastName') && (
            <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
              <AlertCircle className="w-4 h-4" />
              {getErrorMessage('lastName')}
            </p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Email
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          onBlur={() => handleBlur('email')}
          className="input-field"
          placeholder="john@example.com"
          required
          disabled={loading}
        />
        {hasError('email') && touchedFields.has('email') && (
          <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
            <AlertCircle className="w-4 h-4" />
            {getErrorMessage('email')}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Company
        </label>
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
          onBlur={() => handleBlur('company')}
          className="input-field"
          placeholder="Your Company"
          required
          disabled={loading}
        />
        {hasError('company') && touchedFields.has('company') && (
          <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
            <AlertCircle className="w-4 h-4" />
            {getErrorMessage('company')}
          </p>
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Industry
          </label>
          <select
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            onBlur={() => handleBlur('industry')}
            className="input-field"
            required
            disabled={loading}
          >
            <option value="">Select your industry</option>
            <option value="renewable-energy">Renewable Energy</option>
            <option value="retail">Retail & E-commerce</option>
            <option value="enterprise">Enterprise</option>
            <option value="manufacturing">Manufacturing</option>
            <option value="financial-services">Financial Services</option>
            <option value="healthcare">Healthcare</option>
            <option value="technology">Technology</option>
            <option value="education">Education</option>
            <option value="government">Government</option>
            <option value="other">Other</option>
          </select>
          {hasError('industry') && touchedFields.has('industry') && (
            <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
              <AlertCircle className="w-4 h-4" />
              {getErrorMessage('industry')}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Service
          </label>
          <select
            name="service"
            value={formData.service}
            onChange={handleChange}
            onBlur={() => handleBlur('service')}
            className="input-field"
            required
            disabled={loading}
          >
            <option value="">Select a service</option>
            <option value="app-development">App Development</option>
            <option value="digital-transformation">Digital Transformation</option>
            <option value="it-consultancy">IT Consultancy</option>
            <option value="infrastructure">Infrastructure</option>
            <option value="ai-solutions">AI Solutions</option>
            <option value="analytics">Analytics</option>
          </select>
          {hasError('service') && touchedFields.has('service') && (
            <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
              <AlertCircle className="w-4 h-4" />
              {getErrorMessage('service')}
            </p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Message
        </label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          onBlur={() => handleBlur('message')}
          rows={4}
          className="input-field"
          placeholder="How can we help you?"
          required
          disabled={loading}
        ></textarea>
        {hasError('message') && touchedFields.has('message') && (
          <p className="mt-1 text-sm text-red-600 flex items-center gap-1">
            <AlertCircle className="w-4 h-4" />
            {getErrorMessage('message')}
          </p>
        )}
      </div>

      <button
        type="button"
        disabled={loading}
        onTouchStart={startHold}
        onTouchEnd={endHold}
        onMouseDown={startHold}
        onMouseUp={endHold}
        onMouseLeave={endHold}
        className={`relative w-full py-3 px-4 transition-all duration-300 ${
          isSuccess ? 'bg-green-500' : 'bg-primary'
        } text-white rounded-lg transform hover:-translate-y-1 hover:shadow-lg overflow-hidden ${
          loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer select-none'
        } ${
          errors.length > 0 ? 'bg-red-500 hover:bg-red-600' : ''
        }`}
      >
        {/* Progress bar */}
        <div 
          className="absolute inset-0 origin-left transition-all duration-300"
          style={{ 
            transform: `scaleX(${holdProgress / 100})`,
            backgroundColor: 'rgba(110, 255, 234, 0.2)',
            transitionDuration: isHolding ? '0ms' : '300ms'
          }}
        />
        
        {/* Button content */}
        <div className="relative flex items-center justify-center gap-2">
          <Send 
            className={`w-4 h-4 transition-all duration-300 ${
              holdProgress === 100 
                ? 'translate-x-[100px] scale-0 opacity-0' 
                : isHolding 
                  ? 'scale-90' 
                  : ''
            }`}
          />
          {loading ? (
            <>
              <div className="w-4 h-4 border-2 border-current border-t-transparent rounded-full animate-spin"></div>
              <span>Sending...</span>
            </>
          ) : isSuccess ? (
            <>
              <CheckCircle className="w-4 h-4 animate-[pulse_1s_ease-in-out_infinite]" />
              <span>Message Sent!</span>
            </>
          ) : errors.length > 0 ? (
            <>
              <AlertCircle className="w-4 h-4" />
              <span>Please fix form errors</span>
            </>
          ) : isHolding ? (
            <span>{Math.round(holdProgress)}%</span>
          ) : (
            <span>Hold to send</span>
          )}
        </div>
      </button>

      {/* Test Results (only visible in development) */}
      {process.env.NODE_ENV === 'development' && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <h4 className="text-sm font-medium text-gray-900 mb-2">Test Information</h4>
          <pre className="text-xs text-gray-600 whitespace-pre-wrap">
            {JSON.stringify(formData, null, 2)}
          </pre>
        </div>
      )}
    </form>
  );
}

export default ContactForm;