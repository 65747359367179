import React, { useState, useEffect } from 'react';
import { Mail, Key, User, Settings } from 'lucide-react';
import Modal from './Modal';
import FormField from './FormField';
import Button from './Button';
import { supabase } from '../../lib/supabase/client';
import { verifyEmailSettings } from '../../lib/email';
import toast from 'react-hot-toast';

interface EmailSettingsEditorProps {
  isOpen: boolean;
  onClose: () => void;
  settings?: any;
}

function EmailSettingsEditor({ isOpen, onClose, settings }: EmailSettingsEditorProps) {
  const [formData, setFormData] = useState({
    host: '',
    port: 587,
    secure: false,
    username: '',
    password: '',
    fromEmail: '',
    fromName: '',
    replyTo: ''
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [testing, setTesting] = useState(false);

  useEffect(() => {
    if (settings) {
      setFormData({
        host: settings.host || '',
        port: settings.port || 587,
        secure: settings.secure || false,
        username: settings.username || '',
        password: settings.password || '',
        fromEmail: settings.from_email || '',
        fromName: settings.from_name || '',
        replyTo: settings.reply_to || ''
      });
    }
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const errors = [];
    if (!formData.host) errors.push('SMTP Host is required');
    if (!formData.username) errors.push('Username is required');
    if (!formData.password) errors.push('Password is required');
    if (!formData.fromEmail) errors.push('From Email is required');
    if (!formData.fromName) errors.push('From Name is required');

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setSaving(true);
      
      // For development, use the temporary admin user ID
      const userId = '00000000-0000-0000-0000-000000000000';
      
      const { error } = await supabase
        .from('email_settings')
        .upsert({
          id: settings?.id,
          host: formData.host,
          port: formData.port,
          secure: formData.secure,
          username: formData.username,
          password: formData.password,
          from_email: formData.fromEmail,
          from_name: formData.fromName,
          reply_to: formData.replyTo || null,
          updated_by: userId
        });

      if (error) throw error;

      toast.success('Email settings updated');
      onClose();
    } catch (error) {
      console.error('Error saving settings:', error);
      toast.error('Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  const handleTest = async () => {
    try {
      setTesting(true);
      
      const success = await verifyEmailSettings({
        host: formData.host,
        port: formData.port,
        secure: formData.secure,
        username: formData.username,
        password: formData.password,
        fromEmail: formData.fromEmail,
        fromName: formData.fromName,
        replyTo: formData.replyTo
      });

      if (success) {
        toast.success('Email settings verified successfully');
      } else {
        toast.error('Failed to verify email settings');
      }
    } catch (error) {
      console.error('Error testing settings:', error);
      toast.error('Failed to test settings');
    } finally {
      setTesting(false);
    }
  };

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Email Settings"
      size="lg"
      footer={
        <div className="flex justify-end gap-3">
          <Button
            variant="outline"
            icon={Settings}
            onClick={handleTest}
            loading={testing}
          >
            Test Settings
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} loading={saving}>
            Save Settings
          </Button>
        </div>
      }
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {errors.length > 0 && (
          <div className="p-4 bg-red-50 rounded-lg">
            <ul className="list-disc list-inside text-sm text-red-600">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="border-b border-gray-200 pb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">SMTP Settings</h3>
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField label="SMTP Host" required icon={Mail}>
                <input
                  type="text"
                  value={formData.host}
                  onChange={(e) => handleChange('host', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                  placeholder="smtp.example.com"
                />
              </FormField>

              <FormField label="SMTP Port" required>
                <input
                  type="number"
                  value={formData.port}
                  onChange={(e) => handleChange('port', parseInt(e.target.value))}
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                />
              </FormField>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="secure"
                checked={formData.secure}
                onChange={(e) => handleChange('secure', e.target.checked)}
                className="w-4 h-4 text-accent border-gray-300 rounded focus:ring-accent"
              />
              <label htmlFor="secure" className="ml-2 text-sm text-gray-700">
                Use SSL/TLS
              </label>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 pb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Authentication</h3>
          <div className="space-y-6">
            <FormField label="Username" required icon={User}>
              <input
                type="text"
                value={formData.username}
                onChange={(e) => handleChange('username', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="username@example.com"
              />
            </FormField>

            <FormField label="Password" required icon={Key}>
              <input
                type="password"
                value={formData.password}
                onChange={(e) => handleChange('password', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="••••••••"
              />
            </FormField>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Sender Settings</h3>
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField label="From Email" required>
                <input
                  type="email"
                  value={formData.fromEmail}
                  onChange={(e) => handleChange('fromEmail', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                  placeholder="noreply@example.com"
                />
              </FormField>

              <FormField label="From Name" required>
                <input
                  type="text"
                  value={formData.fromName}
                  onChange={(e) => handleChange('fromName', e.target.value)}
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                  placeholder="Company Name"
                />
              </FormField>
            </div>

            <FormField label="Reply-To Email">
              <input
                type="email"
                value={formData.replyTo}
                onChange={(e) => handleChange('replyTo', e.target.value)}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="support@example.com"
              />
              <p className="mt-1 text-sm text-gray-500">
                Optional. Leave blank to use From Email
              </p>
            </FormField>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default EmailSettingsEditor;