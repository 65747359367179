import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BarChart2, PieChart, LineChart, TrendingUp, Database, Cloud, Shield, Zap, Users, GitBranch, Lock, RefreshCw } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function Analytics() {
  const capabilities = [
    {
      icon: BarChart2,
      title: "Business Intelligence",
      description: "Data-driven insights"
    },
    {
      icon: PieChart,
      title: "Data Visualization",
      description: "Interactive dashboards"
    },
    {
      icon: LineChart,
      title: "Predictive Analytics",
      description: "Future trend analysis"
    },
    {
      icon: TrendingUp,
      title: "Performance Metrics",
      description: "KPI tracking"
    }
  ];

  const solutions = [
    {
      icon: BarChart2,
      title: "Business Intelligence",
      description: "Transform raw data into actionable insights",
      features: [
        "Interactive Dashboards",
        "Custom Reports",
        "Real-time Analytics",
        "Data Exploration"
      ]
    },
    {
      icon: LineChart,
      title: "Predictive Analytics",
      description: "Forecast trends and make data-driven decisions",
      features: [
        "Trend Analysis",
        "Forecasting Models",
        "Risk Assessment",
        "Market Analysis"
      ]
    },
    {
      icon: Database,
      title: "Data Management",
      description: "Comprehensive data handling and processing",
      features: [
        "Data Integration",
        "Data Cleaning",
        "ETL Processes",
        "Data Warehousing"
      ]
    },
    {
      icon: Cloud,
      title: "Cloud Analytics",
      description: "Scalable cloud-based analytics solutions",
      features: [
        "Cloud Integration",
        "Scalable Processing",
        "Real-time Analysis",
        "Secure Storage"
      ]
    }
  ];

  const features = [
    {
      icon: Shield,
      title: "Data Security",
      description: "Enterprise-grade security measures"
    },
    {
      icon: Cloud,
      title: "Cloud Integration",
      description: "Seamless cloud connectivity"
    },
    {
      icon: Users,
      title: "Collaboration",
      description: "Team-based analytics"
    },
    {
      icon: GitBranch,
      title: "Version Control",
      description: "Track changes and updates"
    },
    {
      icon: Lock,
      title: "Data Privacy",
      description: "Compliance and protection"
    },
    {
      icon: RefreshCw,
      title: "Real-time Updates",
      description: "Live data processing"
    }
  ];

  const process = [
    {
      phase: "01",
      title: "Data Collection",
      description: "Gathering and organizing data",
      steps: [
        "Source Identification",
        "Data Integration",
        "Quality Assessment",
        "Storage Planning"
      ]
    },
    {
      phase: "02",
      title: "Analysis",
      description: "Processing and analyzing data",
      steps: [
        "Data Cleaning",
        "Pattern Recognition",
        "Statistical Analysis",
        "Insight Generation"
      ]
    },
    {
      phase: "03",
      title: "Visualization",
      description: "Creating interactive visualizations",
      steps: [
        "Dashboard Design",
        "Report Creation",
        "Interactive Elements",
        "User Testing"
      ]
    },
    {
      phase: "04",
      title: "Implementation",
      description: "Deploying analytics solutions",
      steps: [
        "System Integration",
        "User Training",
        "Performance Monitoring",
        "Ongoing Support"
      ]
    }
  ];

  const caseStudies = getCaseStudiesByService('analytics');

  useEffect(() => {
    // Prevent dynamic imports of data: URLs
    window.addEventListener('error', (e) => {
      if (e.message.includes('data:text/javascript;base64')) {
        e.preventDefault();
      }
    });
  }, []);

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Analytics Solutions"
        description="Transform your data into actionable insights. Comprehensive analytics solutions including business intelligence, predictive analytics, and data visualization."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'Analytics Solutions',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'Business Analytics',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Analytics Solutions',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Business Intelligence',
                  description: 'Transform raw data into actionable insights'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Predictive Analytics',
                  description: 'Forecast trends and make data-driven decisions'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Data Management',
                  description: 'Comprehensive data handling and processing'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="Analytics Solutions"
        subtitle="Transform your data into actionable insights"
      />

      {/* Capabilities Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Analytics Capabilities</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive analytics solutions for data-driven decision making
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {capabilities.map((capability, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <capability.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-2">{capability.title}</h3>
                <p className="text-sm text-gray-600">{capability.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Solutions Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Solutions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive analytics solutions tailored to your business needs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <solution.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{solution.title}</h3>
                <p className="text-gray-600 mb-6">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Features Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Key Features</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Enterprise-grade features for reliable analytics solutions
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10 flex items-start gap-4">
                <div className="flex-shrink-0">
                  <feature.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Process Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Process</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to analytics implementation
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {process.map((phase, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{phase.phase}</div>
                <h3 className="text-xl font-semibold mb-2">{phase.title}</h3>
                <p className="text-gray-600 mb-4">{phase.description}</p>
                <ul className="space-y-2">
                  {phase.steps.map((step, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful analytics implementations"
        background="gray"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Transform Your Data?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how our analytics solutions can help you make better business decisions.
          </p>
          <Link to="/contact" className="button-secondary">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Analytics;