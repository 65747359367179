import React, { useState, useEffect } from 'react';
import { User, Mail, Shield, Key } from 'lucide-react';
import Modal from './Modal';
import FormField from './FormField';
import Button from './Button';
import { supabase } from '../../lib/supabase/client';
import toast from 'react-hot-toast';

interface UserEditorProps {
  isOpen: boolean;
  onClose: () => void;
  user?: any;
  roles: any[];
}

function UserEditor({ isOpen, onClose, user, roles }: UserEditorProps) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roleId: '',
    status: 'active',
    isTwoFactorEnabled: false
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        password: '',
        roleId: user.role_id || '',
        status: user.status || 'active',
        isTwoFactorEnabled: user.is_two_factor_enabled || false
      });
    } else {
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roleId: '',
        status: 'active',
        isTwoFactorEnabled: false
      });
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const errors = [];
    if (!formData.firstName) errors.push('First name is required');
    if (!formData.lastName) errors.push('Last name is required');
    if (!formData.email) errors.push('Email is required');
    if (!user && !formData.password) errors.push('Password is required for new users');
    if (!formData.roleId) errors.push('Role is required');

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setSaving(true);

      if (user) {
        // Update existing user
        const { error: profileError } = await supabase
          .from('profiles')
          .update({
            first_name: formData.firstName,
            last_name: formData.lastName,
            role_id: formData.roleId,
            status: formData.status,
            is_two_factor_enabled: formData.isTwoFactorEnabled
          })
          .eq('id', user.id);

        if (profileError) throw profileError;

        if (formData.password) {
          // Update password if provided
          const { error: authError } = await supabase.auth.updateUser({
            password: formData.password
          });

          if (authError) throw authError;
        }
      } else {
        // Create new user
        const { data: authData, error: authError } = await supabase.auth.signUp({
          email: formData.email,
          password: formData.password
        });

        if (authError) throw authError;

        const { error: profileError } = await supabase
          .from('profiles')
          .insert({
            id: authData.user?.id,
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            role_id: formData.roleId,
            status: formData.status,
            is_two_factor_enabled: formData.isTwoFactorEnabled
          });

        if (profileError) throw profileError;
      }

      toast.success(user ? 'User updated successfully' : 'User created successfully');
      onClose();
    } catch (error) {
      console.error('Error saving user:', error);
      toast.error('Failed to save user');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={user ? 'Edit User' : 'Create User'}
      size="lg"
      footer={
        <div className="flex justify-end gap-3">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} loading={saving}>
            {user ? 'Update User' : 'Create User'}
          </Button>
        </div>
      }
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {errors.length > 0 && (
          <div className="p-4 bg-red-50 rounded-lg">
            <ul className="list-disc list-inside text-sm text-red-600">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField label="First Name" required icon={User}>
            <input
              type="text"
              value={formData.firstName}
              onChange={(e) => handleChange('firstName', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            />
          </FormField>

          <FormField label="Last Name" required icon={User}>
            <input
              type="text"
              value={formData.lastName}
              onChange={(e) => handleChange('lastName', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            />
          </FormField>
        </div>

        <FormField label="Email" required icon={Mail}>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            disabled={!!user}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent disabled:bg-gray-50 disabled:text-gray-500"
          />
        </FormField>

        <FormField label={user ? "New Password (optional)" : "Password"} required={!user} icon={Key}>
          <input
            type="password"
            value={formData.password}
            onChange={(e) => handleChange('password', e.target.value)}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            placeholder={user ? "Leave blank to keep current password" : ""}
          />
        </FormField>

        <FormField label="Role" required icon={Shield}>
          <select
            value={formData.roleId}
            onChange={(e) => handleChange('roleId', e.target.value)}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
          >
            <option value="">Select Role</option>
            {roles.map(role => (
              <option key={role.id} value={role.id}>
                {role.name.charAt(0).toUpperCase() + role.name.slice(1)}
              </option>
            ))}
          </select>
        </FormField>

        <FormField label="Status">
          <select
            value={formData.status}
            onChange={(e) => handleChange('status', e.target.value)}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="suspended">Suspended</option>
          </select>
        </FormField>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="twoFactor"
            checked={formData.isTwoFactorEnabled}
            onChange={(e) => handleChange('isTwoFactorEnabled', e.target.checked)}
            className="w-4 h-4 text-accent border-gray-300 rounded focus:ring-accent"
          />
          <label htmlFor="twoFactor" className="ml-2 text-sm text-gray-700">
            Enable Two-Factor Authentication
          </label>
        </div>
      </form>
    </Modal>
  );
}

export default UserEditor;