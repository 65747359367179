import React from 'react';
import { Link } from 'react-router-dom';
import { Code, Smartphone, Globe, Database, Shield, Cpu, Cloud, Zap, GitBranch } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function AppDevelopment() {
  const technologies = [
    { icon: Code, label: "React", description: "Modern UI development" },
    { icon: Database, label: "Node.js", description: "Scalable backend" },
    { icon: Cloud, label: "Cloud Native", description: "AWS & Azure" },
    { icon: Shield, label: "Security", description: "Best practices" },
    { icon: GitBranch, label: "CI/CD", description: "Automated deployment" },
    { icon: Zap, label: "Performance", description: "Optimized code" }
  ];

  const services = [
    {
      icon: Globe,
      title: "Web Applications",
      description: "Custom web applications built with modern frameworks and best practices",
      features: [
        "Single Page Applications",
        "Progressive Web Apps",
        "Responsive Design",
        "Cross-browser Compatibility"
      ]
    },
    {
      icon: Smartphone,
      title: "Mobile Development",
      description: "Native and cross-platform mobile applications",
      features: [
        "iOS & Android Apps",
        "React Native",
        "Flutter Development",
        "Mobile-first Design"
      ]
    },
    {
      icon: Cpu,
      title: "Enterprise Solutions",
      description: "Scalable applications for large organizations",
      features: [
        "Custom CRM Systems",
        "ERP Integration",
        "Workflow Automation",
        "Data Analytics"
      ]
    },
    {
      icon: Shield,
      title: "API Development",
      description: "Robust and secure API solutions",
      features: [
        "RESTful APIs",
        "GraphQL",
        "Microservices",
        "API Security"
      ]
    }
  ];

  const processSteps = [
    {
      number: "01",
      title: "Discovery",
      description: "Understanding your needs through detailed consultation"
    },
    {
      number: "02",
      title: "Planning",
      description: "Creating comprehensive technical specifications"
    },
    {
      number: "03",
      title: "Development",
      description: "Agile development with regular updates"
    },
    {
      number: "04",
      title: "Testing",
      description: "Thorough QA and performance testing"
    },
    {
      number: "05",
      title: "Deployment",
      description: "Smooth deployment and monitoring"
    },
    {
      number: "06",
      title: "Support",
      description: "Ongoing maintenance and updates"
    }
  ];

  const caseStudies = getCaseStudiesByService('app-development');

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="App Development"
        description="Building modern, scalable applications for enterprise needs. Specializing in mobile apps, enterprise solutions, and custom software development."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'App Development Services',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'Application Development',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'App Development Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Mobile App Development',
                  description: 'Native and cross-platform mobile applications'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Enterprise Solutions',
                  description: 'Scalable applications for large organizations'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'API Development',
                  description: 'Robust and secure API solutions'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="App Development"
        subtitle="Custom software solutions tailored to your business needs"
      />

      {/* Technologies Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Technologies We Use</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            We leverage modern technologies and frameworks to build robust applications
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {technologies.map((tech, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <tech.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-1">{tech.label}</h3>
                <p className="text-sm text-gray-600">{tech.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Services Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive app development services for every need
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <service.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Development Process */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Development Process</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to delivering high-quality applications
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {processSteps.map((step, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{step.number}</div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful app development projects"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Build Your Application?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help bring your application idea to life.
          </p>
          <Link to="/contact" className="button-secondary">
            Start Your Project
          </Link>
        </div>
      </section>
    </div>
  );
}

export default AppDevelopment;