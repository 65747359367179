import React, { useState, useEffect } from 'react';
import { FileText, Plus, Filter, RefreshCw } from 'lucide-react';
import { Bar } from 'react-chartjs-2';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import SearchInput from '../../components/admin/SearchInput';
import FilterDropdown from '../../components/admin/FilterDropdown';
import DataTable from '../../components/admin/DataTable';
import CaseStudyEditor from '../../components/admin/CaseStudyEditor';
import { format } from 'date-fns';
import { supabase } from '../../lib/supabase/client';
import toast from 'react-hot-toast';
import { useTheme } from '../../hooks/useTheme';
import caseStudies from '../../data/caseStudies';

function CaseStudiesPage() {
  const { theme } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [industryFilter, setIndustryFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [liveCaseStudies, setLiveCaseStudies] = useState(caseStudies);
  const [syncing, setSyncing] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [selectedCaseStudy, setSelectedCaseStudy] = useState<any>(null);
  const [stats, setStats] = useState({
    byIndustry: {} as Record<string, number>,
    byService: {} as Record<string, number>
  });

  useEffect(() => {
    // Calculate stats whenever case studies change
    const byIndustry: Record<string, number> = {};
    const byService: Record<string, number> = {};

    liveCaseStudies.forEach(study => {
      byIndustry[study.industry] = (byIndustry[study.industry] || 0) + 1;
      byService[study.service] = (byService[study.service] || 0) + 1;
    });

    setStats({ byIndustry, byService });
  }, [liveCaseStudies]);

  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'published', label: 'Published' },
    { value: 'draft', label: 'Draft' },
    { value: 'archived', label: 'Archived' }
  ];

  const industryOptions = [
    { value: 'all', label: 'All Industries' },
    { value: 'renewable-energy', label: 'Renewable Energy' },
    { value: 'retail', label: 'Retail' },
    { value: 'enterprise', label: 'Enterprise' }
  ];

  const handleFetchLive = async () => {
    try {
      setSyncing(true);
      
      // For development, use the temporary admin user ID
      const userId = '00000000-0000-0000-0000-000000000000';

      // Insert or update case studies in the database
      for (const study of caseStudies) {
        const { error } = await supabase
          .from('case_studies')
          .upsert({
            id: study.id,
            title: study.title,
            subtitle: study.subtitle,
            description: study.description,
            client: study.client,
            industry: study.industry,
            service: study.service,
            challenge: study.challenge,
            solution: study.solution,
            results: study.results,
            image: study.image,
            certificate_url: study.certificateUrl,
            tags: study.tags,
            seo: study.seo,
            status: study.status,
            created_by: userId,
            updated_by: userId
          });

        if (error) throw error;
      }

      // Fetch updated case studies
      const { data, error } = await supabase
        .from('case_studies')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setLiveCaseStudies(data);
      toast.success('Case studies synced successfully');
    } catch (error) {
      console.error('Error syncing case studies:', error);
      toast.error('Failed to sync case studies');
    } finally {
      setSyncing(false);
    }
  };

  const handleEdit = (caseStudy: any) => {
    setSelectedCaseStudy(caseStudy);
    setIsEditorOpen(true);
  };

  const handleCreate = () => {
    setSelectedCaseStudy(null);
    setIsEditorOpen(true);
  };

  const handleEditorClose = () => {
    setIsEditorOpen(false);
    setSelectedCaseStudy(null);
    handleFetchLive();
  };

  const filteredCaseStudies = liveCaseStudies.filter(study => {
    const matchesSearch = study.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus = statusFilter === 'all' || study.status === statusFilter;
    const matchesIndustry = industryFilter === 'all' || study.industry === industryFilter;
    return matchesSearch && matchesStatus && matchesIndustry;
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: theme === 'dark' ? '#e5e7eb' : '#1f2937',
          font: {
            family: "'Hind', sans-serif"
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          color: theme === 'dark' ? '#e5e7eb' : '#1f2937',
          font: {
            family: "'Hind', sans-serif"
          }
        },
        grid: {
          color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        }
      },
      x: {
        ticks: {
          color: theme === 'dark' ? '#e5e7eb' : '#1f2937',
          font: {
            family: "'Hind', sans-serif"
          }
        },
        grid: {
          display: false
        }
      }
    }
  };

  return (
    <div>
      <ContentHeader
        title="Case Studies"
        description="Manage and publish case studies"
        icon={FileText}
        actions={
          <>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={handleFetchLive}
              loading={syncing}
            >
              Sync Live Cases
            </Button>
            <Button 
              icon={Plus}
              onClick={handleCreate}
            >
              Add Case Study
            </Button>
          </>
        }
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">By Industry</h3>
          <div className="h-[300px]">
            <Bar
              data={{
                labels: Object.keys(stats.byIndustry).map(industry => 
                  industryOptions.find(opt => opt.value === industry)?.label || industry
                ),
                datasets: [{
                  label: 'Case Studies',
                  data: Object.values(stats.byIndustry),
                  backgroundColor: theme === 'dark' ? '#6EFFEA' : '#0B1329',
                  borderRadius: 4
                }]
              }}
              options={chartOptions}
            />
          </div>
        </Card>

        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">By Service</h3>
          <div className="h-[300px]">
            <Bar
              data={{
                labels: Object.keys(stats.byService),
                datasets: [{
                  label: 'Case Studies',
                  data: Object.values(stats.byService),
                  backgroundColor: theme === 'dark' ? '#0B1329' : '#6EFFEA',
                  borderRadius: 4
                }]
              }}
              options={chartOptions}
            />
          </div>
        </Card>
      </div>

      <Card>
        {/* Filters */}
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search case studies..."
            className="md:w-64"
          />
          <div className="flex gap-4">
            <FilterDropdown
              label="Status"
              options={statusOptions}
              value={statusFilter}
              onChange={setStatusFilter}
            />
            <FilterDropdown
              label="Industry"
              options={industryOptions}
              value={industryFilter}
              onChange={setIndustryFilter}
            />
          </div>
        </div>

        {/* Table */}
        <DataTable
          columns={[
            { 
              header: 'Title',
              accessor: (row) => (
                <div className="flex items-center gap-4">
                  {row.image && (
                    <img 
                      src={row.image} 
                      alt={row.title}
                      className="w-12 h-12 rounded object-cover"
                    />
                  )}
                  <div>
                    <p className="font-medium text-gray-900 dark:text-white">{row.title}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {industryOptions.find(opt => opt.value === row.industry)?.label}
                    </p>
                  </div>
                </div>
              )
            },
            {
              header: 'Status',
              accessor: (row) => (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  row.status === 'published'
                    ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                    : row.status === 'draft'
                    ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400'
                    : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                }`}>
                  {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
              )
            },
            {
              header: 'Industry',
              accessor: (row) => industryOptions.find(opt => opt.value === row.industry)?.label || row.industry,
              className: 'text-gray-600 dark:text-gray-300'
            },
            {
              header: 'Last Modified',
              accessor: (row) => format(new Date(row.updated_at || row.createdAt), 'MMM d, yyyy'),
              className: 'text-right'
            },
            {
              header: 'Actions',
              accessor: (row) => (
                <div className="flex justify-end gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleEdit(row)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => window.open(row.image, '_blank')}
                  >
                    Preview
                  </Button>
                  {row.status === 'published' ? (
                    <Button
                      variant="outline"
                      size="sm"
                    >
                      Unpublish
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="sm"
                    >
                      Publish
                    </Button>
                  )}
                </div>
              )
            }
          ]}
          data={filteredCaseStudies}
          loading={loading}
        />
      </Card>

      {/* Case Study Editor Modal */}
      <CaseStudyEditor
        isOpen={isEditorOpen}
        onClose={handleEditorClose}
        caseStudy={selectedCaseStudy}
      />
    </div>
  );
}

export default CaseStudiesPage;