import React, { useState, useEffect } from 'react';
import { Shield, Plus, Edit, Trash, Check, X } from 'lucide-react';
import Modal from './Modal';
import FormField from './FormField';
import Button from './Button';
import { supabase } from '../../lib/supabase/client';
import toast from 'react-hot-toast';

interface RoleManagerProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Role {
  id: string;
  name: string;
  description: string;
  permissions: string[];
  is_system: boolean;
}

function RoleManager({ isOpen, onClose }: RoleManagerProps) {
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    permissions: [] as string[],
    is_system: false
  });

  const availablePermissions = [
    { value: 'manage_users', label: 'Manage Users' },
    { value: 'manage_case_studies', label: 'Manage Case Studies' },
    { value: 'publish_case_studies', label: 'Publish Case Studies' },
    { value: 'manage_faqs', label: 'Manage FAQs' },
    { value: 'publish_faqs', label: 'Publish FAQs' },
    { value: 'manage_email', label: 'Manage Email' },
    { value: 'publish_email_templates', label: 'Publish Email Templates' },
    { value: 'manage_settings', label: 'Manage Settings' }
  ];

  useEffect(() => {
    loadRoles();
  }, []);

  const loadRoles = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_roles')
        .select('*')
        .order('name');

      if (error) throw error;
      setRoles(data || []);
    } catch (error) {
      console.error('Error loading roles:', error);
      toast.error('Failed to load roles');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (role: Role) => {
    setSelectedRole(role);
    setFormData({
      name: role.name,
      description: role.description || '',
      permissions: role.permissions || [],
      is_system: role.is_system
    });
    setIsEditing(true);
  };

  const handleCreate = () => {
    setSelectedRole(null);
    setFormData({
      name: '',
      description: '',
      permissions: [],
      is_system: false
    });
    setIsEditing(true);
  };

  const handleDelete = async (role: Role) => {
    if (!confirm('Are you sure you want to delete this role?')) return;
    if (role.is_system) {
      toast.error('Cannot delete system roles');
      return;
    }

    try {
      const { error } = await supabase
        .from('user_roles')
        .delete()
        .eq('id', role.id);

      if (error) throw error;
      toast.success('Role deleted successfully');
      loadRoles();
    } catch (error) {
      console.error('Error deleting role:', error);
      toast.error('Failed to delete role');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.name) {
      toast.error('Role name is required');
      return;
    }

    try {
      const { error } = await supabase
        .from('user_roles')
        .upsert({
          id: selectedRole?.id,
          name: formData.name,
          description: formData.description,
          permissions: formData.permissions,
          is_system: formData.is_system
        });

      if (error) throw error;

      toast.success(selectedRole ? 'Role updated' : 'Role created');
      setIsEditing(false);
      loadRoles();
    } catch (error) {
      console.error('Error saving role:', error);
      toast.error('Failed to save role');
    }
  };

  const handlePermissionToggle = (permission: string) => {
    setFormData(prev => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission]
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Role Management"
      size="xl"
    >
      <div className="space-y-6">
        {!isEditing ? (
          <>
            <div className="flex justify-end">
              <Button
                icon={Plus}
                onClick={handleCreate}
              >
                Create Role
              </Button>
            </div>

            <div className="space-y-4">
              {roles.map(role => (
                <div
                  key={role.id}
                  className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg"
                >
                  <div>
                    <h3 className="font-medium text-gray-900">
                      {role.name}
                      {role.is_system && (
                        <span className="ml-2 text-xs text-accent">System Role</span>
                      )}
                    </h3>
                    {role.description && (
                      <p className="text-sm text-gray-500 mt-1">{role.description}</p>
                    )}
                    <div className="flex flex-wrap gap-2 mt-2">
                      {role.permissions.map(permission => (
                        <span
                          key={permission}
                          className="inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-primary/10 text-primary"
                        >
                          {permission}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      icon={Edit}
                      onClick={() => handleEdit(role)}
                    >
                      Edit
                    </Button>
                    {!role.is_system && (
                      <Button
                        variant="outline"
                        size="sm"
                        icon={Trash}
                        onClick={() => handleDelete(role)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <FormField label="Role Name" required icon={Shield}>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                placeholder="Enter role name"
              />
            </FormField>

            <FormField label="Description">
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                rows={3}
                placeholder="Enter role description"
              />
            </FormField>

            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-3">Permissions</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {availablePermissions.map(permission => (
                  <label
                    key={permission.value}
                    className={`flex items-center gap-3 p-3 rounded-lg border transition-colors cursor-pointer ${
                      formData.permissions.includes(permission.value)
                        ? 'bg-primary/5 border-primary'
                        : 'bg-white border-gray-200 hover:bg-gray-50'
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={formData.permissions.includes(permission.value)}
                      onChange={() => handlePermissionToggle(permission.value)}
                      className="w-4 h-4 text-accent border-gray-300 rounded focus:ring-accent"
                    />
                    <span className="text-sm font-medium text-gray-900">
                      {permission.label}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <Button
                variant="outline"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Button type="submit">
                {selectedRole ? 'Update Role' : 'Create Role'}
              </Button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}

export default RoleManager;