import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Cloud, Cpu, Code, Database, LineChart, Globe } from 'lucide-react';
import ServiceCard from '../components/ServiceCard';
import { ProcessSkeleton } from '../components/Skeleton';
import SEO from '../components/SEO';

function Services() {
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState({
    process: false,
    services: false,
    cta: false
  });

  const sectionsRef = useRef<{ [key: string]: HTMLDivElement | null }>({
    process: null,
    services: null,
    cta: null
  });

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.getAttribute('data-section');
          if (sectionId) {
            setIsVisible(prev => ({
              ...prev,
              [sectionId]: entry.isIntersecting
            }));
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    // Observe all sections
    Object.keys(sectionsRef.current).forEach(sectionId => {
      const element = sectionsRef.current[sectionId];
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [loading]); // Re-run when loading state changes

  const processes = [
    {
      step: "01",
      title: "Discovery",
      description: "Understanding your needs and objectives through detailed consultation"
    },
    {
      step: "02",
      title: "Planning",
      description: "Developing comprehensive strategy and solution architecture"
    },
    {
      step: "03",
      title: "Implementation",
      description: "Executing the solution with precision and expertise"
    },
    {
      step: "04",
      title: "Support",
      description: "Ongoing maintenance and optimization of your systems"
    }
  ];

  const services = [
    {
      icon: Shield,
      title: "IT Consultancy",
      description: "Expert guidance and support for technology initiatives",
      features: [
        "24/7 IT Support",
        "Cybersecurity",
        "Network Solutions",
        "Cloud Services"
      ],
      link: "/services/it-consultancy"
    },
    {
      icon: Database,
      title: "Infrastructure",
      description: "Robust and scalable technology infrastructure solutions",
      features: [
        "Data Center Solutions",
        "Network Architecture",
        "Storage Solutions",
        "Backup & Recovery"
      ],
      link: "/services/infrastructure"
    },
    {
      icon: Cloud,
      title: "Digital Transformation",
      description: "Comprehensive digital evolution for modern businesses",
      features: [
        "Cloud Migration",
        "AI & Automation",
        "Business Intelligence",
        "Process Optimization"
      ],
      link: "/services/digital-transformation"
    },
    {
      icon: Code,
      title: "App Development",
      description: "Building modern, scalable applications for enterprise needs",
      features: [
        "Custom Web Applications",
        "Mobile App Development",
        "SaaS Solutions",
        "Enterprise Applications"
      ],
      link: "/services/app-development"
    },
    {
      icon: Cpu,
      title: "AI Solutions",
      description: "Cutting-edge artificial intelligence implementation",
      features: [
        "Machine Learning",
        "Process Automation",
        "Predictive Analytics",
        "AI Integration"
      ],
      link: "/services/ai-solutions"
    },
    {
      icon: Globe,
      title: "Web Development",
      description: "Modern web solutions for your digital presence",
      features: [
        "Responsive Design",
        "Custom Web Applications",
        "E-commerce Solutions",
        "Progressive Web Apps"
      ],
      link: "/services/web-development"
    },
    {
      icon: LineChart,
      title: "Analytics",
      description: "Data-driven insights for informed decision making",
      features: [
        "Data Analytics",
        "Business Intelligence",
        "Performance Metrics",
        "Custom Reporting"
      ],
      link: "/services/analytics"
    }
  ];

  if (loading) {
    return (
      <div className="pt-20 bg-white">
        <section className="bg-primary py-12 md:py-16">
          <div className="max-w-5xl mx-auto px-4">
            <div className="text-center">
              <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">Our Services</h1>
              <p className="text-lg text-gray-300 max-w-3xl mx-auto">
                Comprehensive technology solutions tailored for renewable energy and enterprise transformation
              </p>
            </div>
          </div>
        </section>

        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 text-primary">How We Work</h2>
            </div>
            <ProcessSkeleton />
          </div>
        </section>

        <section className="py-16 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 text-primary">What We Offer</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <ProcessSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Services"
        description="Comprehensive technology solutions from IT infrastructure to AI-powered automation. Explore our range of services tailored for enterprise and renewable energy sectors."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          name: 'Services - Senses Innovations',
          description: 'Technology services offered by Senses Innovations',
          itemListElement: services.map((service, index) => ({
            '@type': 'Service',
            position: index + 1,
            name: service.title,
            description: service.description,
            provider: {
              '@type': 'Organization',
              name: 'Senses Innovations'
            },
            serviceType: service.title,
            areaServed: ['US', 'SA', 'AE', 'EG'],
            hasOfferCatalog: {
              '@type': 'OfferCatalog',
              name: service.title,
              itemListElement: service.features.map((feature, i) => ({
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: feature
                }
              }))
            }
          })),
          breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': 'https://sensesinnovations.com',
                  name: 'Home'
                }
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': 'https://sensesinnovations.com/services',
                  name: 'Services'
                }
              }
            ]
          }
        }}
      />
      {/* Header Section */}
      <section className="bg-primary py-12 md:py-16">
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4 opacity-0 animate-[fadeUp_0.8s_ease-out_forwards]">
            Our Services
          </h1>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto text-center opacity-0 animate-[fadeUp_0.8s_ease-out_0.2s_forwards]">
            Comprehensive technology solutions tailored for renewable energy and enterprise transformation
          </p>
        </div>
      </section>

      {/* Process Section */}
      <section 
        ref={(el) => { sectionsRef.current.process = el; }}
        data-section="process"
        className="py-16 bg-white"
      >
        <div className="max-w-7xl mx-auto px-4">
          <h2 className={`text-2xl md:text-3xl font-bold text-center mb-6 text-primary transition-all duration-700 transform ${
            isVisible.process ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}>
            How We Work
          </h2>
          <p className={`text-gray-600 text-center max-w-2xl mx-auto mb-12 transition-all duration-700 transform ${
            isVisible.process ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}>
            Our proven methodology ensures successful project delivery and long-term value for our clients
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {processes.map((process, index) => (
              <div 
                key={index} 
                className={`process-card opacity-0 transition-all duration-700 transform ${
                  isVisible.process ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <div className="card group h-full">
                  <div className="card-gradient" />
                  <div className="relative z-10">
                    <div className="text-4xl font-bold text-accent mb-4">{process.step}</div>
                    <h3 className="text-xl font-semibold mb-2 text-primary">{process.title}</h3>
                    <p className="text-gray-600">{process.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Grid */}
      <section 
        ref={(el) => { sectionsRef.current.services = el; }}
        data-section="services"
        className="py-16 bg-gray-50"
      >
        <div className="max-w-7xl mx-auto px-4">
          <h2 className={`text-2xl md:text-3xl font-bold text-center mb-6 text-primary transition-all duration-700 transform ${
            isVisible.services ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}>
            What We Offer
          </h2>
          <p className={`text-gray-600 text-center max-w-2xl mx-auto mb-12 transition-all duration-700 transform ${
            isVisible.services ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}>
            Explore our comprehensive range of technology solutions designed to drive your business forward
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {services.map((service, index) => (
              <div 
                key={index}
                className={`opacity-0 translate-y-4 transition-all duration-700 transform ${
                  isVisible.services ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <ServiceCard
                  icon={service.icon}
                  title={service.title}
                  description={service.description}
                  features={service.features}
                  link={service.link}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section 
        ref={(el) => { sectionsRef.current.cta = el; }}
        data-section="cta"
        className="py-16 bg-primary relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-accent/10 to-transparent opacity-50" />
        <div className={`max-w-4xl mx-auto text-center px-4 relative z-10 transition-all duration-700 transform ${
          isVisible.cta ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
        }`}>
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-gray-100 mb-8 text-lg">
            Contact us to discuss how we can help transform your business with our technology solutions.
          </p>
          <Link 
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-accent text-primary rounded-xl text-lg font-semibold transition-all duration-300 hover:shadow-lg hover:-translate-y-1 hover:shadow-accent/20"
          >
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Services;