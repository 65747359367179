import React, { useRef, useState, useEffect } from 'react';
import { FileText, X } from 'lucide-react';
import Button from './Button';

interface CertificateUploadProps {
  value?: string;
  onChange: (file: File) => void;
  onError?: (error: string) => void;
}

function CertificateUpload({ value, onChange, onError }: CertificateUploadProps) {
  const [preview, setPreview] = useState<string | undefined>(value);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Update preview when value changes
  useEffect(() => {
    setPreview(value);
  }, [value]);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (file.type !== 'application/pdf') {
      onError?.('Please select a PDF file');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      onError?.('Certificate size should not exceed 5MB');
      return;
    }

    // Set preview (just show the file name for PDFs)
    setPreview(file.name);

    // Trigger upload
    onChange(file);
  };

  const handleClear = () => {
    setPreview(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-4">
      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf"
        onChange={handleFileSelect}
        className="hidden"
      />

      {preview ? (
        <div className="relative flex items-center gap-3 p-4 bg-gray-50 rounded-lg">
          <FileText className="w-6 h-6 text-primary" />
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {preview.split('/').pop()}
            </p>
            {value && (
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-accent hover:underline"
              >
                View Certificate
              </a>
            )}
          </div>
          <button
            onClick={handleClear}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      ) : (
        <Button
          variant="outline"
          icon={FileText}
          onClick={() => fileInputRef.current?.click()}
          loading={loading}
          className="w-full"
        >
          Upload Performance Certificate
        </Button>
      )}

      <p className="text-xs text-gray-500">
        Upload a PDF file (max 5MB)
      </p>
    </div>
  );
}

export default CertificateUpload;