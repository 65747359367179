import React from 'react';
import { Link } from 'react-router-dom';
import { Network, Wifi, Server, Tent, Shield, Clock, ArrowRight, Zap, Settings, Users } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';

function RenewableInfrastructure() {
  const solutions = [
    {
      icon: Network,
      title: "Backhaul Networks",
      description: "High-performance network infrastructure for remote renewable energy sites",
      features: [
        "Fiber optic connectivity",
        "Microwave links",
        "Redundant connections",
        "Network monitoring"
      ]
    },
    {
      icon: Tent,
      title: "Site Camps & Mobilization",
      description: "Complete IT infrastructure for temporary site camps and mobilization support",
      features: [
        "Rapid deployment",
        "Temporary office setup",
        "Communication systems",
        "Security infrastructure"
      ]
    },
    {
      icon: Server,
      title: "Network Hardware",
      description: "Enterprise-grade networking equipment for reliable operations",
      features: [
        "Industrial switches",
        "Ruggedized routers",
        "UPS systems",
        "Environmental monitoring"
      ]
    },
    {
      icon: Wifi,
      title: "Wireless Connectivity",
      description: "Comprehensive wireless solutions for site-wide coverage",
      features: [
        "WiFi networks",
        "Point-to-point links",
        "Mesh networks",
        "Mobile connectivity"
      ]
    }
  ];

  const features = [
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "End-to-end security measures"
    },
    {
      icon: Clock,
      title: "Rapid Deployment",
      description: "Fast mobilization capabilities"
    },
    {
      icon: Zap,
      title: "High Performance",
      description: "Optimized for reliability"
    },
    {
      icon: Settings,
      title: "Remote Management",
      description: "Centralized control systems"
    },
    {
      icon: Network,
      title: "Redundancy",
      description: "Failover configurations"
    },
    {
      icon: Users,
      title: "24/7 Support",
      description: "Round-the-clock assistance"
    }
  ];

  const caseStudies = [
    {
      title: "Ras Ghareb Wind Energy",
      subtitle: "262.5MW Wind Farm",
      description: "Complete network infrastructure deployment with redundant connectivity",
      image: "https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?auto=format&fit=crop&q=80",
      achievements: [
        "3-month deployment timeline",
        "99.9% network uptime",
        "24/7 monitoring system",
        "Full site coverage"
      ]
    },
    {
      title: "Red Sea Wind Energy",
      subtitle: "500MW Wind Farm",
      description: "Advanced wireless infrastructure with comprehensive site coverage",
      image: "https://images.unsplash.com/photo-1466611653911-95081537e5b7?auto=format&fit=crop&q=80",
      achievements: [
        "100% site coverage",
        "Redundant backhaul links",
        "Remote management",
        "Secure access control"
      ]
    },
    {
      title: "Amunet Wind Energy",
      subtitle: "500MW Wind Farm",
      description: "State-of-the-art security and monitoring infrastructure",
      image: "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?auto=format&fit=crop&q=80",
      achievements: [
        "Advanced security systems",
        "Remote monitoring",
        "SCADA integration",
        "Rapid deployment"
      ]
    }
  ];

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Renewable Energy Solutions"
        description="Comprehensive IT and network solutions for renewable energy projects. Specializing in wind farm infrastructure, solar energy solutions, and remote site connectivity."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'Renewable Energy IT Solutions',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'IT Infrastructure',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Renewable Energy Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Backhaul Networks',
                  description: 'High-performance network infrastructure for remote renewable energy sites'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Site Camps & Mobilization',
                  description: 'Complete IT infrastructure for temporary site camps'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="Renewable Energy Infrastructure"
        subtitle="Comprehensive IT and network solutions for renewable energy projects"
      />

      {/* Solutions Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Solutions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            End-to-end infrastructure solutions for renewable energy projects
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <solution.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{solution.title}</h3>
                <p className="text-gray-600 mb-6">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Features Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Key Features</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Enterprise-grade features for reliable infrastructure
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10 flex items-start gap-4">
                <div className="flex-shrink-0">
                  <feature.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Success Stories</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Discover how we've helped leading renewable energy projects
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {caseStudies.map((study, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div 
                  className="h-48 rounded-xl bg-cover bg-center mb-6 transition-transform duration-300 group-hover:scale-[1.02]"
                  style={{ backgroundImage: `url(${study.image})` }}
                />
                <div className="space-y-4">
                  <div>
                    <h3 className="text-xl font-semibold text-primary">{study.title}</h3>
                    <p className="text-accent">{study.subtitle}</p>
                  </div>
                  <p className="text-gray-600">{study.description}</p>
                  <ul className="space-y-2">
                    {study.achievements.map((achievement, idx) => (
                      <li key={idx} className="text-gray-600 flex items-center">
                        <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                        {achievement}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link 
            to="/renewable-case-studies"
            className="inline-flex items-center gap-2 text-primary hover:text-accent transition-colors duration-300"
          >
            <span>View All Case Studies</span>
            <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
      </Section>

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Build Your Infrastructure?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help create a robust infrastructure solution for your renewable energy project.
          </p>
          <Link to="/contact" className="button-secondary">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
}

export default RenewableInfrastructure;