import React from 'react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';

function Terms() {
  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Terms & Conditions"
        description="Read our terms and conditions to understand the rules and guidelines for using Senses Innovations services."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Terms & Conditions - Senses Innovations',
          description: 'Terms and conditions for using our services',
          publisher: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          }
        }}
      />
      <PageHeader
        title="Terms & Conditions"
        subtitle="Last updated: February 27, 2025"
      />

      <Section>
        <div className="prose prose-lg max-w-4xl mx-auto">
          <h2>1. Agreement to Terms</h2>
          <p>
            These Terms and Conditions constitute a legally binding agreement made between you and Senses Innovations. By accessing or using our website and services, you agree to be bound by these Terms.
          </p>

          <h2>2. Intellectual Property Rights</h2>
          <p>
            The Service and its original content, features, and functionality are owned by Senses Innovations and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
          </p>

          <h2>3. User Representations</h2>
          <p>By using the Service, you represent and warrant that:</p>
          <ul>
            <li>You have the legal capacity to accept these Terms</li>
            <li>You are not a minor in the jurisdiction in which you reside</li>
            <li>You will not access the Service through automated or non-human means</li>
            <li>You will not use the Service for any illegal or unauthorized purpose</li>
            <li>Your use of the Service will not violate any applicable law or regulation</li>
          </ul>

          <h2>4. Prohibited Activities</h2>
          <p>You may not access or use the Service for any purpose other than that for which we make it available. Prohibited activities include:</p>
          <ul>
            <li>Systematic retrieval of data to create a collection or database</li>
            <li>Making unauthorized copies of any content from the Service</li>
            <li>Attempting to impair or damage the Service</li>
            <li>Using the Service to advertise or sell goods and services</li>
            <li>Circumventing, disabling, or interfering with security features</li>
          </ul>

          <h2>5. Service Modifications</h2>
          <p>
            We reserve the right to modify or discontinue, temporarily or permanently, the Service with or without notice. You agree that we will not be liable to you or any third party for any modification, suspension, or discontinuance of the Service.
          </p>

          <h2>6. Termination</h2>
          <p>
            We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
          </p>

          <h2>7. Limitation of Liability</h2>
          <p>
            In no event shall Senses Innovations, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
          </p>

          <h2>8. Disclaimer</h2>
          <p>
            Your use of the Service is at your sole risk. The Service is provided "AS IS" and "AS AVAILABLE" without warranties of any kind, whether express or implied.
          </p>

          <h2>9. Governing Law</h2>
          <p>
            These Terms shall be governed by and defined following the laws of the United States. Senses Innovations and yourself irrevocably consent that the courts of Texas shall have exclusive jurisdiction to resolve any dispute.
          </p>

          <h2>10. Changes to Terms</h2>
          <p>
            We reserve the right to modify these terms at any time. We will notify users of any changes by updating the "Last updated" date of these terms.
          </p>

          <h2>11. Contact Information</h2>
          <p>Questions about the Terms should be sent to us at:</p>
          <ul>
            <li>Email: legal@sensesinnovations.com</li>
            <li>Address: 5900 Balcones Dr. STE 100, Austin, TX 78731, United States</li>
          </ul>
        </div>
      </Section>
    </div>
  );
}

export default Terms;