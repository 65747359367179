import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';

interface DashboardCardProps {
  title: string;
  value: string | number;
  icon: LucideIcon;
  description?: string;
  trend?: {
    value: number;
    label: string;
    isPositive: boolean;
  };
}

function DashboardCard({ title, value, icon: Icon, description, trend }: DashboardCardProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200/80 dark:border-gray-700/80 p-6 shadow-sm hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-[1.02] hover:-translate-y-1 group">
      <div className="flex items-start justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
          <p className="mt-2 text-3xl font-semibold text-gray-900 dark:text-white transition-colors duration-300 group-hover:text-primary dark:group-hover:text-accent">{value}</p>
        </div>
        <div className="p-3 bg-primary/5 dark:bg-accent/10 rounded-lg transition-all duration-300 group-hover:bg-primary/10 dark:group-hover:bg-accent/20">
          <Icon className="w-6 h-6 text-primary dark:text-accent transition-colors duration-300 group-hover:text-accent dark:group-hover:text-primary" />
        </div>
      </div>
      
      {(description || trend) && (
        <div className="mt-4 flex items-center justify-between">
          {description && (
            <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
          )}
          {trend && (
            <div className={`flex items-center text-sm ${
              trend.isPositive 
                ? 'text-green-600 dark:text-green-400' 
                : 'text-red-600 dark:text-red-400'
            }`}>
              <span className="font-medium">{trend.value}%</span>
              <span className="ml-1">{trend.label}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardCard;