import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Clock, Database, Award, Network, Lock, Cloud, MapPin } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import GlobalReachMap from '../components/GlobalReachMap';
import { StatsSkeleton, ProcessSkeleton, MapSkeleton } from '../components/Skeleton';

function About() {
  const [loading, setLoading] = useState(true);
  const [activeRegion, setActiveRegion] = useState<string | undefined>();
  const [isVisible, setIsVisible] = useState({
    mission: false,
    features: false,
    values: false,
    global: false,
    cta: false
  });

  const sectionsRef = useRef<{ [key: string]: HTMLDivElement | null }>({
    mission: null,
    features: null,
    values: null,
    global: null,
    cta: null
  });

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.getAttribute('data-section');
          if (sectionId) {
            setIsVisible(prev => ({
              ...prev,
              [sectionId]: entry.isIntersecting
            }));
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    // Observe all sections
    Object.keys(sectionsRef.current).forEach(sectionId => {
      const element = sectionsRef.current[sectionId];
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [loading]); // Re-run when loading state changes

  if (loading) {
    return (
      <div className="pt-20 bg-white">
        <PageHeader
          title="Who We Are"
          subtitle="Senses Innovations is a leading technology and digital transformation partner specializing in renewable energy IT infrastructure, enterprise digital solutions, and AI-powered automation."
        />

        <section className="py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div className="space-y-8">
                <StatsSkeleton />
              </div>
              <div className="relative">
                <ProcessSkeleton />
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Why Choose Us</h2>
            </div>
            <ProcessSkeleton />
          </div>
        </section>

        <section className="py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Global Reach</h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
              <div className="lg:col-span-5">
                <ProcessSkeleton />
              </div>
              <div className="lg:col-span-7">
                <MapSkeleton />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  const regions = [
    {
      name: "Middle East",
      description: "Comprehensive coverage across GCC countries"
    },
    {
      name: "North Africa",
      description: "Strong presence in renewable energy sector"
    },
    {
      name: "Europe",
      description: "Strategic partnerships and operations"
    },
    {
      name: "North America",
      description: "Advanced technology solutions"
    }
  ];

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="About Us"
        description="Leading technology and digital transformation partner with global presence. Learn about our mission, values, and commitment to delivering innovative IT solutions worldwide."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'AboutPage',
          mainEntity: {
            '@type': 'Organization',
            name: 'Senses Innovations',
            description: 'Technology solutions provider specializing in renewable energy infrastructure',
            foundingLocation: {
              '@type': 'Place',
              address: {
                '@type': 'PostalAddress',
                addressLocality: 'Austin',
                addressRegion: 'TX',
                addressCountry: 'US'
              }
            },
            areaServed: ['US', 'SA', 'AE', 'EG'],
            knowsAbout: [
              'Renewable Energy Infrastructure',
              'Digital Transformation',
              'IT Consultancy',
              'Enterprise Solutions'
            ],
            hasOfferCatalog: {
              '@type': 'OfferCatalog',
              name: 'Services',
              itemListElement: [
                {
                  '@type': 'Offer',
                  itemOffered: {
                    '@type': 'Service',
                    name: 'IT Infrastructure',
                    description: 'Enterprise-grade IT infrastructure solutions'
                  }
                },
                {
                  '@type': 'Offer',
                  itemOffered: {
                    '@type': 'Service',
                    name: 'Digital Transformation',
                    description: 'Comprehensive digital evolution services'
                  }
                }
              ]
            }
          }
        }}
      />
      <PageHeader
        title="Who We Are"
        subtitle="Senses Innovations is a leading technology and digital transformation partner specializing in renewable energy IT infrastructure, enterprise digital solutions, and AI-powered automation."
      />

      <Section>
        <div 
          ref={(el) => { sectionsRef.current.mission = el; }}
          data-section="mission"
          className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 items-center"
        >
          <div className={`space-y-8 transition-all duration-700 transform ${
            isVisible.mission ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}>
            <div className="inline-block">
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-primary relative">
                Our Mission
                <span className="absolute -bottom-2 left-0 w-1/3 h-1 bg-accent"></span>
              </h2>
            </div>
            <div className="space-y-6">
              <p className="text-base md:text-lg text-gray-600 leading-relaxed">
                To empower organizations with cutting-edge technology solutions that drive sustainable growth and digital transformation. We're committed to delivering innovative IT infrastructure that enables our clients to thrive in an increasingly connected world.
              </p>
              <p className="text-base md:text-lg text-gray-600 leading-relaxed">
                Our focus on renewable energy and enterprise solutions reflects our dedication to supporting sustainable development while ensuring businesses remain competitive in the digital age.
              </p>
            </div>
            <div className="flex gap-4">
              {[
                { label: "Innovation", delay: "0" },
                { label: "Sustainability", delay: "100" },
                { label: "Excellence", delay: "200" }
              ].map((value, index) => (
                <div
                  key={index}
                  className={`flex items-center space-x-2 py-2 px-4 bg-gray-50 rounded-lg transition-all duration-700 transform ${
                    isVisible.mission 
                      ? 'opacity-100 translate-y-0' 
                      : 'opacity-0 translate-y-8'
                  }`}
                  style={{ transitionDelay: `${parseInt(value.delay) + 200}ms` }}
                >
                  <div className="w-2 h-2 bg-accent rounded-full"></div>
                  <span className="text-sm md:text-base text-gray-600 font-medium">{value.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={`relative transition-all duration-700 transform ${
            isVisible.mission ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-8'
          }`}>
            <div className="relative h-[300px] md:h-[400px] rounded-xl overflow-hidden group">
              <div className="absolute inset-0 bg-primary/10 group-hover:bg-transparent transition-colors duration-300"></div>
              <img 
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80" 
                alt="Team collaboration" 
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-primary/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </div>
            <div className="absolute -bottom-4 -right-4 w-16 md:w-24 h-16 md:h-24 bg-accent/10 rounded-xl"></div>
            <div className="absolute -top-4 -left-4 w-16 md:w-24 h-16 md:h-24 bg-accent/10 rounded-xl"></div>
          </div>
        </div>
      </Section>

      <Section>
        <div 
          ref={(el) => { sectionsRef.current.features = el; }}
          data-section="features"
        >
          <div className="text-center mb-12">
            <h2 className={`text-2xl md:text-3xl font-bold text-primary mb-4 transition-all duration-700 transform ${
              isVisible.features ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Why Choose Us
            </h2>
            <p className={`text-lg text-gray-600 max-w-2xl mx-auto transition-all duration-700 transform ${
              isVisible.features ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Experience the difference of working with a dedicated technology partner
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              {
                icon: <Award className="w-6 h-6" />,
                title: "Industry Expertise",
                description: "Deep knowledge across multiple sectors"
              },
              {
                icon: <Network className="w-6 h-6" />,
                title: "Global Reach",
                description: "Supporting clients worldwide"
              },
              {
                icon: <Lock className="w-6 h-6" />,
                title: "Secure Solutions",
                description: "Top-tier security standards"
              },
              {
                icon: <Cloud className="w-6 h-6" />,
                title: "Cloud Native",
                description: "Modern cloud architecture"
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className={`card group transition-all duration-700 transform ${
                  isVisible.features 
                    ? 'opacity-100 translate-y-0' 
                    : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <div className="card-gradient" />
                <div className="relative z-10">
                  <div className="text-primary mb-4 transform transition-all duration-300 group-hover:scale-110 group-hover:text-accent">
                    {feature.icon}
                  </div>
                  <h3 className="text-lg font-semibold mb-2 text-primary">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>

      <Section className="bg-gray-50">
        <div 
          ref={(el) => { sectionsRef.current.values = el; }}
          data-section="values"
        >
          <div className="text-center mb-12">
            <h2 className={`text-3xl md:text-4xl font-bold text-primary mb-4 transition-all duration-700 transform ${
              isVisible.values ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Our Values
            </h2>
            <p className={`text-lg text-gray-600 max-w-2xl mx-auto transition-all duration-700 transform ${
              isVisible.values ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Guided by strong principles that shape our approach to delivering excellence
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <Shield className="w-8 h-8" />,
                title: "Security First",
                description: "Implementing robust security measures in every solution"
              },
              {
                icon: <Clock className="w-8 h-8" />,
                title: "Rapid Deployment",
                description: "Swift implementation without compromising quality"
              },
              {
                icon: <Database className="w-8 h-8" />,
                title: "Scalable Solutions",
                description: "Building systems that grow with your business"
              }
            ].map((value, index) => (
              <div 
                key={index} 
                className={`card group transition-all duration-700 transform ${
                  isVisible.values 
                    ? 'opacity-100 translate-y-0' 
                    : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <div className="card-gradient" />
                <div className="relative z-10">
                  <div className="text-primary mb-4 transform transition-all duration-300 group-hover:scale-110 group-hover:text-accent">
                    {value.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-3 text-primary">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>

      <Section>
        <div 
          ref={(el) => { sectionsRef.current.global = el; }}
          data-section="global"
        >
          <div className="text-center mb-12">
            <h2 className={`text-2xl md:text-3xl lg:text-4xl font-bold text-primary mb-4 transition-all duration-700 transform ${
              isVisible.global ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Global Reach
            </h2>
            <p className={`text-base md:text-lg text-gray-600 max-w-2xl mx-auto transition-all duration-700 transform ${
              isVisible.global ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Delivering excellence across key regions with comprehensive service coverage
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className={`lg:col-span-5 space-y-6 transition-all duration-700 transform ${
              isVisible.global ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-8'
            }`}>
              <div className="grid grid-cols-2 gap-4 md:gap-6">
                {regions.map((region, index) => (
                  <div 
                    key={index}
                    className="card group cursor-pointer h-full"
                    onMouseEnter={() => setActiveRegion(region.name)}
                    onMouseLeave={() => setActiveRegion(undefined)}
                  >
                    <div className="card-gradient" />
                    <div className="relative z-10">
                      <h3 className="text-base md:text-xl font-semibold text-primary mb-2">{region.name}</h3>
                      <p className="text-xs md:text-sm lg:text-base text-gray-600">{region.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="card group">
                <div className="card-gradient" />
                <div className="relative z-10">
                  <h3 className="text-lg md:text-xl font-semibold text-primary mb-4">Global Coverage Highlights</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-accent/10 flex items-center justify-center flex-shrink-0 mt-1">
                        <Shield className="w-4 h-4 text-accent" />
                      </div>
                      <p className="text-sm md:text-base text-gray-600">24/7 global support coverage with dedicated regional teams</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-accent/10 flex items-center justify-center flex-shrink-0 mt-1">
                        <Network className="w-4 h-4 text-accent" />
                      </div>
                      <p className="text-sm md:text-base text-gray-600">Strategically located resources ensuring optimal service delivery</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-accent/10 flex items-center justify-center flex-shrink-0 mt-1">
                        <Clock className="w-4 h-4 text-accent" />
                      </div>
                      <p className="text-sm md:text-base text-gray-600">Rapid response times with local presence in key markets</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className={`lg:col-span-7 transition-all duration-700 transform ${
              isVisible.global ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-8'
            }`}>
              <div className="hidden lg:block">
                <GlobalReachMap activeRegion={activeRegion} />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section 
        ref={(el) => { sectionsRef.current.cta = el; }}
        data-section="cta"
        className="py-16 bg-primary"
      >
        <div className={`max-w-4xl mx-auto text-center px-4 transition-all duration-700 transform ${
          isVisible.cta ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
        }`}>
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Get Started?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help transform your business with our technology solutions.
          </p>
          <Link to="/contact" className="button-secondary">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
}

export default About;