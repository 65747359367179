import React from 'react';
import { Link } from 'react-router-dom';
import { Globe, Code, Layout, Smartphone, Database, Shield, Cloud, Zap, Users, LineChart, Settings, RefreshCw } from 'lucide-react';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import CaseStudiesSection from '../components/CaseStudiesSection';
import { getCaseStudiesByService } from '../data/caseStudies';
import RelatedFAQs from '../components/RelatedFAQs';

function WebDevelopment() {
  const technologies = [
    { icon: Code, label: "React", description: "Modern UI development" },
    { icon: Layout, label: "Next.js", description: "Server-side rendering" },
    { icon: Database, label: "Node.js", description: "Backend development" },
    { icon: Cloud, label: "Cloud Native", description: "Scalable hosting" },
    { icon: Shield, label: "Security", description: "Best practices" },
    { icon: Zap, label: "Performance", description: "Optimized code" }
  ];

  const services = [
    {
      icon: Globe,
      title: "Corporate Websites",
      description: "Professional websites that represent your brand",
      features: [
        "Responsive Design",
        "Content Management",
        "SEO Optimization",
        "Performance Metrics"
      ]
    },
    {
      icon: Layout,
      title: "Web Applications",
      description: "Custom web applications for your business needs",
      features: [
        "User Authentication",
        "Real-time Features",
        "Data Management",
        "API Integration"
      ]
    },
    {
      icon: Smartphone,
      title: "Progressive Web Apps",
      description: "Mobile-first web applications with offline capabilities",
      features: [
        "Offline Support",
        "Push Notifications",
        "App-like Experience",
        "Cross-platform"
      ]
    },
    {
      icon: Database,
      title: "E-commerce Solutions",
      description: "Complete online shopping platforms",
      features: [
        "Product Management",
        "Payment Integration",
        "Inventory System",
        "Order Processing"
      ]
    }
  ];

  const features = [
    {
      icon: Shield,
      title: "Security First",
      description: "Built-in security measures"
    },
    {
      icon: Zap,
      title: "Performance",
      description: "Optimized for speed"
    },
    {
      icon: Cloud,
      title: "Scalability",
      description: "Growth-ready architecture"
    },
    {
      icon: Users,
      title: "User Experience",
      description: "Intuitive interfaces"
    },
    {
      icon: LineChart,
      title: "Analytics",
      description: "Built-in tracking"
    },
    {
      icon: Settings,
      title: "Maintenance",
      description: "Regular updates"
    }
  ];

  const process = [
    {
      phase: "01",
      title: "Discovery",
      description: "Understanding your needs",
      steps: [
        "Requirements Gathering",
        "User Research",
        "Technical Planning",
        "Project Scope"
      ]
    },
    {
      phase: "02",
      title: "Design",
      description: "Creating the perfect look",
      steps: [
        "UI/UX Design",
        "Wireframing",
        "Prototyping",
        "Design Review"
      ]
    },
    {
      phase: "03",
      title: "Development",
      description: "Building your solution",
      steps: [
        "Frontend Development",
        "Backend Integration",
        "Testing",
        "Quality Assurance"
      ]
    },
    {
      phase: "04",
      title: "Deployment",
      description: "Going live and beyond",
      steps: [
        "Performance Testing",
        "Security Audit",
        "Deployment",
        "Monitoring"
      ]
    }
  ];

  const caseStudies = getCaseStudiesByService('web-development');

  return (
    <div className="pt-20 bg-white">
      <SEO 
        title="Web Development"
        description="Modern web solutions tailored to your business needs. We specialize in responsive design, web applications, e-commerce solutions, and progressive web apps."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Service',
          name: 'Web Development Services',
          provider: {
            '@type': 'Organization',
            name: 'Senses Innovations'
          },
          serviceType: 'Web Development',
          areaServed: ['US', 'SA', 'AE', 'EG'],
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Web Development Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Corporate Websites',
                  description: 'Professional websites that represent your brand'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Web Applications',
                  description: 'Custom web applications for your business needs'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Progressive Web Apps',
                  description: 'Mobile-first web applications with offline capabilities'
                }
              }
            ]
          }
        }}
      />
      <PageHeader
        title="Web Development"
        subtitle="Modern web solutions tailored to your business needs"
      />

      {/* Technologies Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Technologies We Use</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            We leverage modern technologies and frameworks to build robust web solutions
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {technologies.map((tech, index) => (
            <div
              key={index}
              className="card group text-center"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-4 flex justify-center">
                  <tech.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <h3 className="font-semibold mb-1">{tech.label}</h3>
                <p className="text-sm text-gray-600">{tech.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Services Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive web development services for every need
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="mb-6">
                  <service.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                </div>
                <h3 className="text-2xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Features Section */}
      <Section background="gray">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Key Features</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Enterprise-grade features for reliable web solutions
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10 flex items-start gap-4">
                <div className="flex-shrink-0">
                  <feature.icon className="w-8 h-8 text-primary group-hover:text-accent transition-colors duration-300" />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Process Section */}
      <Section>
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-4">Our Process</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A structured approach to web development
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {process.map((phase, index) => (
            <div
              key={index}
              className="card group"
            >
              <div className="card-gradient" />
              <div className="relative z-10">
                <div className="text-4xl font-bold text-accent mb-4">{phase.phase}</div>
                <h3 className="text-xl font-semibold mb-2">{phase.title}</h3>
                <p className="text-gray-600 mb-4">{phase.description}</p>
                <ul className="space-y-2">
                  {phase.steps.map((step, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full mr-2"></span>
                      {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* Case Studies Section */}
      <CaseStudiesSection 
        caseStudies={caseStudies}
        title="Success Stories"
        subtitle="Real-world examples of successful web development projects"
        background="gray"
      />

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">Ready to Build Your Web Solution?</h2>
          <p className="text-gray-300 mb-8">
            Let's discuss how we can help create the perfect web solution for your business.
          </p>
          <Link to="/contact" className="button-secondary">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
}

export default WebDevelopment;