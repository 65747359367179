import React from 'react';

interface PageHeaderProps {
  title: string;
  subtitle: string;
  backgroundImage?: string;
}

function PageHeader({ title, subtitle, backgroundImage }: PageHeaderProps) {
  return (
    <section className="bg-primary py-8 md:py-12 lg:py-16">
      <div className="max-w-5xl mx-auto px-4">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white text-center mb-3 md:mb-4 opacity-0 animate-[fadeUp_0.8s_ease-out_forwards]">
          {title}
        </h1>
        <p className="text-base md:text-lg text-gray-300 max-w-3xl mx-auto text-center opacity-0 animate-[fadeUp_0.8s_ease-out_0.2s_forwards]">
          {subtitle}
        </p>
      </div>
    </section>
  );
}

export default PageHeader;