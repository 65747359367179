import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  LayoutDashboard,
  FileText,
  Users,
  Mail,
  Globe,
  Activity,
  Shield,
  Send
} from 'lucide-react';

function AdminSidebar() {
  const location = useLocation();
  
  const isActive = (path: string) => location.pathname === path;

  const navigation = [
    { name: 'Dashboard', href: '/admin', icon: LayoutDashboard },
    { name: 'Case Studies', href: '/admin/case-studies', icon: FileText },
    { name: 'SEO', href: '/admin/seo', icon: Globe },
    { name: 'Email', href: '/admin/email', icon: Mail },
    { name: 'Email Test', href: '/admin/email-test', icon: Send },
    { name: 'Users', href: '/admin/users', icon: Users },
    { name: 'Activity', href: '/admin/activity', icon: Activity },
    { name: 'Security', href: '/admin/security', icon: Shield }
  ];

  return (
    <aside className="w-64 bg-white dark:bg-gray-800 border-r border-gray-200/80 dark:border-gray-700/80 fixed left-0 top-16 bottom-0 overflow-y-auto shadow-lg transform transition-all duration-300 ease-in-out">
      <nav className="p-4 space-y-1">
        {navigation.map((item) => {
          const Icon = item.icon;
          const active = isActive(item.href);
          return (
            <Link
              key={item.name}
              to={item.href}
              className={`flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-300 group ${
                active
                  ? 'bg-primary dark:bg-accent text-white shadow-lg shadow-primary/25 dark:shadow-accent/25 scale-[1.02] -translate-x-1'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 hover:text-primary dark:hover:text-accent hover:shadow-md hover:scale-[1.02] hover:-translate-x-1'
              }`}
            >
              <Icon className={`w-5 h-5 transition-transform duration-300 ${
                active ? 'transform rotate-0' : 'group-hover:rotate-3'
              }`} />
              <span className="font-medium">{item.name}</span>
              {active && (
                <div className="absolute inset-y-0 right-0 w-1 bg-accent dark:bg-primary rounded-l-full transform transition-transform duration-300 scale-y-100" />
              )}
            </Link>
          );
        })}
      </nav>
    </aside>
  );
}

export default AdminSidebar;