import React, { useState, useEffect } from 'react';
import { FileText, X } from 'lucide-react';
import Modal from './Modal';
import FormField from './FormField';
import Button from './Button';
import ImageUpload from './ImageUpload';
import CertificateUpload from './CertificateUpload';
import { validateCaseStudy } from '../../lib/validation';
import { uploadImage, uploadCertificate } from '../../lib/storage';
import { supabase } from '../../lib/supabase/client';
import type { CaseStudy } from '../../types/admin';
import toast from 'react-hot-toast';

interface CaseStudyEditorProps {
  isOpen: boolean;
  onClose: () => void;
  caseStudy?: CaseStudy;
}

function CaseStudyEditor({ isOpen, onClose, caseStudy }: CaseStudyEditorProps) {
  const [formData, setFormData] = useState<Partial<CaseStudy>>({
    title: '',
    subtitle: '',
    description: '',
    client: '',
    industry: '',
    service: '',
    challenge: '',
    solution: '',
    results: [],
    image: '',
    certificateUrl: '',
    tags: [],
    seo: {
      title: '',
      description: '',
      slug: '',
      noIndex: false
    },
    status: 'draft'
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [certificateFile, setCertificateFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  // Update form data when case study changes
  useEffect(() => {
    if (caseStudy) {
      setFormData({
        ...caseStudy,
        certificateUrl: caseStudy.certificateUrl || ''
      });
    } else {
      setFormData({
        title: '',
        subtitle: '',
        description: '',
        client: '',
        industry: '',
        service: '',
        challenge: '',
        solution: '',
        results: [],
        image: '',
        certificateUrl: '',
        tags: [],
        seo: {
          title: '',
          description: '',
          slug: '',
          noIndex: false
        },
        status: 'draft'
      });
    }
  }, [caseStudy]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Ensure image is provided
    if (!formData.image && !imageFile) {
      setErrors(['Featured image is required']);
      return;
    }

    const validationErrors = validateCaseStudy(formData);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setSaving(true);
      
      // Handle image upload if there's a new image
      let imageUrl = formData.image;
      if (imageFile) {
        const fileName = `${Date.now()}-${imageFile.name}`;
        imageUrl = await uploadImage(imageFile, fileName);
      }

      // Handle certificate upload if there's a new certificate
      let certificateUrl = formData.certificateUrl;
      if (certificateFile) {
        const fileName = `${Date.now()}-${certificateFile.name}`;
        certificateUrl = await uploadCertificate(certificateFile, fileName);
      }

      // For development, use the temporary admin user ID
      const userId = '00000000-0000-0000-0000-000000000000';
      
      const { data, error } = await supabase
        .from('case_studies')
        .upsert({
          ...formData,
          image: imageUrl,
          certificate_url: certificateUrl,
          updated_by: userId,
          created_by: caseStudy ? undefined : userId
        })
        .select()
        .single();

      if (error) throw error;

      toast.success(caseStudy ? 'Case study updated' : 'Case study created');
      onClose();
    } catch (error) {
      console.error('Error saving case study:', error);
      toast.error('Failed to save case study');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field: keyof CaseStudy, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors([]);
  };

  const handleResultChange = (index: number, value: string) => {
    const results = [...(formData.results || [])];
    results[index] = value;
    handleChange('results', results);
  };

  const addResult = () => {
    handleChange('results', [...(formData.results || []), '']);
  };

  const removeResult = (index: number) => {
    const results = [...(formData.results || [])];
    results.splice(index, 1);
    handleChange('results', results);
  };

  const handleImageChange = (file: File | null) => {
    setImageFile(file);
    setErrors([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={caseStudy ? 'Edit Case Study' : 'Create Case Study'}
      size="xl"
      footer={
        <div className="flex justify-end gap-3">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} loading={saving}>
            {caseStudy ? 'Update' : 'Create'}
          </Button>
        </div>
      }
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {errors.length > 0 && (
          <div className="p-4 bg-red-50 rounded-lg">
            <ul className="list-disc list-inside text-sm text-red-600">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField label="Title" required>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => handleChange('title', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            />
          </FormField>

          <FormField label="Subtitle" required>
            <input
              type="text"
              value={formData.subtitle}
              onChange={(e) => handleChange('subtitle', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            />
          </FormField>
        </div>

        <FormField label="Description" required>
          <textarea
            value={formData.description}
            onChange={(e) => handleChange('description', e.target.value)}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            rows={3}
          />
        </FormField>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField label="Client" required>
            <input
              type="text"
              value={formData.client}
              onChange={(e) => handleChange('client', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            />
          </FormField>

          <FormField label="Industry" required>
            <select
              value={formData.industry}
              onChange={(e) => handleChange('industry', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            >
              <option value="">Select Industry</option>
              <option value="renewable-energy">Renewable Energy</option>
              <option value="retail">Retail</option>
              <option value="enterprise">Enterprise</option>
            </select>
          </FormField>
        </div>

        <FormField label="Service" required>
          <select
            value={formData.service}
            onChange={(e) => handleChange('service', e.target.value)}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
          >
            <option value="">Select Service</option>
            <option value="infrastructure">Infrastructure</option>
            <option value="digital-transformation">Digital Transformation</option>
            <option value="app-development">App Development</option>
            <option value="ai-solutions">AI Solutions</option>
            <option value="analytics">Analytics</option>
          </select>
        </FormField>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField label="Challenge" required>
            <textarea
              value={formData.challenge}
              onChange={(e) => handleChange('challenge', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
              rows={3}
            />
          </FormField>

          <FormField label="Solution" required>
            <textarea
              value={formData.solution}
              onChange={(e) => handleChange('solution', e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
              rows={3}
            />
          </FormField>
        </div>

        <FormField label="Results" required>
          <div className="space-y-3">
            {formData.results?.map((result, index) => (
              <div key={index} className="flex gap-3">
                <input
                  type="text"
                  value={result}
                  onChange={(e) => handleResultChange(index, e.target.value)}
                  className="flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                  placeholder="Enter a result"
                />
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => removeResult(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button
              type="button"
              variant="outline"
              onClick={addResult}
            >
              Add Result
            </Button>
          </div>
        </FormField>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField label="Featured Image" required icon={FileText}>
            <ImageUpload
              value={formData.image}
              onChange={handleImageChange}
              onError={(error) => toast.error(error)}
            />
          </FormField>

          <FormField label="Performance Certificate" icon={FileText}>
            <CertificateUpload
              value={formData.certificateUrl}
              onChange={setCertificateFile}
              onError={(error) => toast.error(error)}
            />
          </FormField>
        </div>

        <FormField label="Status">
          <select
            value={formData.status}
            onChange={(e) => handleChange('status', e.target.value)}
            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
          </select>
        </FormField>
      </form>
    </Modal>
  );
}

export default CaseStudyEditor;