import React from 'react';
import { Wind, ShoppingCart, Building2, Code, Cloud, Shield, Database, LineChart, Globe } from 'lucide-react';

interface FilterTabProps {
  label: string;
  count: number;
  icon: React.ElementType;
  isActive: boolean;
  onClick: () => void;
}

function FilterTab({ label, count, icon: Icon, isActive, onClick }: FilterTabProps) {
  return (
    <button
      onClick={onClick}
      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300 ${
        isActive 
          ? 'bg-primary text-white shadow-lg' 
          : 'bg-white text-gray-600 hover:bg-gray-50'
      }`}
    >
      <Icon className="w-4 h-4" />
      <span>{label}</span>
      <span className={`text-sm ${isActive ? 'text-accent' : 'text-gray-400'}`}>
        ({count})
      </span>
    </button>
  );
}

interface CaseStudiesFilterProps {
  activeFilter: 'industry' | 'service';
  setActiveFilter: (filter: 'industry' | 'service') => void;
  activeIndustry: string;
  setActiveIndustry: (industry: string) => void;
  activeService: string;
  setActiveService: (service: string) => void;
  industryCounts: Record<string, number>;
  serviceCounts: Record<string, number>;
}

function CaseStudiesFilter({
  activeFilter,
  setActiveFilter,
  activeIndustry,
  setActiveIndustry,
  activeService,
  setActiveService,
  industryCounts,
  serviceCounts
}: CaseStudiesFilterProps) {
  const industries = [
    { id: 'all', label: 'All Industries', icon: Building2 },
    { id: 'renewable-energy', label: 'Renewable Energy', icon: Wind },
    { id: 'retail', label: 'Retail & E-commerce', icon: ShoppingCart },
    { id: 'enterprise', label: 'Enterprise', icon: Building2 }
  ].filter(industry => industry.id === 'all' || industryCounts[industry.id] > 0);

  const services = [
    { id: 'all', label: 'All Services', icon: Shield },
    { id: 'infrastructure', label: 'Infrastructure', icon: Database },
    { id: 'digital-transformation', label: 'Digital Transformation', icon: Cloud },
    { id: 'app-development', label: 'App Development', icon: Code },
    { id: 'web-development', label: 'Web Development', icon: Globe },
    { id: 'ai-solutions', label: 'AI Solutions', icon: Cloud },
    { id: 'analytics', label: 'Analytics', icon: LineChart }
  ].filter(service => service.id === 'all' || serviceCounts[service.id] > 0);

  return (
    <div className="mb-12">
      {/* Filter Type Tabs */}
      <div className="flex justify-center gap-4 mb-8">
        <button
          onClick={() => setActiveFilter('industry')}
          className={`px-6 py-2 rounded-lg font-medium transition-all duration-300 ${
            activeFilter === 'industry'
              ? 'bg-primary text-white'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          By Industry
        </button>
        <button
          onClick={() => setActiveFilter('service')}
          className={`px-6 py-2 rounded-lg font-medium transition-all duration-300 ${
            activeFilter === 'service'
              ? 'bg-primary text-white'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          By Service
        </button>
      </div>

      {/* Filter Options */}
      <div className="flex flex-wrap justify-center gap-3">
        {activeFilter === 'industry' ? (
          industries.map((industry) => (
            <FilterTab
              key={industry.id}
              label={industry.label}
              count={industry.id === 'all' ? Object.values(industryCounts).reduce((a, b) => a + b, 0) : (industryCounts[industry.id] || 0)}
              icon={industry.icon}
              isActive={activeIndustry === industry.id}
              onClick={() => setActiveIndustry(industry.id)}
            />
          ))
        ) : (
          services.map((service) => (
            <FilterTab
              key={service.id}
              label={service.label}
              count={service.id === 'all' ? Object.values(serviceCounts).reduce((a, b) => a + b, 0) : (serviceCounts[service.id] || 0)}
              icon={service.icon}
              isActive={activeService === service.id}
              onClick={() => setActiveService(service.id)}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default CaseStudiesFilter