import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronRight, Wind, ShoppingCart, Building2, Server, Shield, Cpu } from 'lucide-react';
import SEO from '../components/SEO';
import { HeroSkeleton, StatsSkeleton, ProcessSkeleton } from '../components/Skeleton';

function Home() {
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState({
    expertise: false,
    industries: false,
    caseStudies: false,
    cta: false
  });

  const expertiseRef = useRef<HTMLDivElement>(null);
  const sectionsRef = useRef<{ [key: string]: HTMLDivElement | null }>({
    expertise: null,
    industries: null,
    caseStudies: null,
    cta: null
  });

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80';
    img.onload = () => setIsLoaded(true);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.getAttribute('data-section');
          if (sectionId) {
            setIsVisible(prev => ({
              ...prev,
              [sectionId]: entry.isIntersecting
            }));
          }
        });
      },
      { 
        threshold: 0.1,
        rootMargin: '0px 0px -10% 0px'
      }
    );

    // Observe all sections
    Object.keys(sectionsRef.current).forEach(sectionId => {
      const element = sectionsRef.current[sectionId];
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [loading]); // Re-run when loading state changes

  const scrollToNextSection = () => {
    if (expertiseRef.current) {
      expertiseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const industries = [
    {
      icon: Wind,
      title: "Renewable Energy",
      description: "Comprehensive IT solutions for wind and solar energy projects",
      features: ["Remote Site Infrastructure", "SCADA Systems", "Performance Monitoring"],
      link: "/industries/renewables"
    },
    {
      icon: ShoppingCart,
      title: "Retail & E-commerce",
      description: "Digital solutions for modern retail operations",
      features: ["POS Systems", "Digital Inventory", "Customer Analytics"]
    },
    {
      icon: Building2,
      title: "Enterprise",
      description: "End-to-end digital transformation solutions",
      features: ["Cloud Migration", "Process Automation", "Security Solutions"]
    }
  ];

  if (loading) {
    return (
      <div className="bg-white">
        <HeroSkeleton />
        <section className="py-20 bg-[#0B1329]">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center text-white mb-12">
              Our Expertise
            </h2>
            <StatsSkeleton />
          </div>
        </section>
        <section className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
              Industries We Serve
            </h2>
            <ProcessSkeleton />
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <SEO 
        title="Home"
        description="Leading technology solutions provider offering enterprise IT infrastructure, digital transformation, and innovative solutions across industries. Expert in cloud, AI, and secure infrastructure deployment."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Senses Innovations',
          url: 'https://sensesinnovations.com',
          logo: 'https://sensesinnovations.com/logo.svg',
          description: 'Enterprise technology solutions provider delivering digital transformation, cloud infrastructure, and AI-powered innovation.',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '5900 Balcones Dr. STE 100',
            addressLocality: 'Austin',
            addressRegion: 'TX',
            postalCode: '78731',
            addressCountry: 'US'
          },
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+1-713-474-4747',
            contactType: 'customer service',
            areaServed: ['US', 'SA', 'AE', 'EG'],
            availableLanguage: ['English', 'Arabic'],
            availableService: [
              'IT Infrastructure',
              'Digital Transformation',
              'Cloud Solutions',
              'AI & Analytics',
              'Enterprise Software',
              'Cybersecurity'
            ]
          },
          sameAs: [
            'https://www.linkedin.com/company/senses-innovations'
          ],
          knowsAbout: [
            'Enterprise IT Infrastructure',
            'Digital Transformation',
            'Cloud Computing',
            'Artificial Intelligence',
            'Business Analytics',
            'Cybersecurity',
            'Renewable Energy Technology'
          ],
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': 'https://sensesinnovations.com'
          }
        }}
      />
      {/* Hero Section */}
      <section className="relative min-h-[85vh] flex items-center justify-center overflow-hidden">
        {/* Background Image */}
        <div 
          className={`absolute inset-0 z-0 transition-all duration-1000 transform ${
            isLoaded ? 'opacity-100 scale-100' : 'opacity-0 scale-105'
          }`}
          style={{
            backgroundColor: '#0B1329',
            backgroundImage: 'url(https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.7)'
          }}
        />
        
        {/* Gradient Overlay */}
        <div 
          className={`absolute inset-0 z-[1] transition-opacity duration-1000 ${
            isLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            background: 'linear-gradient(135deg, rgba(11,19,41,0.85) 0%, rgba(11,19,41,0.7) 50%, rgba(110,255,234,0.3) 100%)'
          }}
        />

        {/* Content */}
        <div className="relative z-10 max-w-6xl mx-auto px-4">
          <div className="text-center space-y-8">
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold text-white opacity-0 animate-[fadeIn_1s_ease-out_0.2s_forwards]">
              <span className="block transform opacity-0 translate-y-8 animate-[slideInUp_0.8s_ease-out_0.4s_forwards]">
                Transforming Business
              </span>
              <span className="block transform opacity-0 translate-y-8 animate-[slideInUp_0.8s_ease-out_0.6s_forwards] text-[#6EFFEA]">
                Through Technology
              </span>
            </h1>
            
            <p className="text-xl md:text-2xl text-gray-200 max-w-3xl mx-auto leading-relaxed opacity-0 transform translate-y-8 animate-[slideInUp_0.8s_ease-out_0.8s_forwards]">
              Delivering innovative IT solutions that power the future of renewable energy
              and enterprise transformation.
            </p>

            <div className="flex items-center justify-center gap-6 pt-4 opacity-0 transform translate-y-8 animate-[slideInUp_0.8s_ease-out_1s_forwards]">
              <Link 
                to="/contact"
                className="px-8 py-4 bg-[#6EFFEA] text-[#0B1329] rounded-lg font-semibold text-lg transition-all duration-300 hover:shadow-lg hover:shadow-[#6EFFEA]/20 hover:-translate-y-1"
              >
                Get Started
              </Link>
              <Link 
                to="/services"
                className="px-8 py-4 border-2 border-[#6EFFEA] text-[#6EFFEA] rounded-lg font-semibold text-lg transition-all duration-300 hover:bg-[#6EFFEA]/10"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        {/* Scroll Indicator */}
        <button 
          onClick={scrollToNextSection}
          className="absolute bottom-12 left-1/2 transform -translate-x-1/2 text-white cursor-pointer opacity-0 animate-[fadeIn_0.5s_ease-out_1.4s_forwards] hover:translate-y-1 transition-transform duration-300"
          aria-label="Scroll to next section"
        >
          <div className="flex flex-col items-center gap-2">
            <span className="text-sm font-medium text-[#6EFFEA] uppercase tracking-wider">Explore</span>
            <ChevronDown className="w-6 h-6 animate-bounce text-[#6EFFEA]" />
          </div>
        </button>
      </section>

      {/* Key Differentiators */}
      <section 
        ref={(el) => {
          expertiseRef.current = el;
          sectionsRef.current.expertise = el;
        }}
        data-section="expertise"
        className="py-12 md:py-16 lg:py-20 bg-[#0B1329]"
      >
        <div className="max-w-6xl mx-auto px-4">
          <h2 className={`text-3xl md:text-4xl font-bold text-center text-white mb-12 transition-all duration-700 transform ${
            isVisible.expertise ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}>
            Our Expertise
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
            {[
              {
                icon: <Server className="w-8 h-8" />,
                title: "IT Infrastructure",
                description: "Proven expertise in deploying secure and scalable infrastructure solutions."
              },
              {
                icon: <Shield className="w-8 h-8" />,
                title: "Fast Mobilization",
                description: "Large-scale deployments completed in less than 3 months."
              },
              {
                icon: <Cpu className="w-8 h-8" />,
                title: "Future-Proof Technology",
                description: "Secure, scalable solutions trusted by industry leaders."
              }
            ].map((item, index) => (
              <div 
                key={index} 
                className={`card group bg-white/5 border border-[#6EFFEA]/20 transition-all duration-700 transform ${
                  isVisible.expertise 
                    ? 'opacity-100 translate-y-0' 
                    : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <div className="card-gradient" />
                <div className="relative z-10 p-4">
                  <div className="text-[#6EFFEA] mb-4 transform transition-all duration-300 group-hover:scale-110">
                    {item.icon}
                  </div>
                  <h3 className="text-lg font-semibold mb-2 text-white">{item.title}</h3>
                  <p className="text-sm md:text-base text-gray-300">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Industries */}
      <section 
        ref={(el) => { sectionsRef.current.industries = el; }}
        data-section="industries"
        className="py-20 bg-white"
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className={`text-3xl md:text-4xl font-bold text-[#0B1329] mb-4 transition-all duration-700 transform ${
              isVisible.industries ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Industries We Serve
            </h2>
            <p className={`text-xl text-gray-600 max-w-2xl mx-auto transition-all duration-700 delay-200 transform ${
              isVisible.industries ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Specialized solutions tailored for your industry's unique challenges
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {industries.map((industry, index) => (
              <div 
                key={index}
                className={`opacity-0 translate-y-4 transition-all duration-700 transform ${
                  isVisible.industries ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                {industry.link ? (
                  <Link to={industry.link} className="block h-full">
                    <div className="card group h-full">
                      <div className="card-gradient" />
                      <div className="relative z-10">
                        <div className="flex items-start justify-between mb-6">
                          <industry.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                          <ChevronRight className="w-6 h-6 text-primary transition-all duration-300 group-hover:text-accent group-hover:translate-x-1" />
                        </div>
                        <h3 className="text-2xl font-semibold mb-3">{industry.title}</h3>
                        <p className="text-gray-600 mb-6">{industry.description}</p>
                        <ul className="space-y-2">
                          {industry.features.map((feature, idx) => (
                            <li key={idx} className="text-gray-600">
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="card group h-full">
                    <div className="card-gradient" />
                    <div className="relative z-10">
                      <div className="flex items-start justify-between mb-6">
                        <industry.icon className="w-12 h-12 text-primary transition-all duration-300 group-hover:text-accent group-hover:scale-110" />
                      </div>
                      <h3 className="text-2xl font-semibold mb-3">{industry.title}</h3>
                      <p className="text-gray-600 mb-6">{industry.description}</p>
                      <ul className="space-y-2">
                        {industry.features.map((feature, idx) => (
                          <li key={idx} className="text-gray-600">
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies Preview */}
      <section 
        ref={(el) => { sectionsRef.current.caseStudies = el; }}
        data-section="caseStudies"
        className="py-20 bg-[#0B1329] text-white"
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className={`text-3xl md:text-4xl font-bold text-white mb-4 transition-all duration-700 transform ${
              isVisible.caseStudies ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Success Stories
            </h2>
            <p className={`text-gray-300 max-w-2xl mx-auto transition-all duration-700 delay-200 transform ${
              isVisible.caseStudies ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
            }`}>
              Discover how we've helped organizations achieve their technology goals
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Ras Ghareb Wind Energy",
                description: "262.5MW Wind Farm with 99.9% uptime",
                image: "https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?auto=format&fit=crop&q=80",
                details: "Complete IT infrastructure deployment ensuring maximum operational efficiency"
              },
              {
                title: "Red Sea Wind Energy",
                description: "500MW Wind Farm with complete regulatory compliance",
                image: "https://images.unsplash.com/photo-1466611653911-95081537e5b7?auto=format&fit=crop&q=80",
                details: "End-to-end IT solutions with full compliance and monitoring systems"
              },
              {
                title: "ACWA Power",
                description: "1.1GW Wind Farm IT enablement",
                image: "https://images.unsplash.com/photo-1548337138-e87d889cc369?auto=format&fit=crop&q=80",
                details: "Large-scale infrastructure deployment with integrated security systems"
              }
            ].map((study, index) => (
              <div 
                key={index}
                className={`transition-all duration-700 transform ${
                  isVisible.caseStudies ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                }`}
                style={{ transitionDelay: `${(index + 2) * 200}ms` }}
              >
                <div className="card group bg-white/10 backdrop-blur-sm border border-white/10 h-full">
                  <div className="card-gradient" />
                  <div className="relative z-10">
                    <div 
                      className="aspect-[16/9] rounded-xl bg-cover bg-center mb-6 transform transition-all duration-500 group-hover:scale-[1.02] shadow-lg"
                      style={{ backgroundImage: `url(${study.image})` }}
                    />
                    <div className="space-y-4">
                      <h3 className="text-xl font-semibold text-[#6EFFEA]">{study.title}</h3>
                      <p className="text-lg text-white/90 font-medium">{study.description}</p>
                      <p className="text-gray-300 leading-relaxed">{study.details}</p>
                      <button className="w-full mt-4 py-3 px-6 rounded-lg border border-[#6EFFEA] text-[#6EFFEA] transition-all duration-300 font-medium hover:bg-[#6EFFEA]/10 hover:scale-[1.02]">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section 
        ref={(el) => { sectionsRef.current.cta = el; }}
        data-section="cta"
        className="py-16 bg-primary relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-accent/10 to-transparent opacity-50" />
        <div className={`max-w-4xl mx-auto text-center px-4 relative z-10 transition-all duration-700 transform ${
          isVisible.cta ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
        }`}>
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-gray-100 mb-8 text-lg">
            Let's discuss how Senses Innovations can help you achieve your technology goals.
          </p>
          <Link 
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-accent text-primary rounded-xl text-lg font-semibold transition-all duration-300 hover:shadow-lg hover:-translate-y-1 hover:shadow-accent/20"
          >
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Home;