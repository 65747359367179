import React, { useState, useEffect } from 'react';
import { Globe, FileText, RefreshCw, Search, AlertTriangle, CheckCircle, XCircle, ExternalLink, Eye, Lightbulb } from 'lucide-react';
import ContentHeader from '../../components/admin/ContentHeader';
import Card from '../../components/admin/Card';
import Button from '../../components/admin/Button';
import SearchInput from '../../components/admin/SearchInput';
import FilterDropdown from '../../components/admin/FilterDropdown';
import DataTable from '../../components/admin/DataTable';
import SEOEditor from '../../components/admin/SEOEditor';
import SEOScoreCard from '../../components/admin/SEOScoreCard';
import SEOPreview from '../../components/admin/SEOPreview';
import SEOSuggestions from '../../components/admin/SEOSuggestions';
import SEOHeaderChat from '../../components/admin/SEOHeaderChat';
import { generateSitemap, generateRobotsTxt } from '../../lib/seo';
import { scanPages } from '../../lib/seo-scanner';
import { analyzeSEO, generateSEOSuggestions } from '../../lib/seo-analyzer';
import { supabase } from '../../lib/supabase/client';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

function SEOPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState<any>(null);
  const [pages, setPages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [scanning, setScanning] = useState(false);
  const [lastScan, setLastScan] = useState<Date | null>(null);
  const [stats, setStats] = useState({
    total: 0,
    indexed: 0,
    noIndex: 0,
    issues: 0,
    averageScore: 0
  });

  // Mock data for SEO Header Chat
  const [headerData] = useState({
    pageScore: 85,
    issues: [
      {
        type: 'critical' as const,
        message: 'Missing meta description',
        page: '/services/infrastructure',
        score: 65
      },
      {
        type: 'warning' as const,
        message: 'Title too long',
        page: '/about',
        score: 75
      },
      {
        type: 'info' as const,
        message: 'Consider adding more internal links',
        page: '/contact',
        score: 85
      }
    ],
    rankingChanges: [
      { keyword: 'renewable energy infrastructure', change: 5, position: 3 },
      { keyword: 'wind farm IT solutions', change: -2, position: 7 },
      { keyword: 'solar farm monitoring', change: 3, position: 4 }
    ],
    webVitals: [
      { name: 'LCP', score: 'good' as const, value: '2.1s' },
      { name: 'FID', score: 'good' as const, value: '12ms' },
      { name: 'CLS', score: 'needs-improvement' as const, value: '0.15' }
    ],
    lastCrawl: new Date()
  });

  useEffect(() => {
    loadPages();
  }, []);

  useEffect(() => {
    // Calculate stats whenever pages change
    const newStats = pages.reduce((acc, page) => {
      const analysis = analyzeSEO({
        title: page.title,
        description: page.description,
        ogTitle: page.og_title,
        ogDescription: page.og_description,
        ogImage: page.og_image,
        noIndex: page.no_index,
        canonicalUrl: page.canonical_url,
        metaKeywords: page.meta_keywords || [],
        metaRobots: page.meta_robots || [],
        schemaMarkup: page.schema_markup || '',
        twitterCard: page.twitter_card || '',
        twitterTitle: page.twitter_title || '',
        twitterDescription: page.twitter_description || '',
        twitterImage: page.twitter_image || ''
      });

      return {
        total: acc.total + 1,
        indexed: acc.indexed + (page.no_index ? 0 : 1),
        noIndex: acc.noIndex + (page.no_index ? 1 : 0),
        issues: acc.issues + (analysis.issues.length > 0 ? 1 : 0),
        averageScore: acc.averageScore + analysis.score
      };
    }, { total: 0, indexed: 0, noIndex: 0, issues: 0, averageScore: 0 });

    newStats.averageScore = Math.round(newStats.averageScore / (pages.length || 1));
    setStats(newStats);
  }, [pages]);

  const loadPages = async () => {
    try {
      const { data, error } = await supabase
        .from('seo_pages')
        .select('*')
        .order('path');

      if (error) throw error;
      setPages(data || []);

      // Get last scan time
      const { data: scanData } = await supabase
        .from('seo_pages')
        .select('last_scanned')
        .order('last_scanned', { ascending: false })
        .limit(1)
        .single();

      if (scanData?.last_scanned) {
        setLastScan(new Date(scanData.last_scanned));
      }
    } catch (error) {
      console.error('Error loading pages:', error);
      toast.error('Failed to load pages');
    } finally {
      setLoading(false);
    }
  };

  const analyzePage = (page: any) => {
    const seoData = {
      title: page.title,
      description: page.description,
      ogTitle: page.og_title,
      ogDescription: page.og_description,
      ogImage: page.og_image,
      noIndex: page.no_index,
      canonicalUrl: page.canonical_url,
      metaKeywords: page.meta_keywords || [],
      metaRobots: page.meta_robots || [],
      schemaMarkup: page.schema_markup || '',
      twitterCard: page.twitter_card || '',
      twitterTitle: page.twitter_title || '',
      twitterDescription: page.twitter_description || '',
      twitterImage: page.twitter_image || ''
    };

    return analyzeSEO(seoData);
  };

  const handleScan = async () => {
    try {
      setScanning(true);
      
      // For testing, use the temporary admin user
      const userId = '00000000-0000-0000-0000-000000000000';
      
      const { results } = await scanPages(userId);
      await loadPages();
      
      toast.success(
        `SEO scan completed: ${results.created} created, ${results.updated} updated` +
        (results.errors > 0 ? `, ${results.errors} errors` : '')
      );
    } catch (error) {
      console.error('Error scanning pages:', error);
      toast.error('Failed to scan pages: ' + (error as Error).message);
    } finally {
      setScanning(false);
    }
  };

  const handleGenerateSitemap = () => {
    const sitemap = generateSitemap(pages.map(page => ({
      path: page.path,
      lastmod: page.last_modified,
      priority: page.path === '/' ? 1.0 : 0.8
    })));

    // Download sitemap
    const blob = new Blob([sitemap], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleGenerateRobotsTxt = () => {
    const robotsTxt = generateRobotsTxt({
      allowAll: true,
      disallowPaths: ['/admin', '/api'],
      sitemapUrl: 'https://sensesinnovations.com/sitemap.xml'
    });

    // Download robots.txt
    const blob = new Blob([robotsTxt], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'robots.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <SEOHeaderChat
        pageScore={headerData.pageScore}
        issues={headerData.issues}
        rankingChanges={headerData.rankingChanges}
        webVitals={headerData.webVitals}
        lastCrawl={headerData.lastCrawl}
      />

      <ContentHeader
        title="SEO Management"
        description={`Manage SEO settings for all pages • ${
          lastScan 
            ? `Last scan: ${format(lastScan, 'MMM d, yyyy h:mm:ss a')}`
            : 'No scans yet'
        }`}
        icon={Globe}
        actions={
          <>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={handleScan}
              loading={scanning}
            >
              Scan Pages
            </Button>
            <Button
              variant="outline"
              icon={FileText}
              onClick={handleGenerateSitemap}
            >
              Generate Sitemap
            </Button>
            <Button
              variant="outline"
              icon={Globe}
              onClick={handleGenerateRobotsTxt}
            >
              Generate Robots.txt
            </Button>
          </>
        }
      />

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
        <Card
          title="Overall Score"
          icon={CheckCircle}
          className="bg-white dark:bg-gray-800"
        >
          <div className="mt-2">
            <div className={`text-3xl font-bold ${
              stats.averageScore >= 90 
                ? 'text-green-500 dark:text-green-400'
                : stats.averageScore >= 70
                ? 'text-yellow-500 dark:text-yellow-400'
                : 'text-red-500 dark:text-red-400'
            }`}>
              {stats.averageScore}
            </div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">Average SEO score</p>
          </div>
        </Card>

        <Card
          title="Indexed Pages"
          icon={Globe}
          className="bg-white dark:bg-gray-800"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-primary dark:text-accent">{stats.indexed}</div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">Pages indexed by search engines</p>
          </div>
        </Card>

        <Card
          title="Non-Indexed Pages"
          icon={XCircle}
          className="bg-white dark:bg-gray-800"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-gray-600 dark:text-gray-400">{stats.noIndex}</div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">Pages excluded from search</p>
          </div>
        </Card>

        <Card
          title="Issues Found"
          icon={AlertTriangle}
          className="bg-white dark:bg-gray-800"
        >
          <div className="mt-2">
            <div className="text-3xl font-bold text-amber-600 dark:text-amber-500">{stats.issues}</div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">Pages with SEO issues</p>
          </div>
        </Card>
      </div>

      <Card>
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search pages..."
            className="md:w-64"
          />
          <FilterDropdown
            label="Status"
            options={[
              { value: 'all', label: 'All Pages' },
              { value: 'indexed', label: 'Indexed' },
              { value: 'noindex', label: 'Not Indexed' },
              { value: 'issues', label: 'Has Issues' }
            ]}
            value={statusFilter}
            onChange={setStatusFilter}
          />
        </div>

        <DataTable
          columns={[
            { 
              header: 'Page',
              accessor: (row) => (
                <div>
                  <div className="font-medium text-gray-900 dark:text-white">{row.path}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">{row.title}</div>
                </div>
              )
            },
            {
              header: 'Score',
              accessor: (row) => {
                const analysis = analyzePage(row);
                return (
                  <div className={`font-medium ${
                    analysis.score >= 90 
                      ? 'text-green-500 dark:text-green-400'
                      : analysis.score >= 70
                      ? 'text-yellow-500 dark:text-yellow-400'
                      : 'text-red-500 dark:text-red-400'
                  }`}>
                    {analysis.score}
                  </div>
                );
              }
            },
            {
              header: 'Status',
              accessor: (row) => {
                const analysis = analyzePage(row);
                if (analysis.issues.length > 0) {
                  return (
                    <div className="flex items-center gap-2 text-amber-600 dark:text-amber-500">
                      <AlertTriangle className="w-4 h-4" />
                      <span>Issues Found</span>
                    </div>
                  );
                }
                if (row.no_index) {
                  return (
                    <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                      <XCircle className="w-4 h-4" />
                      <span>Not Indexed</span>
                    </div>
                  );
                }
                return (
                  <div className="flex items-center gap-2 text-green-600 dark:text-green-500">
                    <CheckCircle className="w-4 h-4" />
                    <span>Indexed</span>
                  </div>
                );
              }
            },
            {
              header: 'Last Modified',
              accessor: (row) => format(new Date(row.last_modified), 'MMM d, yyyy h:mm:ss a'),
              className: 'text-right text-gray-500 dark:text-gray-400'
            },
            {
              header: 'Actions',
              accessor: (row) => (
                <div className="flex items-center justify-end gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Eye}
                    onClick={() => window.open(row.path, '_blank')}
                  >
                    View
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Lightbulb}
                    onClick={() => {
                      setSelectedPage(row);
                      setIsEditorOpen(true);
                    }}
                  >
                    Optimize
                  </Button>
                </div>
              )
            }
          ]}
          data={pages.filter(page => {
            const matchesSearch = 
              page.path.toLowerCase().includes(searchQuery.toLowerCase()) ||
              page.title?.toLowerCase().includes(searchQuery.toLowerCase());
            
            if (statusFilter === 'all') return matchesSearch;
            if (statusFilter === 'indexed') return matchesSearch && !page.no_index;
            if (statusFilter === 'noindex') return matchesSearch && page.no_index;
            if (statusFilter === 'issues') {
              const analysis = analyzePage(page);
              return matchesSearch && analysis.issues.length > 0;
            }
            
            return matchesSearch;
          })}
          loading={loading}
        />
      </Card>

      <SEOEditor
        isOpen={isEditorOpen}
        onClose={() => {
          setIsEditorOpen(false);
          setSelectedPage(null);
          loadPages();
        }}
        page={selectedPage}
      />
    </div>
  );
}

export default SEOPage;