import React, { useRef, useState, useEffect } from 'react';
import { Upload, X } from 'lucide-react';
import Button from './Button';

interface ImageUploadProps {
  value?: string;
  onChange: (file: File) => void;
  onError?: (error: string) => void;
}

function ImageUpload({ value, onChange, onError }: ImageUploadProps) {
  const [preview, setPreview] = useState<string | undefined>(value);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Update preview when value changes
  useEffect(() => {
    setPreview(value);
  }, [value]);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      onError?.('Please select an image file');
      return;
    }

    // Validate file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      onError?.('Image size should not exceed 2MB');
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result as string);
    };
    reader.readAsDataURL(file);

    // Trigger upload
    onChange(file);
  };

  const handleClear = () => {
    setPreview(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-4">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="hidden"
      />

      {preview ? (
        <div className="relative">
          <img
            src={preview}
            alt="Preview"
            className="w-full h-48 object-cover rounded-lg"
          />
          <button
            onClick={handleClear}
            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-lg hover:bg-gray-100"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      ) : (
        <Button
          variant="outline"
          icon={Upload}
          onClick={() => fileInputRef.current?.click()}
          loading={loading}
          className="w-full h-48"
        >
          Click to upload image
        </Button>
      )}

      <p className="text-xs text-gray-500">
        Recommended size: 1200x630px. Max size: 2MB
      </p>
    </div>
  );
}

export default ImageUpload;