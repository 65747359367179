import caseStudies from './caseStudies';
import { faqs } from './faqs';

export interface SearchResult {
  type: 'case-study' | 'service' | 'industry' | 'technology' | 'solution' | 'feature' | 'faq' | 'page';
  title: string;
  description: string;
  path: string;
  image?: string;
  tags: string[];
  keywords?: string[];
}

const searchData: SearchResult[] = [
  // Pages
  {
    type: 'page',
    title: 'Home',
    description: 'Transforming businesses through technology',
    path: '/',
    tags: ['home', 'main', 'overview'],
    keywords: [
      'technology solutions',
      'digital transformation',
      'IT services',
      'innovation',
      'enterprise solutions'
    ]
  },
  {
    type: 'page',
    title: 'About Us',
    description: 'Leading technology and digital transformation partner',
    path: '/about',
    tags: ['about', 'company', 'mission'],
    keywords: [
      'company',
      'mission',
      'values',
      'expertise',
      'global presence',
      'team',
      'leadership'
    ]
  },
  {
    type: 'page',
    title: 'Contact',
    description: 'Get in touch with our team',
    path: '/contact',
    tags: ['contact', 'support', 'help'],
    keywords: [
      'contact us',
      'support',
      'get in touch',
      'locations',
      'offices',
      'global presence'
    ]
  },

  // Case Studies
  ...caseStudies.map(study => ({
    type: 'case-study',
    title: study.title,
    description: study.description,
    path: `/case-studies#${study.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`,
    image: study.image,
    tags: [study.industry || '', study.service || '', 'success story', 'implementation'],
    keywords: [
      'case study',
      'success story',
      'implementation',
      'project',
      study.industry || '',
      study.service || '',
      study.title,
      study.subtitle,
      ...study.results
    ]
  })),

  // Services
  {
    type: 'service',
    title: 'IT Consultancy',
    description: 'Expert guidance and support for technology initiatives',
    path: '/services/it-consultancy',
    tags: ['consulting', 'support', 'strategy', 'guidance', 'technology', 'infrastructure'],
    keywords: [
      'IT consulting',
      'technology consulting',
      'IT strategy',
      'digital strategy',
      'IT support',
      'technical guidance',
      'enterprise IT',
      'IT solutions',
      'technology roadmap',
      'IT assessment',
      'IT planning',
      'consulting services'
    ]
  },
  {
    type: 'service',
    title: 'Infrastructure',
    description: 'Robust and scalable technology infrastructure solutions',
    path: '/services/infrastructure',
    tags: ['network', 'hardware', 'cloud', 'data center', 'security', 'scalable'],
    keywords: [
      'IT infrastructure',
      'network infrastructure',
      'cloud infrastructure',
      'data center',
      'hardware',
      'servers',
      'networking',
      'storage',
      'security',
      'scalability',
      'redundancy',
      'high availability',
      'infrastructure solutions'
    ]
  },
  {
    type: 'service',
    title: 'Digital Transformation',
    description: 'Comprehensive digital evolution for modern businesses',
    path: '/services/digital-transformation',
    tags: ['transformation', 'modernization', 'cloud', 'automation', 'process optimization'],
    keywords: [
      'digital transformation',
      'business transformation',
      'modernization',
      'cloud migration',
      'process automation',
      'workflow optimization',
      'digital strategy',
      'enterprise modernization',
      'legacy modernization',
      'digital evolution',
      'business process',
      'automation'
    ]
  },
  {
    type: 'service',
    title: 'AI Solutions',
    description: 'Cutting-edge artificial intelligence implementation',
    path: '/services/ai-solutions',
    tags: ['ai', 'machine learning', 'automation', 'predictive analytics', 'neural networks'],
    keywords: [
      'artificial intelligence',
      'AI',
      'machine learning',
      'ML',
      'deep learning',
      'neural networks',
      'predictive analytics',
      'automation',
      'AI implementation',
      'intelligent systems',
      'cognitive computing',
      'AI solutions'
    ]
  },
  {
    type: 'service',
    title: 'Analytics',
    description: 'Data-driven insights for informed decision making',
    path: '/services/analytics',
    tags: ['data', 'insights', 'business intelligence', 'reporting', 'visualization'],
    keywords: [
      'data analytics',
      'business intelligence',
      'BI',
      'data visualization',
      'reporting',
      'insights',
      'metrics',
      'KPIs',
      'dashboards',
      'data analysis',
      'predictive analytics',
      'analytics solutions'
    ]
  },
  {
    type: 'service',
    title: 'Web Development',
    description: 'Modern web solutions for your digital presence',
    path: '/services/web-development',
    tags: ['web', 'development', 'responsive', 'applications', 'frontend'],
    keywords: [
      'web development',
      'website',
      'web application',
      'frontend',
      'backend',
      'responsive design',
      'UI/UX',
      'React',
      'JavaScript',
      'TypeScript',
      'web solutions',
      'website development'
    ]
  },
  {
    type: 'service',
    title: 'App Development',
    description: 'Building modern, scalable applications for enterprise needs',
    path: '/services/app-development',
    tags: ['mobile', 'applications', 'development', 'enterprise', 'software'],
    keywords: [
      'app development',
      'mobile apps',
      'enterprise applications',
      'software development',
      'iOS',
      'Android',
      'cross-platform',
      'native apps',
      'mobile solutions',
      'application development'
    ]
  },

  // Industries
  {
    type: 'industry',
    title: 'Renewable Energy',
    description: 'Comprehensive IT solutions for wind and solar energy projects',
    path: '/industries/renewables',
    tags: ['renewable', 'energy', 'wind', 'solar', 'sustainability'],
    keywords: [
      'renewable energy',
      'wind energy',
      'solar energy',
      'wind farm',
      'solar farm',
      'clean energy',
      'sustainable energy',
      'green energy',
      'energy infrastructure',
      'power generation',
      'renewable solutions'
    ]
  },
  {
    type: 'industry',
    title: 'Retail & E-commerce',
    description: 'Digital solutions for modern retail operations',
    path: '/industries#retail',
    tags: ['retail', 'e-commerce', 'digital', 'commerce', 'sales'],
    keywords: [
      'retail',
      'e-commerce',
      'online retail',
      'digital commerce',
      'retail technology',
      'POS',
      'inventory management',
      'retail analytics',
      'omnichannel',
      'retail solutions'
    ]
  },
  {
    type: 'industry',
    title: 'Enterprise',
    description: 'End-to-end digital transformation solutions',
    path: '/industries#enterprise',
    tags: ['enterprise', 'business', 'corporate', 'digital transformation'],
    keywords: [
      'enterprise',
      'corporate',
      'business',
      'digital transformation',
      'enterprise solutions',
      'business technology',
      'enterprise IT',
      'corporate technology',
      'business solutions'
    ]
  },

  // Solutions
  {
    type: 'solution',
    title: 'Backhaul Networks',
    description: 'High-performance network infrastructure for remote renewable energy sites',
    path: '/industries/renewables#infrastructure',
    tags: ['network', 'infrastructure', 'connectivity', 'remote', 'renewable'],
    keywords: [
      'backhaul',
      'network infrastructure',
      'connectivity',
      'remote sites',
      'network performance',
      'wireless backhaul',
      'site connectivity',
      'network solutions'
    ]
  },
  {
    type: 'solution',
    title: 'Site Camps & Mobilization',
    description: 'Complete IT infrastructure for temporary site camps',
    path: '/industries/renewables#mobilization',
    tags: ['site', 'camp', 'mobilization', 'temporary', 'infrastructure'],
    keywords: [
      'site camps',
      'mobilization',
      'temporary infrastructure',
      'camp setup',
      'site infrastructure',
      'remote camps',
      'rapid deployment',
      'site solutions'
    ]
  },

  // FAQs
  ...faqs.map(faq => ({
    type: 'faq',
    title: faq.question,
    description: faq.answer,
    path: `/faq#${faq.question.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`,
    tags: faq.tags,
    keywords: [
      'faq',
      'question',
      'help',
      'support',
      ...faq.tags,
      ...faq.question.toLowerCase().split(' '),
      ...faq.answer.toLowerCase().split(' ')
    ]
  }))
];

export default searchData;