import type { CaseStudy } from '../types/admin';

const caseStudies: CaseStudy[] = [
  // Renewable Energy Case Studies
  {
    id: '123e4567-e89b-12d3-a456-426614174000',
    title: "Ras Ghareb Wind Energy",
    subtitle: "262.5MW Wind Farm",
    description: "Complete IT infrastructure deployment with 99.9% uptime achievement",
    image: "https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?auto=format&fit=crop&q=80",
    results: [
      "Successful deployment in under 3 months",
      "99.9% system uptime",
      "Full regulatory compliance",
      "Integrated monitoring systems"
    ],
    certificateUrl: "/certificates/ras-ghareb-performance.pdf",
    client: "Ras Ghareb Wind Energy",
    industry: "renewable-energy",
    service: "infrastructure",
    challenge: "Rapid deployment of complete IT infrastructure in a remote location with strict regulatory requirements.",
    solution: "Implemented a comprehensive IT solution with redundant systems and automated monitoring.",
    tags: ["wind-energy", "infrastructure", "monitoring"],
    seo: {
      title: "Ras Ghareb Wind Farm Case Study | Senses Innovations",
      description: "Learn how we deployed IT infrastructure for a 262.5MW wind farm with 99.9% uptime.",
      slug: "ras-ghareb-wind-energy",
      noIndex: false
    },
    createdAt: "2024-01-15T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174001',
    title: "Red Sea Wind Energy",
    subtitle: "500MW Wind Farm",
    description: "End-to-end IT solutions with complete regulatory compliance",
    image: "https://images.unsplash.com/photo-1466611653911-95081537e5b7?auto=format&fit=crop&q=80",
    results: [
      "Rapid deployment timeline",
      "Enhanced security measures",
      "Real-time monitoring",
      "Automated reporting systems"
    ],
    certificateUrl: "/certificates/red-sea-performance.pdf",
    client: "Red Sea Wind Energy",
    industry: "renewable-energy",
    service: "infrastructure",
    challenge: "Establishing secure and compliant IT systems for a large-scale wind farm operation.",
    solution: "Deployed comprehensive IT infrastructure with advanced security and monitoring capabilities.",
    tags: ["wind-energy", "security", "monitoring"],
    seo: {
      title: "Red Sea Wind Farm Case Study | Senses Innovations",
      description: "Discover how we implemented secure IT solutions for a 500MW wind farm project.",
      slug: "red-sea-wind-energy",
      noIndex: false
    },
    createdAt: "2024-01-20T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174002',
    title: "ACWA Power",
    subtitle: "1.1GW Wind Farm",
    description: "Large-scale IT infrastructure enablement",
    image: "https://images.unsplash.com/photo-1548337138-e87d889cc369?auto=format&fit=crop&q=80",
    results: [
      "Scalable infrastructure",
      "Integrated security systems",
      "Performance optimization",
      "24/7 monitoring capability"
    ],
    certificateUrl: "/certificates/acwa-power-performance.pdf",
    client: "ACWA Power",
    industry: "renewable-energy",
    service: "infrastructure",
    challenge: "Implementing scalable IT infrastructure for the largest wind farm project in the region.",
    solution: "Delivered enterprise-grade IT systems with advanced monitoring and security features.",
    tags: ["wind-energy", "enterprise", "scalability"],
    seo: {
      title: "ACWA Power Wind Farm Case Study | Senses Innovations",
      description: "See how we enabled IT infrastructure for a massive 1.1GW wind farm project.",
      slug: "acwa-power",
      noIndex: false
    },
    createdAt: "2024-01-25T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174003',
    title: "ENGIE Solar KSA",
    subtitle: "30MW Solar Farm",
    description: "Advanced infrastructure connectivity solution enabling automated maintenance",
    image: "https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?auto=format&fit=crop&q=80",
    results: [
      "High-reliability network infrastructure",
      "Redundant connectivity systems",
      "Seamless automation integration",
      "99.99% connectivity uptime"
    ],
    certificateUrl: "/certificates/engie-solar-performance.pdf",
    client: "ENGIE Solar KSA",
    industry: "renewable-energy",
    service: "infrastructure",
    challenge: "Creating reliable connectivity infrastructure for automated solar farm maintenance.",
    solution: "Implemented redundant network systems with high-availability design.",
    tags: ["solar-energy", "automation", "connectivity"],
    seo: {
      title: "ENGIE Solar KSA Case Study | Senses Innovations",
      description: "Learn about our automated maintenance solution for a 30MW solar farm.",
      slug: "engie-solar-ksa",
      noIndex: false
    },
    createdAt: "2024-01-30T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174004',
    title: "Amunet Wind Energy",
    subtitle: "500MW Wind Farm",
    description: "State-of-the-art security and monitoring infrastructure",
    image: "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?auto=format&fit=crop&q=80",
    results: [
      "Advanced security systems",
      "Remote monitoring capabilities",
      "Integrated SCADA systems",
      "Rapid deployment success"
    ],
    client: "Amunet Wind Energy",
    industry: "renewable-energy",
    service: "infrastructure",
    challenge: "Implementing comprehensive security and monitoring systems for a large wind farm.",
    solution: "Deployed integrated security and SCADA systems with remote monitoring capabilities.",
    tags: ["wind-energy", "security", "scada"],
    seo: {
      title: "Amunet Wind Energy Case Study | Senses Innovations",
      description: "Discover our security and monitoring solution for a 500MW wind farm.",
      slug: "amunet-wind-energy",
      noIndex: false
    },
    createdAt: "2024-02-05T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174005',
    title: "MAGRABi Retail",
    subtitle: "Service Desk Implementation",
    description: "Comprehensive service desk solution for IT, Supply Chain, and Accounts Payable operations",
    image: "https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80",
    results: [
      "Streamlined IT support processes",
      "Automated supply chain workflows",
      "Enhanced accounts payable efficiency",
      "Integrated ticketing system"
    ],
    client: "MAGRABi",
    industry: "retail",
    service: "digital-transformation",
    challenge: "Modernizing service desk operations across multiple departments.",
    solution: "Implemented an integrated service desk solution with automated workflows.",
    tags: ["retail", "automation", "service-desk"],
    seo: {
      title: "MAGRABi Retail Service Desk Case Study | Senses Innovations",
      description: "See how we transformed MAGRABi's service desk operations.",
      slug: "magrabi-service-desk",
      noIndex: false
    },
    createdAt: "2024-02-10T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174006',
    title: "MAGRABi Retail",
    subtitle: "Microsoft Hybrid Migration to Cloud",
    description: "Seamless migration of on-premises infrastructure to Microsoft's hybrid cloud solution",
    image: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80",
    results: [
      "Zero downtime migration",
      "Enhanced system reliability",
      "Improved scalability",
      "Cost optimization"
    ],
    client: "MAGRABi",
    industry: "retail",
    service: "digital-transformation",
    challenge: "Migrating critical systems to the cloud while maintaining business continuity.",
    solution: "Executed a phased migration strategy with zero downtime.",
    tags: ["retail", "cloud-migration", "hybrid-cloud"],
    seo: {
      title: "MAGRABi Cloud Migration Case Study | Senses Innovations",
      description: "Learn about MAGRABi's successful migration to Microsoft's hybrid cloud.",
      slug: "magrabi-cloud-migration",
      noIndex: false
    },
    createdAt: "2024-02-15T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174007',
    title: "MAGRABi Retail",
    subtitle: "User Provisioning System",
    description: "Automated user onboarding and offboarding system implementation",
    image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80",
    results: [
      "Streamlined user management",
      "Reduced provisioning time by 80%",
      "Enhanced security compliance",
      "Automated access control"
    ],
    client: "MAGRABi",
    industry: "retail",
    service: "digital-transformation",
    challenge: "Automating user management processes across multiple systems.",
    solution: "Developed an automated provisioning system with role-based access control.",
    tags: ["retail", "automation", "user-management"],
    seo: {
      title: "MAGRABi User Provisioning Case Study | Senses Innovations",
      description: "Discover how we automated MAGRABi's user management processes.",
      slug: "magrabi-user-provisioning",
      noIndex: false
    },
    createdAt: "2024-02-20T00:00:00Z",
    updatedAt: "2024-02-24T00:00:00Z",
    createdBy: "00000000-0000-0000-0000-000000000000",
    updatedBy: "00000000-0000-0000-0000-000000000000",
    status: "published"
  }
];

export function getCaseStudiesByIndustry(industry: string): CaseStudy[] {
  return industry === 'all' ? caseStudies : caseStudies.filter(study => study.industry === industry);
}

export function getCaseStudiesByService(service: string): CaseStudy[] {
  return service === 'all' ? caseStudies : caseStudies.filter(study => study.service === service);
}

export function getIndustryCounts(): Record<string, number> {
  const counts: Record<string, number> = {};
  caseStudies.forEach(study => {
    if (study.industry) {
      counts[study.industry] = (counts[study.industry] || 0) + 1;
    }
  });
  return counts;
}

export function getServiceCounts(): Record<string, number> {
  const counts: Record<string, number> = {};
  caseStudies.forEach(study => {
    if (study.service) {
      counts[study.service] = (counts[study.service] || 0) + 1;
    }
  });
  return counts;
}

export function getTotalCaseStudies(): number {
  return caseStudies.length;
}

export function getPublishedCaseStudies(): CaseStudy[] {
  return caseStudies.filter(study => study.status === 'published');
}

export function getLatestCaseStudies(limit?: number): CaseStudy[] {
  const sorted = [...caseStudies].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  return limit ? sorted.slice(0, limit) : sorted;
}

export default caseStudies;