import React, { useState, useEffect } from 'react';
import { Bell, ChevronDown, ChevronUp, AlertTriangle, CheckCircle, Clock, Gauge, Search, X } from 'lucide-react';
import { format } from 'date-fns';

interface SEOIssue {
  type: 'critical' | 'warning' | 'info';
  message: string;
  page: string;
  score: number;
}

interface RankingChange {
  keyword: string;
  change: number;
  position: number;
}

interface WebVital {
  name: string;
  score: 'good' | 'needs-improvement' | 'poor';
  value: string;
}

interface SEOHeaderChatProps {
  pageScore: number;
  issues: SEOIssue[];
  rankingChanges: RankingChange[];
  webVitals: WebVital[];
  lastCrawl: Date;
}

function SEOHeaderChat({
  pageScore,
  issues,
  rankingChanges,
  webVitals,
  lastCrawl
}: SEOHeaderChatProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [activeTab, setActiveTab] = useState<'issues' | 'rankings' | 'vitals'>('issues');
  const [notifications, setNotifications] = useState<SEOIssue[]>([]);

  useEffect(() => {
    // Filter and sort issues by priority
    const sortedIssues = [...issues].sort((a, b) => {
      const priority = { critical: 3, warning: 2, info: 1 };
      return priority[b.type] - priority[a.type];
    });
    setNotifications(sortedIssues.slice(0, 3));
  }, [issues]);

  const getScoreColor = (score: number) => {
    if (score >= 90) return 'text-green-500 dark:text-green-400';
    if (score >= 70) return 'text-yellow-500 dark:text-yellow-400';
    return 'text-red-500 dark:text-red-400';
  };

  const getVitalColor = (score: 'good' | 'needs-improvement' | 'poor') => {
    switch (score) {
      case 'good':
        return 'text-green-500 dark:text-green-400';
      case 'needs-improvement':
        return 'text-yellow-500 dark:text-yellow-400';
      case 'poor':
        return 'text-red-500 dark:text-red-400';
    }
  };

  const getIssueIcon = (type: 'critical' | 'warning' | 'info') => {
    switch (type) {
      case 'critical':
        return <AlertTriangle className="w-5 h-5 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
      case 'info':
        return <Search className="w-5 h-5 text-blue-500" />;
    }
  };

  return (
    <div className="fixed top-[72px] right-64 z-40 w-96">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Bell className="w-5 h-5 text-gray-400 dark:text-gray-500" />
              <h3 className="font-medium text-gray-900 dark:text-white">SEO Status</h3>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
              >
                {isOpen ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <>
            <div className="p-4 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Page Score</p>
                  <p className={`text-2xl font-bold ${getScoreColor(pageScore)}`}>
                    {pageScore}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Last Crawl</p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {format(lastCrawl, 'MMM d, h:mm a')}
                  </p>
                </div>
              </div>

              <div className="flex gap-2">
                <button
                  onClick={() => setActiveTab('issues')}
                  className={`flex-1 px-3 py-1 text-sm rounded-lg transition-colors ${
                    activeTab === 'issues'
                      ? 'bg-primary text-white dark:bg-accent dark:text-primary'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                  }`}
                >
                  Issues
                </button>
                <button
                  onClick={() => setActiveTab('rankings')}
                  className={`flex-1 px-3 py-1 text-sm rounded-lg transition-colors ${
                    activeTab === 'rankings'
                      ? 'bg-primary text-white dark:bg-accent dark:text-primary'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                  }`}
                >
                  Rankings
                </button>
                <button
                  onClick={() => setActiveTab('vitals')}
                  className={`flex-1 px-3 py-1 text-sm rounded-lg transition-colors ${
                    activeTab === 'vitals'
                      ? 'bg-primary text-white dark:bg-accent dark:text-primary'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                  }`}
                >
                  Vitals
                </button>
              </div>
            </div>

            <div className="p-4">
              {activeTab === 'issues' && (
                <div className="space-y-3">
                  {notifications.map((issue, index) => (
                    <div
                      key={index}
                      className={`p-3 rounded-lg ${
                        issue.type === 'critical'
                          ? 'bg-red-50 dark:bg-red-900/20'
                          : issue.type === 'warning'
                          ? 'bg-yellow-50 dark:bg-yellow-900/20'
                          : 'bg-blue-50 dark:bg-blue-900/20'
                      }`}
                    >
                      <div className="flex items-start gap-3">
                        {getIssueIcon(issue.type)}
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 dark:text-white">
                            {issue.message}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                            {issue.page}
                          </p>
                        </div>
                        <span className={`text-sm font-medium ${getScoreColor(issue.score)}`}>
                          {issue.score}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeTab === 'rankings' && (
                <div className="space-y-3">
                  {rankingChanges.map((change, index) => (
                    <div
                      key={index}
                      className="p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm font-medium text-gray-900 dark:text-white">
                            {change.keyword}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            Position: {change.position}
                          </p>
                        </div>
                        <span className={`text-sm font-medium ${
                          change.change > 0 
                            ? 'text-green-500 dark:text-green-400' 
                            : 'text-red-500 dark:text-red-400'
                        }`}>
                          {change.change > 0 ? '+' : ''}{change.change}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeTab === 'vitals' && (
                <div className="space-y-3">
                  {webVitals.map((vital, index) => (
                    <div
                      key={index}
                      className="p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm font-medium text-gray-900 dark:text-white">
                            {vital.name}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            {vital.value}
                          </p>
                        </div>
                        <span className={`text-sm font-medium ${getVitalColor(vital.score)}`}>
                          {vital.score === 'good' && <CheckCircle className="w-5 h-5" />}
                          {vital.score === 'needs-improvement' && <Clock className="w-5 h-5" />}
                          {vital.score === 'poor' && <AlertTriangle className="w-5 h-5" />}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SEOHeaderChat;