import { SEOData } from '../types/admin';

interface SEOAnalysisResult {
  score: number;
  issues: SEOIssue[];
  warnings: SEOWarning[];
  suggestions: SEOSuggestion[];
}

interface SEOIssue {
  type: 'critical' | 'major';
  message: string;
  field: string;
  fix?: string;
}

interface SEOWarning {
  type: 'moderate' | 'minor';
  message: string;
  field: string;
  suggestion?: string;
}

interface SEOSuggestion {
  field: string;
  current: string;
  suggested: string;
  reason: string;
}

export function analyzeSEO(data: SEOData, content?: string): SEOAnalysisResult {
  const issues: SEOIssue[] = [];
  const warnings: SEOWarning[] = [];
  const suggestions: SEOSuggestion[] = [];
  let score = 100;

  // Title analysis
  if (!data.title) {
    issues.push({
      type: 'critical',
      message: 'Missing meta title',
      field: 'title',
      fix: 'Add a descriptive title that includes your main keyword'
    });
    score -= 15;
  } else {
    if (data.title.length > 60) {
      warnings.push({
        type: 'moderate',
        message: 'Title is too long (may be truncated in search results)',
        field: 'title',
        suggestion: 'Keep title under 60 characters'
      });
      score -= 5;
    } else if (data.title.length < 30) {
      warnings.push({
        type: 'minor',
        message: 'Title might be too short',
        field: 'title',
        suggestion: 'Consider a more descriptive title'
      });
      score -= 3;
    }
  }

  // Description analysis
  if (!data.description) {
    issues.push({
      type: 'critical',
      message: 'Missing meta description',
      field: 'description',
      fix: 'Add a compelling description that summarizes the page content'
    });
    score -= 15;
  } else {
    if (data.description.length > 160) {
      warnings.push({
        type: 'moderate',
        message: 'Description is too long (may be truncated in search results)',
        field: 'description',
        suggestion: 'Keep description under 160 characters'
      });
      score -= 5;
    } else if (data.description.length < 70) {
      warnings.push({
        type: 'minor',
        message: 'Description might be too short',
        field: 'description',
        suggestion: 'Consider a more detailed description'
      });
      score -= 3;
    }
  }

  // Open Graph analysis
  if (!data.ogTitle || !data.ogDescription || !data.ogImage) {
    warnings.push({
      type: 'moderate',
      message: 'Missing Open Graph tags',
      field: 'og',
      suggestion: 'Add Open Graph tags to improve social sharing'
    });
    score -= 5;
  }

  // Twitter Card analysis
  if (!data.twitterCard || !data.twitterTitle || !data.twitterDescription || !data.twitterImage) {
    warnings.push({
      type: 'minor',
      message: 'Missing Twitter Card tags',
      field: 'twitter',
      suggestion: 'Add Twitter Card tags to improve Twitter sharing'
    });
    score -= 3;
  }

  // Schema markup analysis
  if (!data.schemaMarkup) {
    warnings.push({
      type: 'moderate',
      message: 'Missing Schema markup',
      field: 'schema',
      suggestion: 'Add Schema markup to help search engines understand your content'
    });
    score -= 5;
  } else {
    try {
      JSON.parse(data.schemaMarkup);
    } catch {
      issues.push({
        type: 'major',
        message: 'Invalid Schema markup JSON',
        field: 'schema',
        fix: 'Fix the JSON syntax in your Schema markup'
      });
      score -= 10;
    }
  }

  // Canonical URL analysis
  if (!data.canonicalUrl) {
    warnings.push({
      type: 'minor',
      message: 'No canonical URL specified',
      field: 'canonical',
      suggestion: 'Add a canonical URL to prevent duplicate content issues'
    });
    score -= 3;
  }

  // Meta keywords analysis
  if (!data.metaKeywords || data.metaKeywords.length === 0) {
    warnings.push({
      type: 'minor',
      message: 'No meta keywords specified',
      field: 'keywords',
      suggestion: 'While not crucial for rankings, meta keywords can help with internal search'
    });
    score -= 2;
  } else if (data.metaKeywords.length > 10) {
    warnings.push({
      type: 'minor',
      message: 'Too many meta keywords',
      field: 'keywords',
      suggestion: 'Keep meta keywords focused and under 10 keywords'
    });
    score -= 2;
  }

  // Content analysis if provided
  if (content) {
    const wordCount = content.split(/\s+/).length;
    if (wordCount < 300) {
      warnings.push({
        type: 'moderate',
        message: 'Content might be too thin',
        field: 'content',
        suggestion: 'Consider adding more detailed content (aim for 300+ words)'
      });
      score -= 5;
    }

    // Check if title appears in content
    if (data.title && !content.toLowerCase().includes(data.title.toLowerCase())) {
      warnings.push({
        type: 'minor',
        message: 'Title not found in content',
        field: 'content',
        suggestion: 'Include the title or its keywords naturally in the content'
      });
      score -= 3;
    }
  }

  // Generate smart suggestions
  if (data.title && data.description) {
    // Check for keyword consistency
    const titleWords = new Set(data.title.toLowerCase().split(/\s+/));
    const descWords = new Set(data.description.toLowerCase().split(/\s+/));
    const commonWords = new Set([...titleWords].filter(x => descWords.has(x)));
    
    if (commonWords.size < 2) {
      suggestions.push({
        field: 'keyword-consistency',
        current: 'Low keyword consistency between title and description',
        suggested: 'Use similar key terms in both title and description',
        reason: 'Consistent keywords help search engines understand your page focus'
      });
    }
  }

  return {
    score: Math.max(0, Math.round(score)),
    issues,
    warnings,
    suggestions
  };
}

export function generateSEOSuggestions(data: SEOData, content?: string): string[] {
  const analysis = analyzeSEO(data, content);
  const suggestions: string[] = [];

  // Title suggestions
  if (data.title) {
    if (!data.title.includes('|') && !data.title.includes('-')) {
      suggestions.push('Consider adding your brand name to the title (e.g., "Page Title | Brand Name")');
    }
    if (data.title === data.ogTitle) {
      suggestions.push('Consider using a different, more engaging title for social sharing');
    }
  }

  // Description suggestions
  if (data.description) {
    if (!data.description.includes('call to action')) {
      suggestions.push('Add a call to action in your meta description to improve click-through rates');
    }
  }

  // Image suggestions
  if (data.ogImage) {
    if (data.ogImage === data.twitterImage) {
      suggestions.push('Consider using different optimized images for Open Graph and Twitter Cards');
    }
  }

  // Schema suggestions
  if (data.schemaMarkup) {
    try {
      const schema = JSON.parse(data.schemaMarkup);
      if (!schema['@type']) {
        suggestions.push('Add a specific @type to your Schema markup for better understanding');
      }
    } catch {
      // Schema validation already handled in analyzeSEO
    }
  }

  return suggestions;
}