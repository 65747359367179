import React, { useEffect } from 'react';

declare global {
  interface Window {
    ZohoDeskAsapReady?: (callback: () => void) => void;
    ZohoDeskAsap__asyncalls?: (() => void)[];
    ZohoDeskAsapReadyStatus?: boolean;
  }
}

function ZohoDesk() {
  useEffect(() => {
    // Create and append the script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zohodeskasap';
    script.defer = true;
    script.src = 'https://desk.zoho.com/portal/api/web/asapApp/253367000019396001?orgId=662387794';

    // Add the script to the document
    document.body.appendChild(script);

    // Setup ready callback
    window.ZohoDeskAsapReady = function(callback) {
      const asyncCalls = window.ZohoDeskAsap__asyncalls = window.ZohoDeskAsap__asyncalls || [];
      if (window.ZohoDeskAsapReadyStatus) {
        callback && asyncCalls.push(callback);
        asyncCalls.forEach(cb => cb && cb());
        window.ZohoDeskAsap__asyncalls = null;
      } else {
        callback && asyncCalls.push(callback);
      }
    };

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
}

export default ZohoDesk;