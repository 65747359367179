import type { CaseStudy } from '../types/admin';

export function validateCaseStudy(data: Partial<CaseStudy>): string[] {
  const errors: string[] = [];

  if (!data.title?.trim()) {
    errors.push('Title is required');
  }

  if (!data.subtitle?.trim()) {
    errors.push('Subtitle is required');
  }

  if (!data.description?.trim()) {
    errors.push('Description is required');
  }

  if (!data.client?.trim()) {
    errors.push('Client is required');
  }

  if (!data.industry?.trim()) {
    errors.push('Industry is required');
  }

  if (!data.service?.trim()) {
    errors.push('Service is required');
  }

  if (!data.challenge?.trim()) {
    errors.push('Challenge is required');
  }

  if (!data.solution?.trim()) {
    errors.push('Solution is required');
  }

  if (!data.results?.length) {
    errors.push('At least one result is required');
  }

  if (!data.image?.trim()) {
    errors.push('Featured image is required');
  }

  return errors;
}